import { useContext, createContext, useState, useEffect } from "react";
import { useUser } from "./userContext";
import { io } from "socket.io-client";
import config from "../config";
import { useLang } from "./language";
import { Avatar } from "@mantine/core";
import { notifications } from "@mantine/notifications";

// Create the Context
const SocketContext = createContext();

// Context Provider Component
export const SocketProvider = ({ children }) => {
	const [connectedUsers, setConnectedUsers] = useState([]);
	const [myNotifications, setMyNotifications] = useState([]);
	const [newNotification, setNewNotification] = useState([]);
	const [unreadMessages, setUnreadMessages] = useState(0);
	const [socket, setSocket] = useState(null);
	const [myMessages, setMyMessages] = useState([]);
	const { user, setUser } = useUser();
	const { lang } = useLang();

	const showNotification = (notification) => {
		// create a new notification
		const not = new Notification("JavaScript Notification API", {
			title: notification.name?.[lang],
			body: notification.desc?.[lang],
			icon: (
				<Avatar
					variant="filled"
					radius="md"
					size="md"
					src={
						`${config.api.API_URL}/${notification.createdByUser?.uploads?.find((upl) => upl.destiny === "image")?.path}` ||
						""
					}
				/>
			),
			color:
				notification.importance === "high"
					? "red"
					: notification.importance === "medium"
						? "orange"
						: "blue"
		});

		// close the notification after 10 seconds
		setTimeout(() => {
			not.close();
		}, 10 * 1000);

		// // history to a URL
		// notification.addEventListener("click", () => {
		// 	window.open("https://www.javascripttutorial.net/web-apis/javascript-notification/", "_blank");
		// });
	};

	let granted = false;
	// show an error message
	const showError = () => {
		const error = document.querySelector(".error");
		if (error) {
			error.style.display = "block";
			error.textContent = "You blocked the notifications";
		}
	};

	async function ConnectSocket(userFromServer) {
		let user = JSON.parse(JSON.stringify(userFromServer));
		const socket = io(`${config.api.API_URL}?userId=${user._id}`, {
			transports: ["websocket"]
		});
		setSocket(socket);
	}

	useEffect(() => {
		if (socket) {
			// reconnect on error
			socket.on("connect_error", () => {
				setTimeout(() => {
					socket.connect();
				}, 1000);
			});
			// set user socket and update on database
			socket.on("connect", () => {
				// update user with socket id
				user.socketIds = [...user.socketIds, socket.id];
				setUser(user);
				// user.socketId = socket.id;
				socket.emit("registerUser", user, socket.id);
			});
			// socket.on("disconnect", () => {
			// 	setConnectedUsers(connectedUsers);
			// });
			// setUser(user);

			// socket.on("GetMyNotifications", (notifications) => {
			// 	setNotifications(JSON.parse(notifications));
			// 	// showNotification();
			// });
			// socket.on("createNotification", (notifications) => {
			// 	let Notifications = JSON.parse(notifications);
			// 	let myCurrentNotifications = Notifications.filter((notification) => {
			// 		if (user.role === "admin") {
			// 			return notification.user?._id !== user._id;
			// 		}
			// 	});
			// 	setNotifications(myCurrentNotifications);
			// });
			// socket.on("updateNotification", (notifications) => {
			// 	let Notifications = JSON.parse(notifications);
			// 	let myCurrentNotifications = Notifications.filter((notification) => {
			// 		if (user.role === "admin") {
			// 			// return notification.admin?._id === user._id;
			// 		} else {
			// 			return notification.user?._id === user._id;
			// 		}
			// 	});
			// 	setNotifications(myCurrentNotifications);
			// });

			socket.on("getConnectedUsers", (users) => {
				setConnectedUsers(users);
			});

			// socket.emit('myMessages', user);
			return () => {
				if (socket) {
					socket.off("connect");
					socket.off("disconnect");
					socket.off("GetMyNotifications");
					socket.off("createNotification");
					socket.off("updateNotification");
					socket.off("getConnectedUsers");
				}
			};
		}
		// eslint-disable-next-line
	}, [socket, myNotifications]);

	useEffect(() => {
		if (socket) {
			socket.on("newNotification", (notification) => {
				// Use functional state update to avoid stale state issues
				setMyNotifications((prevNotifications) => [...prevNotifications, notification]);

				notifications.show({
					title: notification.name?.[lang],
					message: notification.desc?.[lang],
					icon: (
						<Avatar
							variant="filled"
							radius="md"
							size="md"
							src={
								`${config.api.API_URL}/${notification.createdByUser?.uploads?.find((upl) => upl.destiny === "image")?.path}` ||
								""
							}
						/>
					),
					color:
						notification.importance === "high"
							? "red"
							: notification.importance === "medium"
								? "orange"
								: "blue"
				});
			});

			// Cleanup the event listener on unmount
			return () => {
				socket.off("newNotification");
			};
		}
		// eslint-disable-next-line
	}, [socket, newNotification?.ID]);

	useEffect(() => {
		if (socket && newNotification) {
			// send to server both to send others and register to DB
			socket.emit("newNotification", newNotification);
		}
		return () => {
			if (socket) {
				socket.off("newNotification");
			}
		};
		// ! change this
		// eslint-disable-next-line
	}, [newNotification?.ID]);

	useEffect(() => {
		(async () => {
			if (Notification.permission === "granted") {
				granted = true;
			} else if (Notification.permission !== "denied") {
				let permission = await Notification.requestPermission();
				granted = permission === "granted" ? true : false;
			}
			// granted ? showNotification() : showError();

			ConnectSocket(user);
		})();
		// eslint-disable-next-line
	}, []);

	const DissconnectSocket = () => {
		console.log("DissconnectSocket");
		socket.disconnect();
	};

	return (
		<SocketContext.Provider
			value={{
				connectedUsers,
				socket,
				myNotifications,
				setMyNotifications,
				myMessages,
				setNewNotification,
				newNotification
			}}
		>
			{children}
		</SocketContext.Provider>
	);
};

// Custom Hook to Use the Context
export const useSocket = () => {
	return useContext(SocketContext);
};
