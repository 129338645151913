import React, { useEffect, useState } from "react";
import { Avatar, Divider, Drawer, Flex, Input, Paper, Text } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import Message from "../message/Message";
import { useUser } from "../../../helpers/userContext";
import { useDebouncedState } from "@mantine/hooks";
import * as API from "../../../helpers/api";
import NProgress from "nprogress";
import config from "../../../config";
import { useLocation } from "react-router-dom";

function Search({ open, onClose }) {
	const [searchResult, setSearchResult] = useDebouncedState("", 500);
	const { user } = useUser();
	const [messages, setMessages] = useState([]);
	let id = useLocation().pathname.split("/")[3];

	useEffect(() => {
		(async () => {
			NProgress.start();
			try {
				if (!searchResult || searchResult === "") {
					setMessages([]);
					NProgress.done();
					return;
				}
				let rawMessages = await API.get({
					route: config.api.message,
					query: {
						$or: [
							{ $and: [{ from: id }, { to: user._id }] },
							{ $and: [{ to: id }, { from: user._id }] }
						],
						deletedFor: {
							$nin: [user._id]
						},
						body: { $regex: searchResult, $options: "i" }
					},
					sort: {
						created: 1
					},
					populate: ["from"]
				});

				if (rawMessages) setMessages(rawMessages);
			} catch (error) {
				console.log("🚀 ~ error:", error);
			} finally {
				NProgress.done();
			}
		})();
		// eslint-disable-next-line
	}, [searchResult]);

	return (
		<Drawer
			opened={open}
			onClose={() => {
				onClose();
				setSearchResult("");
				setMessages([]);
			}}
			title={<Text p="md">Search message</Text>}
			position="right"
			padding={0}
			overlayProps={{ backgroundOpacity: 0.2, blur: 0 }}
			closeButtonProps={{
				style: {
					marginRight: 5
				}
			}}
		>
			<Divider mb="sm" />
			<Paper pl="md" pr="md">
				<Input
					placeholder="Search message"
					onChange={(e) => {
						// trim the search result
						setSearchResult(e.currentTarget.value.trim());
					}}
					rightSection={<IconSearch size={16} />}
				/>
			</Paper>
			<Divider my="md" />
			{messages && messages.length > 0 ? (
				messages.map((message) => (
					<Message
						key={message._id}
						message={message}
						user={user}
						hideActions
						fromUser={message.from}
					/>
				))
			) : searchResult ? (
				<Flex direction="column" grow={1} align={"center"}>
					<Avatar color="grey" radius="xl">
						<IconSearch size={20} />
					</Avatar>
					<Text mt="sm">No Result</Text>
				</Flex>
			) : (
				<Flex direction="column" grow={1} align={"center"}>
					<Avatar color="grey" radius="xl">
						<IconSearch size={20} />
					</Avatar>
					<Text mt="sm">Type to search</Text>
				</Flex>
			)}
		</Drawer>
	);
}

export default Search;
