import { useContext, createContext } from "react";
import { Flex, Button } from "@mantine/core";

export const Language = createContext();

export function useLang() {
	return useContext(Language);
}

const data = [
	{
		label: "English",
		image: <span className="fi fi-us"></span>,
		value: "en"
	},
	{
		label: "Русский",
		image: <span className="fi fi-ru"></span>,
		value: "ru"
	},
	{
		label: "Հայերեն",
		image: <span className="fi fi-am"></span>,
		value: "hy"
	},
	{
		label: "Türkçe",
		image: <span className="fi fi-tr"></span>,
		value: "tr"
	},
	{
		label: "العربية",
		image: <span className="fi fi-sa"></span>,
		value: "ar"
	},
	{
		label: "中文",
		image: <span className="fi fi-cn"></span>,
		value: "zh"
	},
	{
		label: "فارسی",
		image: <span className="fi fi-ir"></span>,
		value: "fa"
	},
	{
		label: "עברית",
		image: <span className="fi fi-il"></span>,
		value: "he"
	},
	{
		label: "Français",
		image: <span className="fi fi-fr"></span>,
		value: "fr"
	},
	{
		label: "Español",
		image: <span className="fi fi-es"></span>,
		value: "es"
	},
	{
		label: "Português",
		image: <span className="fi fi-pt"></span>,
		value: "pt"
	}
];

export function LanguagePicker() {
	const { lang, setLang } = useLang();
	return (
		<Flex wrap="wrap" mt={1} direction={"row"}>
			{data.map((item) => (
				<Button
					justify="center"
					width
					value={item.value}
					onClick={() => setLang(item.value)}
					m={2}
					leftSection={item.image}
					variant={lang === item.value ? "light" : "default"}
				>
					{item.label}
				</Button>
			))}
		</Flex>
	);
}
