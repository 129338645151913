import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import NProgress from "nprogress";
import { useUser } from "../../../helpers/userContext";
import { useLang } from "../../../helpers/language";
import { T } from "../../../helpers/translator";

export default function User() {
	const [isBusy, setBusy] = useState(true);
	let { id } = useParams();
	const route = config.api.user;
	const { lang } = useLang();
	const { user } = useUser();
	const [chatID, setChatID] = useState("");
	const [permissions, setPermissions] = useState([]);
	const roleParser = (role, field) => {
		if (role === "manager") {
			return field;
		} else if (role === "editor") {
			return null;
		} else {
			return field;
		}
	};

	const workingStatuses = [
		{
			label: T("userArchive", lang),
			value: "archive"
		},
		{
			label: T("interview1", lang),
			value: "interview1"
		},
		{
			label: T("interview2", lang),
			value: "interview2"
		},
		{
			label: T("interview3", lang),
			value: "interview3"
		},
		{
			label: T("interview4", lang),
			value: "interview4"
		},
		{
			label: T("contract", lang),
			value: "working"
		},
		{
			label: T("maternityLeave", lang),
			value: "maternityLeave"
		},
		{
			label: T("fired", lang),
			value: "fired"
		}
	];

	const [index, setIndex] = useState("");
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [desc, setDesc] = useState("");

	const [passportNo, setPassportNo] = useState("");
	const [passportEndDate, setPassportEndDate] = useState("");
	const [idCard, setIdCard] = useState("");
	const [idCardEndDate, setIdCardEndDate] = useState("");
	const [contractStartDate, setContractStartDate] = useState("");
	const [contractEndDate, setContractEndDate] = useState("");

	const [socialCard, setSocialCard] = useState("");
	const [bankEmitet, setBankEmitet] = useState("");
	const [bankAccount, setBankAccount] = useState("");
	const [socialNetworkLink, setSocialNetworkLink] = useState("");

	const [firstNameEn, setFirstNameEn] = useState("");
	const [firstNameRu, setFirstNameRu] = useState("");
	const [firstNameHy, setFirstNameHy] = useState("");
	const [lastNameEn, setLastNameEn] = useState("");
	const [lastNameRu, setLastNameRu] = useState("");
	const [lastNameHy, setLastNameHy] = useState("");
	const [addressEn, setAddressEn] = useState("");
	const [addressRu, setAddressRu] = useState("");
	const [addressHy, setAddressHy] = useState("");
	const [registeredAddressEn, setRegisteredAddressEn] = useState("");
	const [registeredAddressRu, setRegisteredAddressRu] = useState("");
	const [registeredAddressHy, setRegisteredAddressHy] = useState("");
	const [department, setDepartment] = useState("");
	const [departments, setDepartments] = useState("");
	const [position, setPosition] = useState("");
	const [positions, setPositions] = useState("");
	const [pc, setPc] = useState("");
	const [pcs, setPcs] = useState("");
	const [dateOfBirth, setdateOfBirth] = useState("");
	const [workingStatus, setWorkingStatus] = useState({
		dateStarts: new Date(),
		status: ""
	});
	const [gender, setGender] = useState("");
	const [role, setRole] = useState("");
	const [roles, setRoles] = useState("");

	// files
	const [image, setImage] = useState(undefined);
	const [passportImages, setPassportImages] = useState([]);
	const [IDCardImages, setIDCardImagesImages] = useState([]);
	const [socialCardImages, setSocialCardImages] = useState([]);
	const [diplomaImages, setDiplomaImages] = useState([]);
	const [certificateImages, setCertificateImages] = useState([]);

	const genders = [
		{
			_id: "male",
			name: {
				en: "Male",
				ru: "Мужской",
				hy: "Արական",
				tr: "Erkek",
				ar: "ذكر",
				zh: "男性",
				fa: "مرد",
				he: "זכר",
				fr: "Masculin",
				es: "Masculino",
				pt: "Masculino"
			}
		},
		{
			_id: "female",
			name: {
				en: "Female",
				ru: "Женский",
				hy: "Իգական",
				tr: "Kadın",
				ar: "أنثى",
				zh: "女性",
				fa: "زن",
				he: "נקבה",
				fr: "Féminin",
				es: "Femenino",
				pt: "Feminino"
			}
		}
	];

	const modelSendToServer = {
		name: {
			en: firstNameEn + " " + lastNameEn,
			ru: firstNameRu + " " + lastNameRu,
			hy: firstNameHy + " " + lastNameHy
		},
		firstName: {
			en: firstNameEn,
			ru: firstNameRu,
			hy: firstNameHy
		},
		lastName: {
			en: lastNameEn,
			ru: lastNameRu,
			hy: lastNameHy
		},
		address: {
			en: addressEn,
			ru: addressRu,
			hy: addressHy
		},
		registeredAddress: {
			en: registeredAddressEn,
			ru: registeredAddressRu,
			hy: registeredAddressHy
		},
		username,
		password,
		desc,
		email,
		phone,
		passportNo,
		passportEndDate,
		idCard,
		idCardEndDate,
		contractStartDate,
		contractEndDate,
		socialCard,
		bankEmitet,
		bankAccount,
		socialNetworkLink,
		dateOfBirth,
		gender,
		role: role || null,
		pc: pc || null,
		index,
		chatID,
		workingStatus,
		department: department || null,
		position: position || null
	};

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawDepartments = await API.get({ route: config.api.department });
			if (rawDepartments) setDepartments(rawDepartments);
			let rawPositions = await API.get({ route: config.api.position });
			if (rawPositions) setPositions(rawPositions);
			let rawPCs = await API.get({ route: config.api.pc });
			if (rawPCs) setPcs(rawPCs);
			let rawRoles = await API.get({ route: config.api.role });
			if (rawRoles) setRoles(rawRoles);

			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true,
					limit: 1,
					populate: ["department", "position", "pc", "role"]
				});

				setIndex(currentItem[0]?.index);
				setGender(currentItem[0]?.gender);

				setDesc(currentItem[0]?.desc);
				setUserName(currentItem[0]?.username);
				setChatID(currentItem[0]?.chatID);
				setPassword(currentItem[0]?.password);
				setEmail(currentItem[0]?.email);
				setPhone(currentItem[0]?.phone?.replace(/\s/g, "").replace(/^0+/, ""));

				setPassportNo(currentItem[0]?.passportNo);
				setPassportEndDate(currentItem[0]?.passportEndDate);
				setIdCard(currentItem[0]?.idCard);
				setIdCardEndDate(currentItem[0]?.idCardEndDate);

				setContractStartDate(currentItem[0]?.contractStartDate);
				setContractEndDate(currentItem[0]?.contractEndDate);
				setSocialCard(currentItem[0]?.socialCard);
				setBankEmitet(currentItem[0]?.bankEmitet);
				setBankAccount(currentItem[0]?.bankAccount);
				setSocialNetworkLink(currentItem[0]?.socialNetworkLink);

				setFirstNameEn(currentItem[0]?.firstName?.en);
				setFirstNameRu(currentItem[0]?.firstName?.ru);
				setFirstNameHy(currentItem[0]?.firstName?.hy);
				setLastNameEn(currentItem[0]?.lastName?.en);
				setLastNameRu(currentItem[0]?.lastName?.ru);
				setLastNameHy(currentItem[0]?.lastName?.hy);
				setAddressEn(currentItem[0]?.address?.en);
				setAddressRu(currentItem[0]?.address?.ru);
				setAddressHy(currentItem[0]?.address?.hy);
				setRegisteredAddressEn(currentItem[0]?.registeredAddress?.en);
				setRegisteredAddressRu(currentItem[0]?.registeredAddress?.ru);
				setRegisteredAddressHy(currentItem[0]?.registeredAddress?.hy);
				setWorkingStatus(currentItem[0]?.workingStatus);
				currentItem[0]?.dateOfBirth && setdateOfBirth(currentItem[0]?.dateOfBirth);
				setDepartment(currentItem[0]?.department);
				setPosition(currentItem[0]?.position);
				setPc(currentItem[0]?.pc);
				setRole(currentItem[0]?.role);

				setImage(undefined);
				setPassportImages([]);
				setIDCardImagesImages([]);
				setSocialCardImages([]);
				setDiplomaImages([]);
				setCertificateImages([]);

				currentItem[0]?.uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					if (item.destiny === "passportImages")
						setPassportImages((passportImages) => [...passportImages, item]);
					if (item.destiny === "IDCardImages")
						setIDCardImagesImages((IDCardImages) => [...IDCardImages, item]);
					if (item.destiny === "socialCardImages")
						setSocialCardImages((socialCardImages) => [...socialCardImages, item]);
					if (item.destiny === "diplomaImages")
						setDiplomaImages((diplomaImages) => [...diplomaImages, item]);
					if (item.destiny === "certificateImages")
						setCertificateImages((certificateImages) => [...certificateImages, item]);
					return item;
				});

				genders.forEach((item) => {
					if (item._id === currentItem[0]?.gender) {
						setGender(item._id);
					}
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				chatID={chatID}
				id={id}
				permissionModel="user"
				defaultTab={"eight"}
				permissions={permissions}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: chatID,
						setter: setChatID,
						type: "text",
						usageType: "textfield",
						label: T("chatID", lang),
						placeholder: T("chatID", lang),
						permissionModel: "chatID"
					},
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: role,
						setter: setRole,
						optionListValue: (roles || []).map((role) => {
							return {
								label: roleParser(user.role, role.name),
								value: role._id
							};
						}),
						type: "optionList",
						usageType: "optionList",
						label: T("role", lang),
						permissionModel: "role"
					},
					{
						value: username,
						setter: setUserName,
						type: "text",
						usageType: "textfield",
						label: T("login", lang),
						placeholder: T("login", lang),
						permissionModel: "username"
					},
					{
						value: password,
						setter: setPassword,
						type: "password",
						usageType: "password",
						label: T("password", lang),
						placeholder: T("password", lang),
						permissionModel: "password"
					},
					{
						value: department,
						setter: setDepartment,
						optionListValue: (departments || []).map((department) => {
							return {
								label: department.name,
								value: department._id
							};
						}),
						type: "optionList",
						usageType: "optionList",
						label: T("department", lang),
						permissionModel: "department"
					},
					{
						value: position,
						setter: setPosition,
						optionListValue: (positions || []).map((position) => {
							return {
								label: position.name,
								value: position._id
							};
						}),
						type: "optionList",
						usageType: "optionList",
						label: T("position", lang),
						permissionModel: "position"
					},
					{
						value: pc,
						setter: setPc,
						optionListValue: pcs,
						type: "optionList",
						usageType: "optionList",
						label: T("pc", lang),
						permissionModel: "pc"
					},
					{
						value: firstNameEn,
						setter: setFirstNameEn,
						type: "text",
						usageType: "textfield",
						label: T("firstName", lang),
						placeholder: T("inputInLatin", lang),
						lang: "en",
						permissionModel: "firstName"
					},
					{
						value: lastNameEn,
						setter: setLastNameEn,
						type: "text",
						usageType: "textfield",
						label: T("lastName", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "lastName",
						lang: "en"
					},
					{
						value: firstNameRu,
						setter: setFirstNameRu,
						type: "text",
						usageType: "textfield",
						label: T("firstName", lang),
						placeholder: T("inputInRussian", lang),
						permissionModel: "firstName",
						lang: "ru"
					},
					{
						value: lastNameRu,
						setter: setLastNameRu,
						type: "text",
						usageType: "textfield",
						label: T("lastName", lang),
						placeholder: T("inputInRussian", lang),
						permissionModel: "lastName",
						lang: "ru"
					},
					{
						value: firstNameHy,
						setter: setFirstNameHy,
						type: "text",
						usageType: "textfield",
						label: T("firstName", lang),
						placeholder: T("inputInArmenian", lang),
						lang: "hy",
						permissionModel: "firstName"
					},
					{
						value: lastNameHy,
						setter: setLastNameHy,
						type: "text",
						usageType: "textfield",
						label: T("lastName", lang),
						placeholder: T("inputInArmenian", lang),
						permissionModel: "lastName",
						lang: "hy"
					},
					{
						value: addressHy,
						setter: setAddressHy,
						type: "text",
						usageType: "textfield",
						label: "Հասցե",
						placeholder: T("inputInArmenian", lang),
						permissionModel: "address",
						lang: "hy"
					},
					{
						value: addressRu,
						setter: setAddressRu,
						type: "text",
						usageType: "textfield",
						label: "Адрес",
						placeholder: T("inputInRussian", lang),
						permissionModel: "address",
						lang: "ru"
					},
					{
						value: addressEn,
						setter: setAddressEn,
						type: "text",
						usageType: "textfield",
						label: "Address",
						placeholder: T("inputInLatin", lang),
						permissionModel: "address",
						lang: "en"
					},
					{
						value: registeredAddressHy,
						setter: setRegisteredAddressHy,
						type: "text",
						usageType: "textfield",
						label: T("registeredAddress", lang),
						placeholder: T("inputInArmenian", lang),
						permissionModel: "registeredAddress",
						lang: "hy"
					},
					{
						value: registeredAddressRu,
						setter: setRegisteredAddressRu,
						type: "text",
						usageType: "textfield",
						label: T("registeredAddress", lang),
						placeholder: T("inputInRussian", lang),
						permissionModel: "registeredAddress",
						lang: "ru"
					},
					{
						value: registeredAddressEn,
						setter: setRegisteredAddressEn,
						type: "text",
						usageType: "textfield",
						label: T("registeredAddress", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "registeredAddress",
						lang: "en"
					},
					{
						value: dateOfBirth,
						setter: setdateOfBirth,
						type: "date",
						usageType: "datePicker",
						label: T("dateOfBirth", lang),
						placeholder: T("dateOfBirth", lang),
						permissionModel: "dateOfBirth"
					},
					{
						value: gender,
						setter: setGender,
						optionListValue: genders,
						type: "text",
						usageType: "optionList",
						label: T("gender", lang),
						permissionModel: "gender"
					},
					{
						value: email,
						setter: setEmail,
						type: "text",
						usageType: "email",
						label: T("email", lang),
						permissionModel: "email"
					},
					{
						value: phone,
						setter: (phoneNumber) => {
							setPhone(
								phoneNumber
									.replace(/^0+/, "")
									.replace(/-/g, "")
									.replace(/\s/g, "")
									.replace(/\(/g, "")
									.replace(/\)/g, "")
							);
						},
						type: "text",
						usageType: "phone",
						label: T("phone", lang),
						permissionModel: "phone"
					},
					{
						value: passportNo,
						setter: setPassportNo,
						type: "text",
						usageType: "textfield",
						label: T("passportNo", lang),
						permissionModel: "passportNo"
					},
					{
						value: passportEndDate,
						setter: setPassportEndDate,
						type: "date",
						usageType: "datePicker",
						label: T("passportEndDate", lang),
						permissionModel: "passportEndDate"
					},
					{
						value: idCard,
						setter: setIdCard,
						type: "text",
						usageType: "idCard",
						label: T("idCart", lang),
						permissionModel: "idCard"
					},
					{
						value: idCardEndDate,
						setter: setIdCardEndDate,
						type: "date",
						usageType: "datePicker",
						label: T("idCardEndDate", lang),
						permissionModel: "idCardEndDate"
					},
					{
						value: contractStartDate,
						setter: setContractStartDate,
						type: "date",
						usageType: "datePicker",
						label: T("contractStartDate", lang),
						permissionModel: "contractStartDate"
					},
					{
						value: contractEndDate,
						setter: setContractEndDate,
						type: "date",
						usageType: "datePicker",
						label: T("contractEndDate", lang),
						permissionModel: "contractEndDate"
					},
					{
						value: socialCard,
						setter: setSocialCard,
						type: "text",
						usageType: "textfield",
						label: T("socialCart", lang),
						permissionModel: "socialCard"
					},
					{
						value: bankEmitet,
						setter: setBankEmitet,
						type: "text",
						usageType: "bank",
						label: T("bankEmitet", lang),
						permissionModel: "bankEmitet"
					},
					{
						value: bankAccount,
						setter: setBankAccount,
						type: "text",
						usageType: "bank",
						label: T("bankAccount", lang),
						permissionModel: "bankAccount"
					},
					{
						value: socialNetworkLink,
						setter: setSocialNetworkLink,
						type: "text",
						usageType: "url",
						label: T("socialNetworkLink", lang),
						permissionModel: "socialNetworkLink"
					},
					{
						value: workingStatus,
						setter: setWorkingStatus,
						optionListValue: workingStatuses,
						type: "optionList",
						usageType: "optionList",
						label: T("workingStatus", lang),
						permissionModel: "workingStatus"
					},
					{
						value: desc,
						setter: setDesc,
						type: "text",
						width: 12,
						usageType: "textarea",
						label: T("description", lang),
						placeholder: T("description", lang),
						permissionModel: "desc"
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						adminMode: user.role !== "editor",
						height: 500,
						width: 500,
						permissionModel: "image",
						placeholder: T("image", lang)
					},
					{
						destiny: "passportImages",
						files: passportImages,
						setFiles: setPassportImages,
						filesUploadLimit: 3,
						filesMaxSize: 5,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						adminMode: user.role !== "editor",
						height: 500,
						permissionModel: "image",
						placeholder: T("passportImages", lang)
					},
					{
						destiny: "IDCardImages",
						files: IDCardImages,
						setFiles: setIDCardImagesImages,
						filesUploadLimit: 3,
						filesMaxSize: 5,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						adminMode: user.role !== "editor",
						height: 500,
						permissionModel: "image",
						placeholder: T("IDCardImages", lang)
					},
					{
						destiny: "socialCardImages",
						files: socialCardImages,
						setFiles: setSocialCardImages,
						filesUploadLimit: 3,
						filesMaxSize: 5,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						adminMode: user.role !== "editor",
						height: 500,
						permissionModel: "image",
						placeholder: T("socialCardImages", lang)
					},
					{
						destiny: "diplomaImages",
						files: diplomaImages,
						setFiles: setDiplomaImages,
						filesUploadLimit: 3,
						filesMaxSize: 5,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						adminMode: user.role !== "editor",
						height: 500,
						permissionModel: "image",
						placeholder: T("diplomaImages", lang)
					},
					{
						destiny: "certificateImages",
						files: certificateImages,
						setFiles: setCertificateImages,
						filesUploadLimit: 3,
						filesMaxSize: 5,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						adminMode: user.role !== "editor",
						height: 500,
						permissionModel: "image",
						placeholder: T("certificateImages", lang)
					}
				]}
				userProfile={{
					name: {
						en: firstNameEn + " " + lastNameEn,
						ru: firstNameRu + " " + lastNameRu,
						hy: firstNameHy + " " + lastNameHy
					},
					firstName: {
						en: firstNameEn,
						ru: firstNameRu,
						hy: firstNameHy
					},
					lastName: {
						en: lastNameEn,
						ru: lastNameRu,
						hy: lastNameHy
					},
					address: {
						en: addressEn,
						ru: addressRu,
						hy: addressHy
					},
					registeredAddress: {
						en: registeredAddressEn,
						ru: registeredAddressRu,
						hy: registeredAddressHy
					},
					username,
					password,
					desc,
					email,
					phone,
					passportNo,
					passportEndDate,
					idCard,
					idCardEndDate,
					contractStartDate,
					contractEndDate,
					socialCard,
					bankEmitet,
					bankAccount,
					socialNetworkLink,
					dateOfBirth,
					gender,
					role: role || null,
					pc: pc || null,
					index,
					chatID,
					workingStatus,
					department: department || null,
					position: position || null,
					upload: image
				}}
			/>
		);
}
