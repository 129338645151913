import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import NProgress from "nprogress";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import Loading from "../modules/loading";
import { useParams } from "react-router-dom";
import { useMantineColorScheme } from "@mantine/core";
import { T } from "../../helpers/translator";
import * as API from "../../helpers/api";

export default function Chats() {
	const { user } = useUser();
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const [users, setUsers] = useState([]);

	function setRefreshColumns() {
		return [
			{
				accessorKey: "index",
				permissionModel: "chat",
				header: T("index"),
				enableClickToCopy: true,
				size: 30,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = row.index ? row.index : "...";
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorFn: (row) => `${row.name?.en} ${row.name?.ru} ${row.name?.hy}`,
				size: 500,
				permissionModel: "chat",
				header: T("name"),
				// filterVariant: "multi-select",
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = "";
					if (row.name) result = parse(String(row.name));
					else {
						// find in row.users user name which is not mine
						let userNames = [];
						if (row.users && users.length > 0) {
							row.users.forEach((userId) => {
								if (userId !== user._id) {
									let foundUser = users.find((use) => use._id === userId);
									if (foundUser) userNames.push(foundUser.name);
								}
							});
						}
						result = userNames?.[0]?.[lang];
					}

					return parse(String(result));
				}
			}
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			try {
				let rawUsers = await API.get({ route: config.api.user });
				if (rawUsers) setUsers(rawUsers);
			} catch (error) {
				console.error(error);
			}

			setColumns(setRefreshColumns());
			NProgress.done();
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.chat}
				query={{
					$or: [{ users: user._id }, { admins: user._id }]
				}}
				sorting={{ index: "asc" }}
				uploadType={true}
				limit={null}
				columns={columns}
				// populate={["position", "pc", "department"]}
				permissionModel={"chat"}
			/>
		);
	else return <Loading />;
}
