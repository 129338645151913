import React from "react";
import {
	Card,
	Text,
	Group,
	Grid,
	Accordion,
	Badge,
	Title,
	Divider,
	Avatar,
	Timeline,
	Button,
	Image,
	Flex
} from "@mantine/core";
import {
	IconUser,
	IconMail,
	IconPhone,
	IconCalendar,
	IconBriefcase,
	IconLocation,
	IconHeart,
	IconCertificate,
	IconStatusChange,
	IconRecordMail
} from "@tabler/icons-react";
import { T } from "../../../helpers/translator";
import { useLang } from "../../../helpers/language";
import config from "../../../config";

export const UserProfile = ({ user }) => {
	const { lang } = useLang();

	const downloadAsContact = (user) => {
		// vCard format template
		const vCardContent = `
BEGIN:VCARD
VERSION:3.0
FN:${user.firstName?.[lang] || ""} ${user.lastName?.[lang] || ""}
N:${user.lastName?.[lang] || ""};${user.firstName?.[lang] || ""}
TEL;TYPE=CELL:${user.phone || ""}
EMAIL:${user.email || ""}
ADR;TYPE=HOME:${user.address?.[lang] || ""}
BDAY:${user.dateOfBirth ? new Date(user.dateOfBirth).toISOString().split("T")[0] : ""}
END:VCARD
  `.trim();
		// Create a blob with the vCard content
		const blob = new Blob([vCardContent], { type: "text/vcard" });

		// Create a temporary link element
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = `${user.firstName?.[lang] || "Contact"}_${user.lastName?.[lang] || ""}.vcf`;

		// Trigger the download
		document.body.appendChild(link);
		link.click();

		// Cleanup
		document.body.removeChild(link);
	};

	const genders = [
		{
			_id: "male",
			name: {
				en: "Male",
				ru: "Мужской",
				hy: "Արական",
				tr: "Erkek",
				ar: "ذكر",
				zh: "男性",
				fa: "مرد",
				he: "זכר",
				fr: "Masculin",
				es: "Masculino",
				pt: "Masculino"
			}
		},
		{
			_id: "female",
			name: {
				en: "Female",
				ru: "Женский",
				hy: "Իգական",
				tr: "Kadın",
				ar: "أنثى",
				zh: "女性",
				fa: "زن",
				he: "נקבה",
				fr: "Féminin",
				es: "Femenino",
				pt: "Feminino"
			}
		}
	];

	const workingStatuses = [
		{
			label: T("userArchive", lang),
			value: "archive"
		},
		{
			label: T("interview1", lang),
			value: "interview1"
		},
		{
			label: T("interview2", lang),
			value: "interview2"
		},
		{
			label: T("interview3", lang),
			value: "interview3"
		},
		{
			label: T("interview4", lang),
			value: "interview4"
		},
		{
			label: T("contract", lang),
			value: "working"
		},
		{
			label: T("maternityLeave", lang),
			value: "maternityLeave"
		},
		{
			label: T("fired", lang),
			value: "fired"
		}
	];

	return (
		<Card shadow="lg" p="xl" radius="md" withBorder>
			{/* Header */}
			<Flex justify={"center"} direction={"column"} align="center">
				<Group>
					<Avatar
						variant="filled"
						radius="sm"
						h={400}
						w={400}
						src={`${config.api.API_URL}/${user.upload?.path}`}
						style={{ border: `2px solid cyan` }}
					/>
				</Group>
				<Button
					mt={20}
					onClick={() => downloadAsContact(user)}
					variant="light"
					color="blue"
					size="sm"
				>
					{T("downloadAsContact", lang)}
				</Button>
			</Flex>

			<Divider my="lg" />
			{/* Basic Information */}
			<Title order={1} mb="sm">
				{T("basicInformation", lang)}
			</Title>
			<Grid>
				<Grid.Col span={6}>
					<Group>
						<IconUser size={18} />
						<Text>
							<strong>{T("username", lang)}:</strong>
							{user.username || "Anonymous User"}
						</Text>
					</Group>
					<Group>
						<IconRecordMail size={18} />
						<Text>
							<strong>{T("email", lang)}:</strong>
							{user.email || "Email not provided"}
						</Text>
					</Group>
				</Grid.Col>
				<Grid.Col span={6}>
					<Group>
						<IconStatusChange size={18} />
						<Text>
							<strong>{T("position", lang)}:</strong> {user.position?.name?.[lang] || "N/A"}
						</Text>
					</Group>
					<Group>
						<IconCalendar size={18} />
						<Text>
							<strong>{T("department", lang)}:</strong> {user.department?.name?.[lang] || "N/A"}
						</Text>
					</Group>
				</Grid.Col>
				<Grid.Col span={6}>
					<Group>
						<IconPhone size={18} />
						<Text>
							<strong>{T("phone", lang)}:</strong> {user.phone || "N/A"}
						</Text>
					</Group>
					<Group>
						<IconCalendar size={18} />
						<Text>
							<strong>{T("dateOfBirth", lang)}:</strong>{" "}
							{user.dateOfBirth ? new Date(user.dateOfBirth).toLocaleDateString() : "N/A"}
						</Text>
					</Group>
				</Grid.Col>
				<Grid.Col span={6}>
					<Group>
						<IconBriefcase size={18} />
						<Text>
							<strong>{T("workingStatus", lang)}</strong>{" "}
							{workingStatuses.filter((status) => status.value === user.workingStatus)?.label?.[
								lang
							] || "N/A"}
						</Text>
					</Group>
					<Group>
						<IconLocation size={18} />
						<Text>
							<strong>{T("gender", lang)}:</strong>{" "}
							{genders.find((gender) => gender._id === user.gender)?.name?.[lang] || "N/A"}
						</Text>
					</Group>
				</Grid.Col>
			</Grid>

			<Divider my="lg" />
			{/* Address Information */}
			<Title order={4} mb="sm">
				{T("address", lang)}
			</Title>
			<Grid>
				<Grid.Col span={6}>
					<Text>
						<strong>{T("address", lang)}:</strong> {user.address?.[lang] || "N/A"}
					</Text>
				</Grid.Col>
				<Grid.Col span={6}>
					<Text>
						<strong>{T("registeredAddress", lang)}:</strong>{" "}
						{user.registeredAddress?.[lang] || "N/A"}
					</Text>
				</Grid.Col>
			</Grid>

			<Divider my="lg" />
			{/* Passport and Contract Details */}
			<Title order={5} mb="sm">
				{T("additionalDetails", lang)}
			</Title>
			<Grid>
				<Grid.Col span={6}>
					<Group>
						<IconCertificate size={18} />
						<Text>
							<strong>{T("passportNo", lang)}:</strong> {user.passportNo || "N/A"}
						</Text>
					</Group>
					<Text>
						<strong>{T("passportEndDate", lang)}:</strong>{" "}
						{user.passportEndDate ? new Date(user.passportEndDate).toLocaleDateString() : "N/A"}
					</Text>
				</Grid.Col>
				<Grid.Col span={6}>
					<Group>
						<IconCertificate size={18} />
						<Text>
							<strong>{T("idCardNo", lang)}:</strong> {user.idCard || "N/A"}
						</Text>
					</Group>
					<Text>
						<strong>{T("idCardEndDate", lang)}:</strong>{" "}
						{user.idCardEndDate ? new Date(user.idCardEndDate).toLocaleDateString() : "N/A"}
					</Text>
				</Grid.Col>
			</Grid>

			<Divider my="lg" />
			{/* Passport and Contract Details */}
			<Title order={5} mb="sm">
				{T("workingStatus", lang)}
			</Title>
			<Grid>
				<Grid.Col>
					<Text>
						<strong>{T("contractStartDate", lang)}:</strong>{" "}
						{user.contractStartDate ? new Date(user.contractStartDate).toLocaleDateString() : "N/A"}
					</Text>
					<Text>
						<strong>{T("contractEndDate", lang)}:</strong>{" "}
						{user.contractEndDate ? new Date(user.contractEndDate).toLocaleDateString() : "N/A"}
					</Text>
				</Grid.Col>
			</Grid>

			<Divider my="lg" />
			{/* Passport and Contract Details */}
			<Title order={5} mb="sm">
				{T("bank", lang)}
			</Title>
			<Grid>
				<Grid.Col>
					<Text>
						<strong>{T("bankEmitet", lang)}:</strong> {user.bankEmitet || "N/A"}
					</Text>
					<Text>
						<strong>{T("bankAccount", lang)}:</strong> {user.bankAccount || "N/A"}
					</Text>
				</Grid.Col>
			</Grid>

			<Divider my="lg" />
			{/* Favorites */}
			<Title order={6} mb="sm">
				{T("favorites", lang)}
			</Title>
			<Accordion>
				<Accordion.Item label="Favorites">
					{user.favorites?.length > 0 ? (
						<Group spacing="sm" wrap>
							{user.favorites.map((fav, idx) => (
								<Badge key={idx} color="pink" variant="light" leftSection={<IconHeart size={14} />}>
									Favorite {idx + 1}
								</Badge>
							))}
						</Group>
					) : (
						<Text color="dimmed">No favorites added.</Text>
					)}
				</Accordion.Item>
			</Accordion>
		</Card>
	);
};
