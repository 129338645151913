import { T } from "./helpers/translator";

const api = {
	ticket: "ticket",
	chat: "chat",
	chatGroup: "chat-group",
	message: "message",
	messageReadReceipt: "message-read-receipt",
	user: "user",
	department: "department",
	city: "city",
	voltage: "voltage",
	pc: "pc",
	notification: "notification",
	router: "router",
	printer: "printer",
	smb: "smb",
	project: "project",
	step: "step",
	branch: "branch",
	software: "software",
	problem: "problem",
	status: "status",
	room: "room",
	upload: "upload",
	projectType: "project-type",
	switch: "switch",
	monitor: "monitor",
	keyboard: "keyboard",
	mouse: "mouse",
	role: "role",
	permission: "permission",
	userArchive: "user-archive",
	position: "position",
	village: "village",
	community: "community",
	API_URL: process.env.REACT_APP_API_URL,
	API_Version: "v3",
	CHAT_URL: process.env.REACT_APP_CHAT_URL
};

const authentication = {
	login: "/login/private",
	tokenAddress: "private-token",
	verify: "/verify"
};

const steps = [
	{
		_id: 1,
		name: {
			hy: "Տեղազննում",
			en: "Visitation",
			ru: "Посещение"
		}
	},
	{
		_id: 2,
		name: {
			hy: "Գեոլոգիական եզրակացություն",
			en: "Geological exploration",
			ru: "Геологическое исследование"
		},
		bypass: true
	},
	{
		_id: 3,
		name: {
			hy: "Նախագծողի ընտրություն",
			en: "Selection of the project developer",
			ru: "Выбор застройщика"
		}
	},
	{
		_id: 4,
		name: {
			hy: "Գծագրում",
			en: "Design",
			ru: "Проектирование"
		}
	},
	{
		_id: 5,
		name: {
			hy: "Գծագրի ստուգում",
			en: "Checking the design",
			ru: "Проверка проекта"
		}
	},
	{
		_id: 6,
		name: {
			hy: "Տրամադրում համաձայնեցման",
			en: "Agreement",
			ru: "Согласование"
		}
	},
	{
		_id: 7,
		name: {
			hy: "ԷՀԳ",
			en: "EHGS",
			ru: "ЭХГС"
		},
		bypass: true
	},
	{
		_id: 8,
		name: {
			hy: "Գեոդեզիա",
			en: "Geodesy",
			ru: "Геодезия"
		},
		bypass: true
	},
	{
		_id: 9,
		name: {
			hy: "ԷՀԳ եզրակացություն",
			en: "EHGS exploration",
			ru: "ЭХГС исследование"
		},
		bypass: true
	},
	{
		_id: 10,
		name: {
			hy: "Համաձայնեցման համակարգում",
			en: "Agreement system",
			ru: "Система согласования"
		}
	},
	{
		_id: 11,
		name: {
			hy: "Շահագրգիռների համաձայնեցում",
			en: "Agreement on equipment",
			ru: "Согласование оборудования"
		},
		bypass: true
	},
	{
		_id: 12,
		name: {
			hy: "Շահագրգիռների տրամադրում",
			en: "Supply of equipment",
			ru: "Поставка оборудования"
		},
		bypass: true
	},
	{
		_id: 13,
		name: {
			hy: "ՏԻՄ դիմում",
			en: "TIM application",
			ru: "TIM заявка"
		}
	},
	{
		_id: 14,
		name: {
			hy: "ՏԻՄ համաձայնեցում",
			en: "TIM agreement",
			ru: "TIM согласование"
		}
	},
	{
		_id: 15,
		name: {
			hy: "Նախնակաան ZIP Փաթեթի տրամադրում",
			en: "Provision of preliminary ZIP package",
			ru: "Предварительная поставка ZIP пакета"
		}
	},
	{
		_id: 16,
		name: {
			hy: "Նախնակաան ՀԷՑ հանձնում",
			en: "Provision of preliminary EHGS",
			ru: "Предварительная ЭХГС поставка"
		}
	},
	{
		_id: 17,
		name: {
			hy: "Գանձապետարանի դիմում",
			en: "Application for a building permit",
			ru: "Заявление на строительное разрешение"
		},
		bypass: true
	},
	{
		_id: 18,
		name: {
			hy: "Գանձապետարանի հաստատում",
			en: "Approval of the building permit",
			ru: "Утверждение строительного разрешения"
		},
		bypass: true
	},
	{
		_id: 19,
		name: {
			hy: "Շին թույլտվության դիմում",
			en: "Application for construction permission",
			ru: "Заявление на разрешение на строительство"
		},
		bypass: true
	},
	{
		_id: 20,
		name: {
			hy: "Շին թույլտվության տրամադրում",
			en: "Granting of construction permission",
			ru: "Предоставление разрешения на строительство"
		},
		bypass: true
	},
	{
		_id: 21,
		name: {
			hy: "Ծավալի կազմում",
			en: "Volume formation",
			ru: "Формирование объема"
		}
	},
	{
		_id: 22,
		name: {
			hy: "Շինարարական բաժնին առաջադրանքի տրամադրում",
			en: "Provision of a contract to the construction department",
			ru: "Предоставление договора в строительный отдел"
		},
		bypass: true
	},
	{
		_id: 23,
		name: {
			hy: "Շինարարական առաջադրանքի հանձնում",
			en: "Provision of a construction contract",
			ru: "Предоставление строительного договора"
		},
		bypass: true
	},
	{
		_id: 24,
		name: {
			hy: "Ծավալի ստուգում",
			en: "Checking the volume",
			ru: "Проверка объема"
		}
	},
	{
		_id: 25,
		name: {
			hy: "Ծավալի ուղղում",
			en: "Volume adjustment",
			ru: "Регулировка объема"
		}
	},
	{
		_id: 26,
		name: {
			hy: "Ծավալի կրկնակի ստուգում",
			en: "Checking the volume of the duplicate",
			ru: "Проверка объема дубликата"
		}
	},
	{
		_id: 27,
		name: {
			hy: "Ծավալների տրամադրում նախահաշվի",
			en: "Provision of volumes in advance",
			ru: "Предоставление объемов вперед"
		}
	},
	{
		_id: 28,
		name: {
			hy: "Նախահաշվի համակարգում",
			en: "Preliminary accounting system",
			ru: "Система предварительного учета"
		}
	},
	{
		_id: 29,
		name: {
			hy: "Նախահաշվի սկիզբ",
			en: "Start of preliminary accounting",
			ru: "Начало предварительного учета"
		},
		bypass: true
	},
	{
		_id: 30,
		name: {
			hy: "Նախահաշվի ստուգողի ընտրությունը",
			en: "Selection of the preliminary accounting checker",
			ru: "Выбор проверяющего предварительный учет"
		},
		bypass: true
	},
	{
		_id: 31,
		name: {
			hy: "Նախահաշվի ստուգում",
			en: "Checking the preliminary accounting",
			ru: "Проверка предварительного учета"
		},
		bypass: true
	},
	{
		_id: 32,
		name: {
			hy: "նախահաշվողի հաստատողի ընտրություն",
			en: "Selection of the preliminary accounting approver",
			ru: "Выбор утверждающего предварительный учет"
		},
		bypass: true
	},
	{
		_id: 33,
		name: {
			hy: "Նախահաշվի հաստատում",
			en: "Approval of preliminary accounting",
			ru: "Утверждение предварительного учета"
		},
		bypass: true
	},
	{
		_id: 34,
		name: {
			hy: "Փաթեթի կազմում",
			en: "Compilation of the package",
			ru: "Составление пакета"
		}
	},
	{
		_id: 35,
		name: {
			hy: "Փաթեթի ստուգում",
			en: "Checking the package",
			ru: "Проверка пакета"
		}
	},
	{
		_id: 36,
		name: {
			hy: "Տրամադրում բազմացման",
			en: "Granting of multiplication",
			ru: "Предоставление умножения"
		}
	},
	{
		_id: 37,
		name: {
			hy: "Նախագծի բազմացում",
			en: "Multiplication of the project",
			ru: "Умножение проекта"
		}
	},
	{
		_id: 38,
		name: {
			hy: "ZIP տրամադրում ՀԷՑ",
			en: "ZIP provision to EHGS",
			ru: "Предоставление ZIP ЭХГС"
		}
	},
	{
		_id: 39,
		name: {
			hy: "Գանձապետարանի դիմումի համակարգում",
			en: "Building permit system",
			ru: "Система строительного разрешения"
		},
		bypass: true
	},
	{
		_id: 40,
		name: {
			hy: "Գանձապետարանի դիմում",
			en: "Application for a building permit",
			ru: "Заявление на строительное разрешение"
		},
		bypass: true
	},
	{
		_id: 41,
		name: {
			hy: "Գանձապետարանի հաստատում",
			en: "Approval of the building permit",
			ru: "Утверждение строительного разрешения"
		},
		bypass: true
	},
	{
		_id: 42,
		name: {
			hy: "Շին թույլտվության դիմում",
			en: "Application for construction permission",
			ru: "Заявление на разрешение на строительство"
		},
		bypass: true
	},
	{
		_id: 43,
		name: {
			hy: "Շին թույլտվության տրամադրում",
			en: "Granting of construction permission",
			ru: "Предоставление разрешения на строительство"
		},
		bypass: true
	},
	{
		_id: 44,
		name: {
			hy: "ՇԹ-ի տրամադրում ՀԷՑ",
			en: "Granting of STP to EHGS",
			ru: "Предоставление СТП ЭХГС"
		},
		bypass: true
	},
	{
		_id: 45,
		name: {
			hy: "Ակտ",
			en: "Act",
			ru: "Акт"
		}
	}
];

const brand = {
	name: "APIT admin panel",
	link: "/home",
	logo: "/logo.svg"
};

const menu = [
	{
		title: {
			en: "Database",
			ru: "База данных",
			hy: "Տվյալների բազա"
		},
		route: "database",
		icon: "DB.svg",
		menu: [
			{
				name: { en: "Projects", ru: "Проекты", hy: "Նախագծեր" },
				icon: "project-svgrepo-com.svg",
				route: "projects",
				permissionModel: "project"
			},
			{
				name: {
					en: "Branch",
					ru: "Филиал электронной сети",
					hy: "Մասնաճյուղ և էլ․ ցանցեր"
				},
				icon: "branch-global-news-svgrepo-com.svg",
				route: "branch",
				permissionModel: "branch"
			},
			{
				name: {
					en: "Voltage level",
					ru: "Уровень напряжения",
					hy: "Լարման մակարդակ"
				},
				icon: "volt-svgrepo-com.svg",
				route: "voltage",
				permissionModel: "voltage"
			},
			{
				name: {
					en: "Project Type",
					ru: "Тип проекта",
					hy: "Նախագծի տեսակը"
				},
				icon: "type-svgrepo-com.svg",
				route: "project-type",
				permissionModel: "projectType"
			},
			{
				name: {
					en: "Community",
					ru: "Сообщество",
					hy: "Համայնք"
				},
				icon: "community-svgrepo-com.svg",
				route: "community",
				permissionModel: "community"
			},
			{
				name: {
					en: "City",
					ru: "Город",
					hy: "Քաղաք"
				},
				icon: "city-buildings-svgrepo-com.svg",
				route: "city",
				permissionModel: "city"
			},
			{
				name: {
					en: "Village",
					ru: "Деревня",
					hy: "Գյուղ"
				},
				icon: "village-svgrepo-com.svg",
				route: "village",
				permissionModel: "village"
			},
			{
				name: {
					en: "Find anything",
					ru: "Найти что-нибудь",
					hy: "Ընդհանուր ֆիլտր"
				},
				icon: "search-globe-svgrepo-com.svg",
				route: "project-search",
				permissionModel: "project-search"
			}
		]
	},
	{
		title: {
			en: "Human Resources",
			ru: "Кадры",
			hy: "Անձնակազմ"
		},
		route: "hr",
		icon: "HR.svg",
		menu: [
			{
				name: {
					en: "All",
					ru: "Все",
					hy: "Բոլորը"
				},
				icon: "users-svgrepo-com.svg",
				route: "users/all",
				permissionModel: "userAll"
			},
			{
				name: {
					en: "Archive",
					ru: "Архив",
					hy: "Արխիվ"
				},
				icon: "user-question-alt-1-svgrepo-com.svg",
				route: "users/archive",
				permissionModel: "userArchive"
			},
			{
				name: {
					en: "Interviewed",
					ru: "Собеседование",
					hy: "Հարցազրույց"
				},
				icon: "user-question-alt-1-svgrepo-com.svg",
				route: "users/interview1",
				permissionModel: "userInterview1"
			},
			{
				name: {
					en: "Interviewed 2",
					ru: "Знакомство",
					hy: "Ծանոթություն"
				},
				icon: "user-check-svgrepo-com.svg",
				route: "users/interview2",
				permissionModel: "userInterview2"
			},
			{
				name: {
					en: "Training",
					ru: "Обучение",
					hy: "Ուսուցում"
				},
				icon: "user-heart-alt-1-svgrepo-com.svg",
				route: "users/interview3",
				permissionModel: "userInterview3"
			},
			{
				name: {
					en: "Internship",
					ru: "Стажирование",
					hy: "Փորձաշրջան"
				},
				icon: "user-heart-alt-1-svgrepo-com.svg",
				route: "users/interview4",
				permissionModel: "userInterview4"
			},
			{
				name: { en: "Employees", ru: "Сотрудники", hy: "Աշխատակիցներ" },
				icon: "user-shield-alt-1-svgrepo-com.svg",
				route: "users/working",
				permissionModel: "user"
			},
			{
				name: { en: "Departments", ru: "Отделения", hy: "Բաժիններ" },
				icon: "building-svgrepo-com.svg",
				route: "department",
				permissionModel: "department"
			},
			{
				name: { en: "Positions", ru: "Должности", hy: "Հաստիքներ" },
				icon: "position-man-svgrepo-com.svg",
				route: "position",
				permissionModel: "position"
			},
			{
				name: {
					en: "Fired",
					ru: "Уволенные",
					hy: "Պայմանագրի լուծում"
				},
				icon: "user-xmark-alt-1-svgrepo-com.svg",
				route: "users/fired",
				permissionModel: "userFired"
			},
			{
				name: {
					en: "Maternity Leave",
					ru: "Декретный отпуске",
					hy: "Դեկրետ"
				},
				icon: "25-baby-s-svgrepo-com.svg",
				route: "users/maternityLeave",
				permissionModel: "userLeave"
			},
			{
				name: {
					en: "Roles",
					ru: "Роли",
					hy: "Դերեր"
				},
				icon: "tickets-svgrepo-com.svg",
				route: "role",
				permissionModel: "role"
			}
		]
	},
	{
		title: {
			en: "IT",
			ru: "ИТ",
			hy: "ՏՏ"
		},
		route: "it",
		icon: "IT.svg",
		menu: [
			{
				name: {
					en: "IT Dashboard",
					ru: "IT Панель",
					hy: "IT Վահանակ"
				},
				icon: "dashboard-svgrepo-com.svg",
				route: "it-dashboard",
				permissionModel: "itDashboard"
			},
			{
				name: { en: "Ticket", ru: "Билеты", hy: "Տոմս" },
				icon: "tickets-svgrepo-com.svg",
				route: "ticket",
				permissionModel: "ticket"
			},
			{
				name: { en: "PC", ru: "ПК", hy: "Համակարգիչներ" },
				icon: "pc-svgrepo-com.svg",
				route: "pc",
				permissionModel: "pc"
			},
			{
				name: {
					en: "Routers",
					ru: "Маршрутизаторы",
					hy: "Հեռահաղորդակցություն"
				},
				icon: "router-svgrepo-com.svg",
				route: "router",
				permissionModel: "router"
			},
			{
				name: {
					en: "Monitor",
					ru: "Монитор",
					hy: "Մոնիտոր"
				},
				icon: "monitor-svgrepo-com.svg",
				route: "monitor",
				permissionModel: "monitor"
			},
			{
				name: {
					en: "Switch",
					ru: "Коммутатор",
					hy: "Անջատիչ"
				},
				icon: "switcher-svgrepo-com.svg",
				route: "switch",
				permissionModel: "switch"
			},
			{
				name: { en: "Printers", ru: "Принтеры", hy: "Տպիչներ" },
				icon: "printer-svgrepo-com.svg",
				route: "printer",
				permissionModel: "printer"
			},
			{
				name: {
					en: "Server folder",
					ru: "Папки сервера",
					hy: "Սերվերի պանակ"
				},
				icon: "folder-svgrepo-com.svg",
				route: "smb",
				permissionModel: "smb"
			},
			{
				name: {
					ru: "Программные обеспечения",
					en: "Software",
					hy: "Ծրագիր"
				},
				icon: "software-svgrepo-com.svg",
				route: "software",
				permissionModel: "software"
			},
			{
				name: {
					ru: "Статусы",
					en: "Statuses",
					hy: "Ստատուսներ"
				},
				icon: "status-svgrepo-com.svg",
				route: "status",
				permissionModel: "status"
			},
			{
				name: {
					ru: "Проблемы",
					en: "Problems",
					hy: "Խնդիրներ"
				},
				icon: "status-svgrepo-com.svg",
				route: "problem",
				permissionModel: "problem"
			},
			{
				name: {
					en: "Room",
					ru: "Комната",
					hy: "Սենյակ"
				},
				icon: "room-svgrepo-com.svg",
				route: "room",
				permissionModel: "room"
			},
			{
				name: {
					en: "Office Map",
					ru: "Карта офиса",
					hy: "Օֆիսի քարտեզ"
				},
				icon: "map-svgrepo-com.svg",
				route: "office-map",
				permissionModel: "officeMap"
			}
		]
	},
	{
		title: {
			en: "Chat",
			ru: "Чат",
			hy: "Չատ"
		},
		icon: "CHAT.svg",
		route: "chat",
		permissionModel: "chat",
		menu: [
			{
				name: {
					en: "Chats",
					ru: "Чаты",
					hy: "Չատեր"
				},
				icon: "dashboard-svgrepo-com.svg",
				route: "chat",
				permissionModel: "chat"
			}
		]
	}
];

const notificationSettings = {
	position: "top-right",
	autoClose: 2500,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	save: function (state, lang) {
		if (state === "success") {
			return {
				title: T("saved", lang),
				message: T("yourFileHasBeenSaved", lang),
				autoClose: this.autoClose,
				color: "green"
			};
		}
		if (state === "error") {
			return {
				title: T("error", lang),
				message: T("yourFileHasBeenNotSaved", lang),
				color: "red"
			};
		}
	},
	delete: function (state, lang) {
		if (state === "success") {
			return {
				title: T("deleted", lang),
				message: T("yourFileHasBeenDeleted", lang),
				autoClose: this.autoClose,
				color: "green"
			};
		}
		if (state === "error") {
			return {
				title: T("error", lang),
				message: T("yourFileHasBeenNotSaved", lang),
				color: "red"
			};
		}
	}
};

const routerSpeeds = [
	{
		label: "100 MB/s",
		value: "100 MB/s"
	},
	{
		label: "300 MB/s",
		value: "300 MB/s"
	},
	{
		label: "500 MB/s",
		value: "500 MB/s"
	},
	{
		label: "1000 MB/s",
		value: "1000 MB/s"
	}
];

const rams = [
	{
		label: "4GB",
		value: "4GB"
	},
	{
		label: "8GB",
		value: "8GB"
	},
	{
		label: "16GB",
		value: "16GB"
	},
	{
		label: "32GB",
		value: "32GB"
	},
	{
		label: "64GB",
		value: "64GB"
	},
	{
		label: "128GB",
		value: "128GB"
	}
];

const roms = [
	{
		label: "32GB",
		value: "32GB"
	},
	{
		label: "64GB",
		value: "64GB"
	},
	{
		label: "SSD 120GB",
		value: "SSD 120GB"
	},
	{
		label: "SSD 240GB",
		value: "SSD 240GB"
	},
	{
		label: "SSD 500GB",
		value: "SSD 500GB"
	},
	{
		label: "SSD 1TB",
		value: "SSD 1TB"
	},
	{
		label: "SSD 2TB",
		value: "SSD 2TB"
	},
	{
		label: "HDD 250GB",
		value: "HDD 250GB"
	},
	{
		label: "HDD 320GB",
		value: "HDD 320GB"
	},
	{
		label: "HDD 500GB",
		value: "HDD 500GB"
	},
	{
		label: "HDD 1TB",
		value: "HDD 1TB"
	},
	{
		label: "HDD 2TB",
		value: "HDD 2TB"
	}
];

const config = {
	api,
	authentication: authentication,
	steps,
	brand,
	menu,
	notificationSettings,
	routerSpeeds,
	rams,
	roms
};

export default config;
