import React, { createContext, useContext, useState, useEffect } from "react";

// Create the Context
const BrowserStateContext = createContext();

// Context Provider Component
export const BrowserStateProvider = ({ children }) => {
	const [browserState, setBrowserState] = useState({
		isHidden: false,
		isMinimized: false,
		isFocused: true
	});

	useEffect(() => {
		const handleVisibilityChange = () => {
			setBrowserState((prevState) => ({
				...prevState,
				isHidden: document.hidden
			}));
		};

		const handleBlur = () => {
			setBrowserState((prevState) => ({
				...prevState,
				isFocused: false
			}));
		};

		const handleFocus = () => {
			setBrowserState((prevState) => ({
				...prevState,
				isFocused: true
			}));
		};

		const checkMinimized = () => {
			const isMinimized = window.outerWidth === 0 && window.outerHeight === 0;
			setBrowserState((prevState) => ({
				...prevState,
				isMinimized
			}));
		};

		// Add event listeners
		document.addEventListener("visibilitychange", handleVisibilityChange);
		window.addEventListener("blur", handleBlur);
		window.addEventListener("focus", handleFocus);
		window.addEventListener("resize", checkMinimized);

		// Initial checks
		handleVisibilityChange();
		checkMinimized();

		return () => {
			// Cleanup event listeners
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("blur", handleBlur);
			window.removeEventListener("focus", handleFocus);
			window.removeEventListener("resize", checkMinimized);
		};
	}, []);

	return (
		<BrowserStateContext.Provider value={browserState}>{children}</BrowserStateContext.Provider>
	);
};

// Custom Hook to Use the Context
export const useBrowserState = () => {
	return useContext(BrowserStateContext);
};
