import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import { useLang } from "../../helpers/language";
import NProgress, { set } from "nprogress";
import AddPage from "./chatAdd";
import { useSocket } from "../../helpers/socket";
import parse from "html-react-parser";
import { T } from "../../helpers/translator";
import { showNotification } from "@mantine/notifications";
import {
	ActionIcon,
	Avatar,
	Box,
	Button,
	Card,
	CloseIcon,
	Divider,
	Flex,
	Group,
	Image,
	Indicator,
	Modal,
	rem,
	ScrollArea,
	Stack,
	Table,
	Text,
	TextInput,
	Title,
	useMantineColorScheme
} from "@mantine/core";
import {
	IconMicrophone,
	IconMicrophoneOff,
	IconPaperclip,
	IconPhoneOff,
	IconPin,
	IconSearch,
	IconStar,
	IconTrash,
	IconUser
} from "@tabler/icons-react";
import Message from "./message/Message";
import Forward from "./forward/forward";
import UserInfo from "./user-info/UserInfo";
import Search from "./search/search";
import FileSearch from "./fileSearch/fileSearch";
import PinnedStared from "./pinned-stared/PinnedStared";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Reply from "./reply/reply";
import Input from "../modules/input";
import { Calling } from "./calling";

export default function ChatDirectAdd() {
	const [isBusy, setBusy] = useState(true);
	let id = useLocation().pathname.split("/")[2];
	let { search } = useLocation();
	search = search.substring(1).split("=");
	let tempMode = search[0] === "temporary" && search[1] === "true";
	const { connectedUsers = [], socket, setNewNotification } = useSocket();
	const { lang } = useLang();
	const { user, setUser } = useUser();
	const [permissions, setPermissions] = useState([]);
	const { colorScheme } = useMantineColorScheme();
	const viewport = useRef(null);
	const [partner, setPartner] = useState([]);
	const [messages, setMessages] = useState([]);
	const [favorite, setFavorite] = useState(false);
	const [input, setInput] = useState("");
	const [forwardData, setForwardData] = useState({});
	const [replyData, setReplyData] = useState({});
	const [pinnedMessages, setPinnedMessages] = useState(null);
	const [starredMessages, setStarredMessages] = useState(null);
	const [uploadFiles, setUploadFiles] = useState([]);
	const uploadFilesRef = useRef(uploadFiles);
	const [users, setUsers] = useState([]);
	const [chats, setChats] = useState([]);
	const [chat, setChat] = useState([]);
	const audioRef = useRef(new Audio("/assets/call.mp3"));

	const playAudio = () => {
		audioRef.current.play();
	};

	const stopAudio = () => {
		audioRef.current.pause(); // Pause the audio
		audioRef.current.currentTime = 0; // Reset to the beginning
	};

	let userConnectionStatus = connectedUsers.find(
		(user) => user._id === partner?._id
	)?.connectionStatus;

	// chat add constants
	const [objective, setObjective] = useState({
		index: "",
		name: "",
		desc: "",
		users: [],
		admins: [],
		parentChat: "",
		privateChat: false,
		readOnly: false
	});
	const [canSendState, setCanSendState] = useState(false);
	const scrollAreaRef = useRef(null);

	const modelSendToServer = {
		index: objective.index,
		name: objective.name,
		desc: objective.desc,
		users: objective.users,
		admins: objective.admins,
		parentChat: objective.parentChat,
		privateChat: objective.privateChat,
		readOnly: objective.readOnly,
		chatType: objective.users?.length > 1 ? (objective.readOnly ? "channel" : "group") : "direct"
	};

	const [audioFile, setAudioFile] = useState(null);
	const audioFileRef = useRef(audioFile);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadSuccess, setUploadSuccess] = useState(false);
	const [componentVisibility, setComponentVisibility] = useState({
		forwardOpened: false,
		userInfoDrawer: false,
		search: false,
		fileSearch: false,
		pinned: false,
		stared: false,
		reply: false
	});

	const remoteVideoRef = useRef(null);
	const myVideoRef = useRef();
	const otherVideoRef = useRef();
	const localVideoRef = useRef();
	const peerRef = useRef();
	const streamRef = useRef();
	const [roomId, setRoomId] = useState(id);
	const [myId, setMyId] = useState("");
	const [callStarted, setCallStarted] = useState(false);
	const [isVideoCall, setIsVideoCall] = useState(true);
	const [incomingCall, setIncomingCall] = useState(false);
	const [callerId, setCallerId] = useState(null);

	const columns = [
		{
			accessorFn: (row) => `${row.firstName?.en} ${row.firstName?.ru} ${row.firstName?.hy}`,
			header: T("firstName", lang),
			filterVariant: "multi-select",
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = parse(row.firstName?.[lang] ? row.firstName?.[lang] : "...");
				return result;
			}
		},
		{
			accessorFn: (row) => `${row.lastName?.en} ${row.lastName?.ru} ${row.lastName?.hy}`,
			header: T("lastName", lang),
			filterVariant: "multi-select",
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = parse(row.lastName?.[lang] ? row.lastName?.[lang] : "...");
				return result;
			}
		}
	];

	const editor = useEditor({
		extensions: [
			StarterKit,
			Placeholder.configure({ placeholder: "Type message" }),
			Underline,
			TextAlign,
			Superscript,
			SubScript
		],
		content: input,
		onUpdate: ({ editor }) => {
			if (editor.getHTML() === "<p></p>") {
				setInput("");
			} else {
				setInput(editor.getHTML());
			}
		}
	});

	const toggleComponentVisibility = (component) => {
		setComponentVisibility((prevState) => ({
			...prevState,
			[component]: !prevState[component]
		}));
	};

	const onForwardOpen = (msg) => {
		setForwardData({
			forwardMessage: msg.body,
			forwardUser: msg.from === user._id ? user : partner
		});
		toggleComponentVisibility("forwardOpened");
	};

	const scrollToBottom = () =>
		viewport?.current?.scrollTo({
			top: viewport?.current.scrollHeight,
			behavior: "smooth"
		});

	const sendMessage = (socket) => {
		if (input) {
			const container = scrollAreaRef.current;
			container.scrollTo({ top: container.scrollHeight, behavior: "smooth" });
			let newMessage = {
				from: user._id,
				to: [partner._id],
				chatId: window.location.pathname.split("/")[2],
				body: input,
				readBy: [user._id],
				created: new Date(),
				uploadInProgress: uploadFiles.length > 0 || audioFile ? true : false
			};
			setInput("");
			socket.emit("sendMessage", {
				newMessage
			});
		}
	};

	const onMessageDelete = ({ msg, deleteForEveryone }) => {
		socket.emit("deleteMessage", {
			message: msg,
			deleteForEveryone,
			userId: user._id,
			partnerId: partner._id
		});
	};

	const setFavoriteToApi = async () => {
		if (!favorite) {
			if (
				user.favorites?.find((favorite) => favorite._id === partner._id) ||
				user.favorites?.find((favorite) => favorite === partner._id)
			) {
				setFavorite(true);
				return;
			}
			let updatedUser = await API.update(config.api.user, user._id, {
				favorites: [...user.favorites, id]
			});
			if (updatedUser) {
				setUser({ ...user, favorites: [...updatedUser.favorites] });
				setFavorite(true);
			}
		} else {
			let updatedUser = await API.update(config.api.user, user._id, {
				favorites: user.favorites.filter((favorite) => favorite._id !== partner._id)
			});
			if (updatedUser) {
				setUser({ ...user, favorites: [...updatedUser.favorites] });
			}
			setFavorite(false);
		}
	};

	useEffect(() => {}, [messages]);

	useEffect(() => {
		if (objective) {
			let state = objective?.users?.length > 0 ? true : false;
			setCanSendState(state);
		}
	}, [objective]);

	useEffect(() => {
		uploadFilesRef.current = uploadFiles;
	}, [uploadFiles]);

	useEffect(() => {
		audioFileRef.current = audioFile;
	}, [audioFile]);

	useEffect(() => {
		(async () => {
			NProgress.start();

			if (!tempMode) {
				let currentItem = await API.get({
					route: config.api.chat,
					query: {
						_id: id
					},
					populate: [
						"users",
						{
							path: "users",
							populate: [
								{
									path: "department"
								},
								{
									path: "position"
								},
								{
									path: "role"
								}
							]
						},
						"admins",
						"parentChat"
					]
				});
				if (currentItem) setChat(currentItem[0]);
				console.log("🚀 ~ currentItem[0]:", currentItem[0]);

				if (currentItem[0]?.chatType === "direct") {
					let partner = currentItem[0].users.filter((use) => use._id !== user._id);
					try {
						let partnerUploads = await API.get({
							route: config.api.upload,
							query: { obj_id: partner[0]._id }
						});
						partner[0].uploads = partnerUploads;
					} catch (error) {
						console.log("🚀 ~ error:", error);
					}
					if (partner.length) setPartner(partner[0]);

					user.favorites?.forEach((favorite) => {
						if (favorite?._id === partner?.[0]?._id) {
							setFavorite(true);
						}
					});
				}

				try {
					// $or: [
					// 	{ $and: [{ from: id }, { to: user._id }] },
					// 	{ $and: [{ to: id }, { from: user._id }] }
					// ],
					let rawMessages = await API.get({
						route: config.api.message,
						query: {
							chatId: id,
							deletedFor: {
								$nin: [user._id]
							}
						},
						sort: {
							created: 1
						},
						populate: ["forward.from", "from"],
						// limit: 10
						uploadType: true
					});
					if (rawMessages) setMessages(rawMessages);
				} catch (error) {
					console.log("🚀 ~ error:", error);
				}
				setTimeout(() => {
					scrollToBottom();
				}, 0);
			} else {
				let users = [];
				try {
					users = await API.get({
						route: config.api.user,
						query: {
							_id: { $ne: user._id }
						},
						uploadType: true
					});
					setUsers(users);
				} catch (error) {
					console.log("🚀 ~ error:", error);
				}

				let chats = [];
				try {
					chats = await API.get({
						route: config.api.chat,
						query: {
							chatType: "direct",
							users: { $in: [user._id] }
						},
						uploadType: true
					});
					setChats(chats);
				} catch (error) {
					console.log("🚀 ~ error:", error);
				}
			}
			NProgress.done();
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, window.location.href]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "chat") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// if socket.event already exists, remove it
		if (socket?.listeners("newMessage").length > 0) {
			socket.off("newMessage");
		}

		socket?.on("messageUpdated", async (message) => {
			if (message.chatId === window.location.pathname.split("/")[2]) {
				// Fetch message uploads if necessary
				let messageUploads = await API.get({
					route: config.api.upload,
					query: {
						obj_id: message._id
					}
				});

				if (messageUploads) {
					message.uploads = messageUploads;
				}

				setMessages((oldMessages) => {
					// Check if the message already exists in oldMessages
					const messageExists = oldMessages.some((msg) => msg._id === message._id);

					if (messageExists) {
						// Update the existing message
						return oldMessages.map((msg) => (msg._id === message._id ? message : msg));
					} else {
						// Add the new message to the list
						return [...oldMessages, message];
					}
				});
			}
		});

		socket?.on("newMessage", async (newMessage) => {
			if (newMessage.from._id === user?._id) {
				console.log("🚀 ~ THIS IS SENDER");
				if (uploadFilesRef?.current?.length > 0 || audioFileRef.current) {
					if (uploadFilesRef?.current?.length > 0) {
						try {
							let uploadPromises = uploadFilesRef?.current?.map((file) =>
								API.upload(newMessage._id, [file], lang, "files", () => {}, new Date())
							);
							let results = await Promise.all(uploadPromises);
							let uploads = results.flat();
							if (uploads && Array.isArray(uploads)) {
								newMessage.uploads = uploads;
								setUploadFiles([]);
								socket.emit("messageUploadDone", {
									message: newMessage
								});
							}
						} catch (error) {
							console.log("🚀 ~ socket?.on ~ error:", error);
						}
					}
					if (audioFileRef.current) {
						try {
							let uploadPromises = [
								API.upload(newMessage._id, [audioFileRef.current], lang, "audio", () => {})
							];
							let results = await Promise.all(uploadPromises);
							let uploads = results.flat();
							if (uploads && Array.isArray(uploads)) {
								newMessage.uploads = uploads;
								setAudioFile(null);
								socket.emit("messageUploadDone", {
									message: newMessage
								});
							}
						} catch (error) {
							console.log("🚀 ~ socket?.on ~ error:", error);
						}
					}
				} else {
					setMessages((oldMessages) => {
						return [...oldMessages, newMessage];
					});
				}
			} else if (newMessage.chatId === window.location.pathname.split("/")[2]) {
				console.log("🚀 ~ THIS IS RECIEVER");
				setMessages((oldMessages) => {
					return [...oldMessages, newMessage];
				});
				// some logic moved to main.jsx for work in all platform pages
			} else {
				// just show notification
				let partner = await API.get({
					route: config.api.user,
					query: {
						_id: newMessage.from._id
					},
					uploadType: true,
					limit: 1,
					populate: ["department", "position", "role"]
				});
				let messageBody = newMessage?.body
					? newMessage?.body.length > 30
						? newMessage?.body.slice(0, 30)
						: newMessage?.body
					: "";
				setNewNotification({
					ID: new Date().getTime(),
					date: new Date().toISOString(),
					name: partner[0].name || {
						en: T("nothingFound", lang),
						ru: T("nothingFound", lang),
						hy: T("nothingFound", lang)
					},
					desc: {
						en: messageBody,
						ru: messageBody,
						hy: messageBody
					},
					importance: "medium",
					route: `${config.api.chat}/${partner?._id}`,
					createdByUser: partner[0],
					targetUsers: [
						{
							user: user?._id,
							read: false
						}
					]
				});
			}

			setTimeout(() => {
				scrollToBottom();
			}, 0);
		});

		socket?.on("messageDeleted", async (message) => {
			if (message.deletedFor.includes(user._id)) {
				setMessages((oldMessages) => {
					return oldMessages.filter((msg) => msg._id !== message._id);
				});
			}
		});

		return () => {
			socket?.off("newMessage");
			socket?.off("messageUpdated");
			socket?.off("messageDeleted");
		};

		// eslint-disable-next-line
	}, [socket]);

	let messagePreviousDate = null;

	console.log(partner);
	console.log(chat);

	if (isBusy) return <Loading />;
	else if (tempMode)
		return (
			<AddPage
				isBusy={isBusy}
				setBusy={setBusy}
				route={config.api.chat}
				id={id}
				permissionModel="ticket"
				modelSendToServer={modelSendToServer}
				permissions={permissions}
				canSendState={canSendState}
				user={user}
				inputs={[
					{
						value: objective.index,
						setter: (value) => {
							setObjective({
								...objective,
								index: value
							});
						},
						type: "textfield",
						usageType: "textfield",
						label: T("index", lang),
						permissionModel: "chat"
					},
					objective.users?.length > 1 && {
						value: objective.name,
						setter: (value) => {
							setObjective({
								...objective,
								name: value
							});
						},
						type: "textfield",
						usageType: "textfield",
						label: T("name", lang),
						permissionModel: "chat"
					},
					objective.users?.length > 1 && {
						value: objective.parentChat,
						setter: (value) => {
							setObjective({
								...objective,
								parentChat: value
							});
						},
						type: "optionList",
						usageType: "optionList",
						optionListValue: chats,
						label: T("parentChat", lang),
						permissionModel: "chat"
					},
					objective.users?.length > 1 && {
						value: objective.privateChat,
						setter: (value) => {
							setObjective({
								...objective,
								privateChat: value
							});
						},
						type: "checkbox",
						usageType: "checkbox",
						label: T("privateChat", lang),
						permissionModel: "chat"
					},
					objective.users?.length > 1 && {
						value: objective.readOnly,
						setter: (value) => {
							setObjective({
								...objective,
								readOnly: value
							});
						},
						type: "checkbox",
						usageType: "checkbox",
						label: T("readOnly", lang),
						permissionModel: "chat"
					},
					objective.users?.length > 1 && {
						value: objective.desc,
						setter: (value) => {
							setObjective({
								...objective,
								desc: value
							});
						},
						type: "textarea",
						usageType: "textarea",
						label: T("desc", lang),
						permissionModel: "chat",
						width: 12
					}
				]}
				cartComponent={{
					title: T("users", lang),
					data: [
						{
							placeholder: T("users", lang),
							value: objective.users,
							setter: (val) => {
								setObjective({
									...objective,
									users: [...val]
								});
							},
							data: users,
							columns: columns,
							width: "220px",
							quantity: "off",
							permissionModel: "chat"
						},
						{
							placeholder: T("admin", lang),
							value: objective.admins,
							setter: (val) => {
								setObjective({
									...objective,
									admins: [...val]
								});
							},
							data: users,
							columns: columns,
							width: "220px",
							quantity: "off",
							permissionModel: "chat"
						}
					]
				}}
			/>
		);
	else
		return (
			<>
				<Box padding="md">
					<Card withBorder radius="sm">
						<Flex justify={"space-between"} align={"center"}>
							<Flex align={"center"}>
								{chat.chatType === "direct" ? (
									<>
										<Indicator
											inline
											processing={
												userConnectionStatus
													? userConnectionStatus === "online"
														? true
														: false
													: false
											}
											color={
												userConnectionStatus === "online"
													? "green"
													: userConnectionStatus === "away"
														? "orange"
														: "grey"
											}
											size={14}
										>
											<Avatar
												size="lg"
												radius="sm"
												src={
													`${config.api.API_URL}/${partner?.uploads?.find((upl) => upl.destiny === "image").path}` ||
													""
												}
											/>
										</Indicator>
										<Text size="xl" ml={20}>
											{partner?.name?.[lang] || T("nothingFound", lang)}
										</Text>
										<Text size="sm" ml={20}>
											{partner?.role?.[0].name?.[lang] || T("nothingFound", lang)}
										</Text>
									</>
								) : (
									<>
										<Avatar
											size="lg"
											radius="sm"
											src={
												`${config.api.API_URL}/${chat?.uploads?.find((upl) => upl.destiny === "image").path}` ||
												""
											}
										/>{" "}
										<Text size="xl" ml={20}>
											{chat?.name || T("nothingFound", lang)}
										</Text>
										<Text size="sm" ml={20}>
											{T(chat.chatType, lang)}
										</Text>
									</>
								)}

								<Button w={40} variant="subtle" p={5} size="xs" onClick={setFavoriteToApi}>
									{favorite ? (
										<Image
											src="/assets/favorite-interface-multimedia-svgrepo-com.svg"
											alt="star"
											style={{
												filter: `invert(${favorite ? "0" : "1"})`
											}}
											width={20}
											height={20}
										/>
									) : (
										<Image
											src="/assets/favorite-svgrepo-com.svg"
											alt="star"
											style={{
												filter: `invert(${favorite ? "0" : "1"})`
											}}
											width={20}
											height={20}
										/>
									)}
								</Button>
							</Flex>
							<Group justify="right" gap="xs">
								<ActionIcon
									variant="default"
									aria-label="userInfoDrawer"
									onClick={() => toggleComponentVisibility("userInfoDrawer")}
								>
									<IconUser style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="search"
									onClick={() => toggleComponentVisibility("search")}
								>
									<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="fileSearch"
									onClick={() => toggleComponentVisibility("fileSearch")}
								>
									<IconPaperclip style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="Pinned"
									onClick={async () => {
										try {
											const pinnedMessages = await API.get({
												route: config.api.chat,
												query: {
													$or: [
														{ $and: [{ from: id }, { to: user._id }] },
														{ $and: [{ to: id }, { from: user._id }] }
													],
													deletedFor: {
														$nin: [user._id]
													},
													pinned: true
												},
												sort: {
													created: -1
												}
											});
											if (pinnedMessages) {
												setPinnedMessages(pinnedMessages);
											}
										} catch (e) {
											console.log(e.message);
										}
										toggleComponentVisibility("pinned");
									}}
								>
									<IconPin style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
								<ActionIcon
									variant="default"
									aria-label="Starred"
									onClick={async () => {
										try {
											const starredMessages = await API.get({
												route: config.api.chat,
												query: {
													$or: [
														{ $and: [{ from: id }, { to: user._id }] },
														{ $and: [{ to: id }, { from: user._id }] }
													],
													deletedFor: {
														$nin: [user._id]
													},
													starred: true
												},
												sort: {
													created: -1
												}
											});
											if (starredMessages) {
												setStarredMessages(starredMessages);
											}
										} catch (e) {
											console.log(e.message);
										}
										toggleComponentVisibility("stared");
									}}
								>
									<IconStar style={{ width: rem(16), height: rem(16) }} stroke={2} />
								</ActionIcon>
							</Group>
						</Flex>
					</Card>
				</Box>
				<ScrollArea
					ref={scrollAreaRef}
					viewportRef={viewport}
					style={{ height: "calc(100vh - 90px)" }}
				>
					<Table
						highlightOnHover
						withRowBorders={false}
						spacing="xs"
						style={{
							borderRadius: 3
						}}
					>
						<Table.Tbody>
							{messages?.map((msg, index) => {
								const currentDate = moment(msg.created).format("DD-MM-YYYY");
								const showDateHeader = currentDate !== messagePreviousDate;
								messagePreviousDate = currentDate;
								return (
									<Table.Tr key={index}>
										<div key={msg._id}>
											{showDateHeader && (
												<Divider
													my="xs"
													label={moment(msg.created).format("DD.MM.YYYY")}
													labelPosition="center"
												/>
											)}
											<Message
												message={msg}
												fromUser={msg.from}
												toUser={partner}
												user={user}
												setBusy={setBusy}
												isBusy={isBusy}
												setFiles={setUploadFiles}
												onRead={(userId) => {
													socket.emit("readMessage", {
														message: msg,
														userId
													});
												}}
												onReply={() => {
													toggleComponentVisibility("reply");
													setReplyData(msg);
												}}
												onForward={() => {
													onForwardOpen(msg);
												}}
												onDelete={(deleteForEveryone) => {
													onMessageDelete({ msg, deleteForEveryone });
												}}
												onPinned={async () => {
													setBusy(true);
													try {
														await API.update("message", msg._id, { pinned: !msg.pinned });
													} catch (e) {
														console.log(e.message);
													} finally {
														setBusy(false);
													}
												}}
												onStarred={async () => {
													setBusy(true);
													try {
														await API.update("message", msg._id, { starred: !msg.starred });
													} catch (e) {
														console.log(e.message);
													} finally {
														setBusy(false);
													}
												}}
											/>
										</div>
									</Table.Tr>
								);
							})}
						</Table.Tbody>
					</Table>
				</ScrollArea>
				<UserInfo
					open={componentVisibility.userInfoDrawer}
					onClose={() => toggleComponentVisibility("userInfoDrawer")}
					data={partner}
				/>
				<Search
					open={componentVisibility.search}
					onClose={() => toggleComponentVisibility("search")}
				/>
				<FileSearch
					open={componentVisibility.fileSearch}
					onClose={() => toggleComponentVisibility("fileSearch")}
				/>
				<Forward
					opened={componentVisibility.forwardOpened}
					onClose={() => toggleComponentVisibility("forwardOpened")}
					data={forwardData}
				/>
				<PinnedStared
					open={componentVisibility.pinned}
					onClose={() => toggleComponentVisibility("pinned")}
					title="Pinned messages"
					data={pinnedMessages}
					partner={partner}
				/>
				<PinnedStared
					open={componentVisibility.stared}
					onClose={() => toggleComponentVisibility("stared")}
					title="Stared messages"
					data={starredMessages}
					partner={partner}
				/>
				<Reply
					open={componentVisibility.reply}
					onClose={() => toggleComponentVisibility("reply")}
					title="Reply"
					data={replyData}
				/>
				<Group position="apart" style={{ marginTop: "auto", padding: "10px 0" }} grow={1}>
					<Flex direction={"column"} w={"100%"}>
						{uploadFiles?.length > 0 && (
							<ScrollArea w={"100%"} mb={10} scrollbars="x" viewportRef={viewport}>
								<Flex align="end" direction={"row"} justify="center" wrap>
									{uploadFiles?.map((file, index) => (
										<Card key={index} shadow="sm" padding="lg" m={10} radius="md" withBorder>
											{file.type.startsWith("image/") && (
												<Image
													src={URL.createObjectURL(file)}
													alt={file.name}
													width={100}
													height={100}
												/>
											)}

											<Text size="sm" mt={14}>
												{file.name.slice(0, 10)}
											</Text>

											<Button
												onClick={() => {
													setUploadFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
												}}
												color="red"
												style={{
													position: "absolute",
													top: 5,
													right: 5,
													opacity: 0.8,
													width: "20px",
													height: "20px",
													padding: "0"
												}}
											>
												<CloseIcon
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
													m={0}
												/>
											</Button>
										</Card>
									))}
								</Flex>
							</ScrollArea>
						)}

						<Input
							value={input}
							setter={setInput}
							type="textarea"
							usageType="textarea"
							attachment={{
								files: uploadFiles,
								setter: setUploadFiles
							}}
							audioRecorder={{
								setter: async () => {
									if (!audioFile) return;
									// try {
									// 	const response = await API.upload(
									// 		"1234134134134", // Replace with your object ID
									// 		[audioFile],
									// 		"en", // Language
									// 		"audio-destination", // Destination
									// 		0, // Thumbnail width (not relevant for audio)
									// 		0, // Thumbnail height (not relevant for audio)
									// 		false, // Responsive images (not relevant for audio)
									// 		"Recorded audio", // Alt text
									// 		setUploadProgress // Progress callback
									// 	);
									// 	console.log("Upload successful:", response);
									// 	setUploadSuccess(true);
									// } catch (error) {
									// 	console.error("Upload failed:", error);
									// }
								},
								audioURL: "",
								setAudioURL: () => {},
								uploadProgress: uploadProgress,
								uploadSuccess: uploadSuccess,
								audioFile: audioFile,
								setAudioFile: setAudioFile
							}}
							lang={lang}
							crud={{
								read: true,
								update: true
							}}
							textAreaProps={{
								submit: () => sendMessage(socket),
								submitOnEnter: true,
								attachment: true,
								audioRecorder: true,
								emoji: true
							}}
						/>
					</Flex>
				</Group>

				{/* <Calling partner={partner} setBusy={setBusy} /> */}
			</>
		);
}
