import React, { useState, useEffect } from "react";
import StepPage from "../StepPage";
import * as API from "../../../helpers/api";
import config from "../../../config";
// import getUploads from "../../../helpers/getUploads";
import { useUser } from "../../../helpers/userContext";
import moment from "moment";
import { IconCalendarClock, IconCalendarWeek } from "@tabler/icons-react";
import { IconTimeline } from "@tabler/icons-react";
import LoadingSkeleton from "./loadingSceleton";
import { T } from "../../../helpers/translator";
import parse from "html-react-parser";

export default function Step({
	route,
	id,
	ID,
	setID,
	modelSendToServer,
	destiny,
	lang,
	history,
	step,
	UpdateStep,
	steps,
	setStep,
	localSteps,
	activeStep,
	setActiveStep,
	isBusy,
	setBusy,
	priority,
	setPriority,
	projectType,
	setProjectType,
	priorities,
	projectTypes,
	setProjectTypes,
	projectStartDate,
	setProjectStartDate,
	handleStepChange,
	projectEndDate,
	setProjectEndDate,
	voltage,
	branch,
	city,
	village,
	community,
	permissions,
	openedInner
}) {
	const [isComponentBusy, setComponentBusy] = useState(true);
	// queue is set automatically when sending to server
	const { user } = useUser();
	const [History, setHistory] = useState([{}]);
	const [responsible, setResponsible] = useState([]);
	const [selectedTimeInterval, setSelectedTimeInterval] = useState({});
	const [timeLineSelected, setTimeLineSelected] = useState(1);

	//step specific variables
	const [index, setIndex] = useState(0);
	const [startDate, setStartDate] = useState([]);
	const [endDate, setEndDate] = useState([]);
	const [deadline, setDeadline] = useState("");
	const [Id, setCurrentStepId] = useState("");
	const [currentSTEP, setCurrentSTEP] = useState({});

	// inputs variables
	const [descEn, setDescEn] = useState("");
	const [descRu, setDescRu] = useState("");
	const [descHy, setDescHy] = useState("");
	const [responsibleStep13_14, setResponsibleStep13_14] = useState("");

	const [allUsers, setAllUsers] = useState([]);

	const [historySteps, setHistorySteps] = useState([]);

	let otherFields = {
		desc: { en: descEn, ru: descRu, hy: descHy },
		steps: [
			{ index: 13, responsible: responsibleStep13_14 },
			{ index: 14, responsible: responsibleStep13_14 }
		].filter((step) => step.responsible && step.responsible.length > 0)
	};

	const columns = [
		{
			accessorFn: (row) => `${row.firstName?.en} ${row.firstName?.ru} ${row.firstName?.hy}`,
			header: T("firstName", lang),
			filterVariant: "multi-select",
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = parse(row.firstName?.[lang] ? row.firstName?.[lang] : "...");
				return result;
			}
		},
		{
			accessorFn: (row) => `${row.lastName?.en} ${row.lastName?.ru} ${row.lastName?.hy}`,
			header: T("lastName", lang),
			filterVariant: "multi-select",
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = parse(row.lastName?.[lang] ? row.lastName?.[lang] : "...");
				return result;
			}
		}
	];

	useEffect(() => {
		(async () => {
			try {
				let currentStep = await API.get({
					route: config.api.step,
					query: {
						project_id: id,
						queue: step
					},
					uploadType: true,
					limit: 1,
					populate: ["steps.responsible", "steps.users"]
				});

				// set responsible from branch
				if (branch?.steps?.find((item) => item.index === step)?.responsible) {
					let responsible = branch.steps
						?.find((item) => item.index === step)
						.responsible.map(async (resp) => {
							let uploads = await API.get({
								route: config.api.upload,
								query: {
									obj_id: resp._id
								}
							});
							return { ...resp, uploads };
						});
					try {
						await Promise.all(responsible).then(setResponsible);
					} catch (error) {
						console.error(error);
					}
				}
				
				if (branch?.steps?.find((item) => item.index === step)?.users) {
					setAllUsers(branch?.steps?.find((item) => item.index === step)?.users);
				}

				// set all states from server
				currentStep[0] && setCurrentSTEP(currentStep[0]);
				currentStep[0]?._id && setCurrentStepId(currentStep[0]?._id);
				currentStep[0]?.startDate && setStartDate(currentStep[0]?.startDate);
				currentStep[0]?.endDate && setEndDate(currentStep[0]?.endDate);
				currentStep[0]?.history && setHistory(currentStep[0]?.history);
				currentStep[0]?.desc?.en && setDescEn(currentStep[0]?.desc.en);
				currentStep[0]?.desc?.ru && setDescRu(currentStep[0]?.desc.ru);
				currentStep[0]?.desc?.hy && setDescHy(currentStep[0]?.desc.hy);
				currentStep[0]?.steps?.find((item) => item.index === 13)?.responsible &&
					setResponsibleStep13_14(
						currentStep[0]?.steps?.find((item) => item.index === 13)?.responsible
					);
				currentStep[0]?.steps?.find((item) => item.index === 14)?.responsible &&
					setResponsibleStep13_14(
						currentStep[0]?.steps?.find((item) => item.index === 14)?.responsible
					);
				currentStep[0]?.steps?.find((item) => item.index === 13)?.users &&
					setAllUsers(currentStep[0]?.steps?.find((item) => item.index === 13)?.users);

				let historySteps = [];
				if (currentStep[0]?.history) {
					for (let i = 0; i < currentStep[0]?.history.length; i++) {
						if (currentStep[0]?.history[i].status === "inProgress") {
							// historySteps.push(currentStep[0]?.history[i]);
							historySteps.push({
								startDate: currentStep[0]?.history?.[i].date,
								endDate: currentStep[0]?.history?.[i + 1]?.date,
								changes: {}
							});
						}
					}
				}
				setHistorySteps(historySteps);
				setTimeLineSelected(historySteps.length - 1);
				setSelectedTimeInterval({
					startDate: historySteps[historySteps.length - 1]?.startDate,
					endDate: historySteps[historySteps.length - 1]?.endDate
				});

				setDeadline(voltage?.deadline?.step10);
			} catch (error) {
				console.log(error);
			}

			// eslint-disable-next-line
			setBusy(false);
			setComponentBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, isComponentBusy]);

	if (isComponentBusy) return <LoadingSkeleton />;
	return (
		<StepPage
			isBusy={isBusy}
			setBusy={setBusy}
			route={route}
			projectId={id}
			stepId={Id}
			handleStepChange={handleStepChange}
			lang={lang}
			History={History}
			modelSendToServer={modelSendToServer}
			pageTitle={localSteps?.[step - 1]?.name[lang]}
			step={step}
			UpdateStep={UpdateStep}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			steps={steps}
			setStep={setStep}
			localSteps={localSteps}
			startDate={startDate}
			endDate={endDate}
			selectedTimeInterval={selectedTimeInterval}
			setSelectedTimeInterval={setSelectedTimeInterval}
			timeLineSelected={timeLineSelected}
			setTimeLineSelected={setTimeLineSelected}
			historySteps={historySteps}
			setComponentBusy={setComponentBusy}
			permissions={permissions}
			openedInner={openedInner}
			responsible={responsible}
			isComponentBusy={isComponentBusy}
			otherFields={otherFields}
			inputs={[
				historySteps[0]?.startDate && {
					value: moment(historySteps[0]?.startDate).format("DD-MM-YYYY, HH:mm:ss"),
					type: "text",
					usageType: "textfield",
					label: T("startDate", lang),
					disabled: true,
					permissionModel: "step10-startDate",
					rightIcon: <IconTimeline />
				},
				historySteps[0]?.startDate && historySteps[historySteps.length - 1]?.endDate
					? {
							value:
								"It lasts " +
								moment(historySteps[historySteps.length - 1]?.endDate).diff(
									historySteps[historySteps.length - 1]?.startDate,
									"minutes"
								) +
								" working minutes",
							type: "text",
							usageType: "textfield",
							label: T("processTime", lang),
							permissionModel: "step10-processTime",
							disabled: true,
							rightIcon: <IconCalendarClock />
						}
					: {
							value:
								"Still going on " +
								moment(new Date()).diff(
									historySteps[historySteps.length - 1]?.startDate,
									"minutes"
								) +
								" working minutes",
							type: "text",
							usageType: "textfield",
							label: T("processTime", lang),
							permissionModel: "step10-processTime",
							disabled: true,
							rightIcon: <IconCalendarClock />
						},
				historySteps[historySteps.length - 1]?.endDate && {
					value: moment(historySteps[historySteps.length - 1]?.endDate).format(
						"DD-MM-YYYY, HH:mm:ss"
					),
					type: "text",
					usageType: "textfield",
					label: T("endDate", lang),
					disabled: true,
					permissionModel: "step10-endDate",
					rightIcon: <IconCalendarClock />
				},
				deadline && {
					value: moment(historySteps[0]?.startDate).add(deadline, "days").format("DD-MM-YYYY"),
					type: "text",
					usageType: "textfield",
					label: T("deadline", lang),
					permissionModel: "step10-deadline",
					disabled: true,
					rightIcon: <IconCalendarWeek />
				},
				{
					value: descEn,
					setter: setDescEn,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInLatin", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					lang: "en",
					permissionModel: "step10-desc",
					width: 12
				},
				{
					value: descRu,
					setter: setDescRu,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInRussian", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					lang: "ru",
					permissionModel: "step10-desc",
					width: 12
				},
				{
					value: descHy,
					setter: setDescHy,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInArmenian", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					permissionModel: "step10-desc",
					lang: "hy",
					width: 12
				}
			]}
			cartComponent={{
				title: T("responsible", lang),
				data: [
					{
						placeholder: `${T("responsible", lang)} ${T("step", lang)} 13, 14`,
						value: responsibleStep13_14,
						setter: setResponsibleStep13_14,
						data: allUsers,
						columns: columns,
						width: "220px",
						permissionModel: "step10-users"
					}
				]
			}}
		/>
	);
}
