import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as API from "../helpers/api";
import { useLang } from "../helpers/language";
import config from "../config";
import { UPDATE, DELETE } from "../helpers/CRUD";
import classes from "./Tabs.module.css";
//! import modules
import Input from "./modules/input";
import { FilesComponent } from "./modules/filesComponent";
import { TableComponent } from "./modules/tableComponent";
import { ToDoComponent } from "./modules/toDoComponent";
import { CartComponent } from "./modules/cartComponent";
import { PermissionsTableComponent } from "./modules/permissionsTableComponent";
import "date-fns";
import { useUser } from "../helpers/userContext";
import { T, Translations } from "../helpers/translator";
import {
	IconArrowBack,
	IconDashboard,
	IconDeviceFloppy,
	IconList,
	IconMessage,
	IconPhoto,
	IconPhotoCode,
	IconPlus,
	IconRotateDot,
	IconTable,
	IconTableColumn,
	IconUser
} from "@tabler/icons-react";
import {
	Group,
	Button,
	CloseIcon,
	Container,
	Title,
	Card,
	Tabs,
	Grid,
	CardSection,
	useMantineColorScheme,
	useMantineTheme,
	rem
} from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { TimelineComponent } from "./modules/timelineComponent";
import { UserProfile } from "./hr/user/UserProfile";

export default function SinglePage({
	inputs,
	simplePage,
	defaultTab,
	filesComponent,
	tableComponent,
	timelineComponent,
	userProfile,
	permissionsTableComponent,
	permissionModel,
	permissions,
	editpathName,
	toDoComponent,
	hardReload,
	cartComponent,
	route,
	id,
	modelSendToServer,
	setBusy,
	connectedRoute,
	connectedData,
	connectedID,
	commentTicket = () => {},
	closeTicket,
	firstStatus,
	lastStatus,
	Status
}) {
	const { lang, setLang } = useLang();
	const { user, setUser } = useUser();
	const history = useHistory();
	const location = useLocation();
	let formatedLocation = location.search
		.replace("?", "")
		.split("&")
		.reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();
	const [isDesktop, setDesktop] = useState(false);

	function setIsDesktop() {
		if (window.innerWidth > 1000) {
			setDesktop(true);
		} else if (window.innerWidth <= 1000) {
			setDesktop(false);
		}
	}

	// get windows size
	useEffect(() => {
		setIsDesktop();
		window.addEventListener("resize", setIsDesktop);
		return () => window.removeEventListener("resize", setIsDesktop);
	}, []);

	const HandlePermissionCheck = (input) => {
		if (input) {
			if (user?.ananunaki) {
				input.crud = {
					create: true,
					read: true,
					update: true,
					delete: true
				};
				return input;
			}
			let foundedPermission = user.role?.permissions?.find(
				(permission) => permission.name === permissionModel
			);
			if (foundedPermission) {
				let foundedInput = foundedPermission.inputs?.find(
					(inp) => inp.name === input?.permissionModel
				);
				if (foundedInput && foundedInput.read) {
					input.crud = {
						create: foundedInput.create !== undefined ? foundedInput.create : false,
						read: foundedInput.read !== undefined ? foundedInput.read : false,
						update: foundedInput.update !== undefined ? foundedInput.update : false,
						delete: foundedInput.delete !== undefined ? foundedInput.delete : false
					};
					return input;
				}
			} else {
				console.error("Permission not found 2");
			}
		}
	};

	return (
		<Tabs
			defaultValue={formatedLocation.tab ? formatedLocation.tab : defaultTab ? defaultTab : "first"}
			value={formatedLocation.tab}
			variant="unstyled"
			classNames={classes}
			onChange={(value) =>
				history.push(`/${route}/${editpathName ? "edit/" : ""}${id}?tab=${value}`)
			}
		>
			<Tabs.List>
				<Tabs.Tab value="first" color="blue" leftSection={<IconList size={14} />}>
					{T("mainInformation", lang)}
				</Tabs.Tab>
				{filesComponent ? (
					<Tabs.Tab value="second" color="orange" leftSection={<IconPhoto size={14} />}>
						{filesComponent.length === 1 && filesComponent[0].placeholder
							? filesComponent[0].placeholder
							: T("files", lang)}
					</Tabs.Tab>
				) : null}
				{cartComponent ? (
					<Tabs.Tab value="third" color="cyan" leftSection={<IconTableColumn size={14} />}>
						{cartComponent.title
							? cartComponent.title
							: cartComponent.data?.length === 1 && cartComponent.data?.[0].placeholder
								? cartComponent.data?.[0].placeholder
								: T("cart", lang)}
					</Tabs.Tab>
				) : null}
				{toDoComponent ? (
					<Tabs.Tab value="forth" color="violet" leftSection={<IconRotateDot size={14} />}>
						{toDoComponent.length === 1 && toDoComponent[0].placeholder
							? toDoComponent[0].placeholder
							: T("toDo", lang)}
					</Tabs.Tab>
				) : null}
				{tableComponent ? (
					<Tabs.Tab value="sixth" color="green" leftSection={<IconTable size={14} />}>
						{T("table", lang)}
					</Tabs.Tab>
				) : null}
				{timelineComponent ? (
					<Tabs.Tab value="timeline" color="green" leftSection={<IconTable size={14} />}>
						{timelineComponent.length === 1 && timelineComponent[0].placeholder
							? timelineComponent[0].placeholder
							: T("timeline", lang)}
					</Tabs.Tab>
				) : null}
				{permissionsTableComponent ? (
					<Tabs.Tab value="seventh" color="green" leftSection={<IconTable size={14} />}>
						{T("table", lang)}
					</Tabs.Tab>
				) : null}
				{userProfile ? (
					<Tabs.Tab value="eight" color="green" leftSection={<IconUser size={14} />}>
						{T("userProfile", lang)}
					</Tabs.Tab>
				) : null}
			</Tabs.List>
			{inputs && (
				<Tabs.Panel value="first">
					<Card
						shadow="sm"
						p="md"
						style={{
							borderTopLeftRadius: 0
						}}
					>
						<Grid
							grow
							p={20}
							style={{
								background: colorScheme === "dark" ? Theme.colors.dark[6] : ""
							}}
						>
							{inputs.map((input, index) => {
								let checkedInput = HandlePermissionCheck(input);
								if (checkedInput) {
									if (checkedInput?.lang) {
										if (checkedInput.lang === lang) {
											return (
												<Grid.Col
													key={index}
													span={{
														base: 12,
														md: checkedInput.width ? checkedInput.width : 6,
														lg: checkedInput.width ? checkedInput.width : 3
													}}
												>
													<Input
														key={index}
														id={index}
														label={checkedInput.label}
														field={checkedInput.field}
														placeholder={checkedInput.placeholder}
														price={checkedInput.price}
														priceSetter={checkedInput.priceSetter}
														timeInterval={checkedInput.timeInterval}
														settimeInterval={checkedInput.settimeInterval}
														value={checkedInput.value}
														width={checkedInput.width}
														setter={checkedInput.setter}
														type={checkedInput.type}
														lang={checkedInput.lang}
														user={user}
														usageType={checkedInput.usageType}
														optionListValue={checkedInput.optionListValue}
														radioValue={checkedInput.radioValue}
														disabled={checkedInput.disabled}
														crud={checkedInput.crud}
														selectSomething={checkedInput.selectSomething}
													/>
												</Grid.Col>
											);
										}
									} else {
										return (
											<Grid.Col
												span={{
													base: 12,
													md: checkedInput.width ? checkedInput.width : 6,
													lg: checkedInput.width ? checkedInput.width : 3
												}}
											>
												<Input
													key={index}
													id={index}
													label={checkedInput.label}
													field={checkedInput.field}
													placeholder={checkedInput.placeholder}
													price={checkedInput.price}
													priceSetter={checkedInput.priceSetter}
													timeInterval={checkedInput.timeInterval}
													settimeInterval={checkedInput.settimeInterval}
													value={checkedInput.value}
													setter={checkedInput.setter}
													user={user}
													type={checkedInput.type}
													radioValue={checkedInput.radioValue}
													width={checkedInput.width}
													usageType={checkedInput.usageType}
													optionListValue={checkedInput.optionListValue}
													disabled={checkedInput.disabled}
													crud={checkedInput.crud}
												/>
											</Grid.Col>
										);
									}
								}
								return null;
							})}
						</Grid>
						<CardSection p={20} mt={20}>
							<Group justify="center" grow>
								{
									<>
										<Button
											leftSection={
												<IconDeviceFloppy
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											variant="light"
											onClick={async () => {
												try {
													let result = {};
													let updatedUser = { ...user };
													let callSuccess = false;
													if (location.pathname.includes(user._id)) {
														Object.keys(updatedUser).forEach((key) => {
															if (modelSendToServer[key] === undefined) return;
															updatedUser[key] = modelSendToServer[key];
														});
														updatedUser.temporary = false;
														updatedUser._id = user._id;
														callSuccess = await UPDATE({
															modelSendToServer: updatedUser,
															route,
															setBusy,
															id,
															connectedRoute,
															connectedData,
															connectedID,
															lang,
															user,
															filesComponent,
															hardReload,
															Status
														});
														if (callSuccess) {
															result = await API.update(
																config.api.user,
																updatedUser._id,
																{
																	...updatedUser,
																	history: [
																		{
																			date: new Date(),
																			action: {
																				method: "updated",
																				name: Translations.userUpdated,
																				route: {
																					pathname: location.pathname
																						.replace(/\/$/, "")
																						.replace(/^\//, ""),
																					search: location.search.replace(/^\?/, "")
																				}
																			}
																		},
																		...user?.history?.slice(0, 20)
																	]
																},
																["role", "position", "department"]
															);
															Object.keys(user).forEach((key) => {
																if (result[key] === undefined) return;
																if (key === "role") {
																	updatedUser[key] = result[key]?.[0];
																} else {
																	updatedUser[key] = result[key];
																}
															});
															setUser({
																...updatedUser,
																history: user.history ? user.history : updatedUser.history
															});
														}
													} else {
														callSuccess = await UPDATE({
															modelSendToServer,
															route,
															history,
															setBusy,
															id,
															connectedRoute,
															connectedData,
															connectedID,
															lang,
															user,
															filesComponent,
															hardReload,
															Status
														});
														if (callSuccess) {
															result = await API.update(config.api.user, updatedUser._id, {
																...updatedUser,
																history: [
																	{
																		date: new Date(),
																		action: {
																			method: "updated",
																			name: Translations.userUpdated,
																			route: {
																				pathname: location.pathname
																					.replace(/\/$/, "")
																					.replace(/^\//, ""),
																				search: location.search.replace(/^\?/, "")
																			}
																		}
																	},
																	...user?.history?.slice(0, 20)
																]
															});
															setUser({
																...updatedUser,
																history: result.history ? result.history : updatedUser.history
															});
														}
													}
												} catch (error) {
													console.error(error);
												}
											}}
										>
											{T("save", lang)}
										</Button>

										<Button
											leftSection={
												<IconArrowBack
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											color="orange"
											variant="light"
											onClick={() => history.goBack()}
										>
											{T("cancel", lang)}
										</Button>
									</>
								}
								{route !== "ticket" && user?.role !== undefined ? (
									<Button
										color="red"
										leftSection={
											<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										variant="light"
										onClick={async () => {
											try {
												let deletedObject = await DELETE({ route, id, history, lang, setBusy });
												if (deletedObject) {
													let result = {};
													result = await API.update(config.api.user, user._id, {
														...user,
														history: [
															{
																date: new Date(),
																action: {
																	method: "deleted",
																	name: Translations.userDeleted,
																	route: {
																		pathname: location.pathname
																			.replace(/\/$/, "")
																			.replace(/^\//, ""),
																		search: location.search.replace(/^\?/, "")
																	}
																}
															},
															...user.history.slice(0, 20)
														]
													});
													setUser({
														...user,
														history: result.history ? result.history : user.history
													});
												}
											} catch (error) {
												console.error(error);
											}
										}}
									>
										{T("remove", lang)}
									</Button>
								) : null}
								{route === "ticket" &&
								lastStatus !== modelSendToServer.status &&
								firstStatus !== modelSendToServer.status &&
								!modelSendToServer.closedDate ? (
									<Button
										leftSection={
											<IconMessage size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										color="orange"
										variant="light"
										onClick={commentTicket}
									>
										{T("commentTicket", lang)}
									</Button>
								) : null}
								{route === "ticket" && lastStatus === modelSendToServer.status && (
									<Button
										leftSection={
											<IconDashboard size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										disabled={
											lastStatus === modelSendToServer.status && modelSendToServer.closedDate
										}
										color="green"
										variant="light"
										onClick={closeTicket}
									>
										{T("closeTicket", lang)}
									</Button>
								)}
							</Group>
						</CardSection>
					</Card>
				</Tabs.Panel>
			)}
			{filesComponent && (
				<Tabs.Panel value="second">
					<Card shadow="sm" p="md">
						<Grid
							p={20}
							m={0}
							style={{
								backgroundColor: colorScheme === "dark" ? Theme.colors.dark[6] : ""
							}}
						>
							{filesComponent.map((component, index) => {
								let checkedComponent = HandlePermissionCheck(component);
								if (checkedComponent) {
									return (
										<Grid.Col span={{ base: 12, md: 6 }} key={index}>
											<FilesComponent
												key={index}
												id={id}
												destiny={component.destiny}
												file={component.file}
												files={component.files}
												filesUploadLimit={component.filesUploadLimit}
												acceptedFiles={component.acceptedFiles}
												lang={component.lang}
												alt={component.alt}
												objectToAttachAnUpload={component.objectToAttachAnUpload}
												setAlt={component.setAlt}
												setBusy={setBusy}
												setFile={component.setFile}
												setFiles={component.setFiles}
												route={route}
												thumbSizeWidth={component.thumbSizeWidth}
												thumbSizeHeight={component.thumbSizeHeight}
												responsiveImages={component.responsiveImages}
												width={component.width}
												height={component.height}
												filesMaxSize={component.filesMaxSize}
												disabled={component.disabled}
												user={user}
												crud={component.crud}
												permissionModel={checkedComponent.permissionModel}
												permissions={permissions}
												adminMode={component.adminMode}
												date={component.date}
												placeholder={component.placeholder}
											/>
										</Grid.Col>
									);
								}
								return null;
							})}
						</Grid>
					</Card>
				</Tabs.Panel>
			)}
			{cartComponent?.data && (
				<Tabs.Panel value="third">
					{cartComponent?.data?.map((component, index) => {
						let checkedComponent = HandlePermissionCheck(component);
						if (checkedComponent) {
							return (
								<CartComponent
									key={index}
									title={component.title}
									data={component.data}
									columns={component.columns}
									setter={component.setter}
									value={component.value}
									pagination={component.pagination}
									width={component.width}
									customStyles={component.customStyles}
									placeholder={component.placeholder}
									disabled={component.disabled}
									user={user}
									crud={component.crud}
									permissionModel={checkedComponent.permissionModel}
									permissions={permissions}
								/>
							);
						}
						return null;
					})}
				</Tabs.Panel>
			)}
			{toDoComponent && (
				<Tabs.Panel value="forth">
					{toDoComponent.map((component, index) => {
						let checkedComponent = HandlePermissionCheck(component);
						if (checkedComponent) {
							return (
								<ToDoComponent
									key={index}
									title={component.title}
									data={component.data}
									columns={component.columns}
									setter={component.setter}
									value={component.value}
									pagination={component.pagination}
									width={component.width}
									customStyles={component.customStyles}
									limit={component.limit}
									user={user}
									crud={component.crud}
									permissionModel={checkedComponent.permissionModel}
									permissions={permissions}
								/>
							);
						}
						return null;
					})}
				</Tabs.Panel>
			)}
			{tableComponent && (
				<Tabs.Panel value="sixth">
					{tableComponent.map((component, index) => {
						let checkedComponent = HandlePermissionCheck(component);
						if (checkedComponent) {
							return (
								<TableComponent
									key={index}
									title={component.title}
									data={component.data}
									columns={component.columns}
									setter={component.setter}
									conditionalRowStyles={component.conditionalRowStyles}
									user={user}
									crud={component.crud}
									permissionModel={checkedComponent.permissionModel}
									permissions={permissions}
								/>
							);
						}
						return null;
					})}
				</Tabs.Panel>
			)}
			{timelineComponent && (
				<Tabs.Panel
					value="timeline"
					style={{
						display: "flex",
						flexDirection: isDesktop ? "row" : "column"
					}}
				>
					{timelineComponent.map((component, index) => {
						let checkedComponent = HandlePermissionCheck(component);
						if (checkedComponent) {
							return (
								<TimelineComponent
									key={index}
									placeholder={component.placeholder}
									data={component.data}
									crud={component.crud}
									columns={component.columns}
									permissionModel={checkedComponent.permissionModel}
									permissions={permissions}
									user={user}
								/>
							);
						}
						return null;
					})}
				</Tabs.Panel>
			)}
			{permissionsTableComponent && (
				<Tabs.Panel value="seventh">
					<PermissionsTableComponent
						title={permissionsTableComponent.title}
						data={permissionsTableComponent.data}
						columns={permissionsTableComponent.columns}
						setter={permissionsTableComponent.setter}
						permissionParentModel={permissionModel}
						conditionalRowStyles={permissionsTableComponent.conditionalRowStyles}
					/>
				</Tabs.Panel>
			)}
			{userProfile && (
				<Tabs.Panel value="eight">
					<UserProfile user={userProfile} />
				</Tabs.Panel>
			)}
		</Tabs>
	);
}
