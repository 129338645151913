import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import { useUser } from "../../../helpers/userContext";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { Image, useMantineColorScheme } from "@mantine/core";
import { parsePhoneNumber } from "../../../helpers/parsePhoneNumber";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Voltage() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const { user } = useUser();
	const route = config.api.voltage;
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();

	const [index, setIndex] = useState("");
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");
	const [deadline, setDeadline] = useState({
		project: 0,
		step1: 0,
		step2: 0,
		step3: 0,
		step4: 0,
		step5: 0,
		step6: 0,
		step7: 0,
		step8: 0,
		step9: 0,
		step10: 0,
		step11: 0,
		step12: 0,
		step13: 0,
		step14: 0,
		step15: 0,
		step16: 0,
		step17: 0,
		step18: 0,
		step19: 0,
		step20: 0,
		step21: 0,
		step22: 0,
		step23: 0,
		step24: 0,
		step25: 0,
		step26: 0,
		step27: 0,
		step28: 0,
		step29: 0,
		step30: 0,
		step31: 0,
		step32: 0,
		step33: 0,
		step34: 0,
		step35: 0,
		step36: 0,
		step37: 0,
		step38: 0,
		step39: 0,
		step40: 0,
		step41: 0,
		step42: 0,
		step43: 0,
		step44: 0,
		step45: 0
	});

	const [responsibleStep2, setResponsibleStep2] = useState([]);
	const [responsibleStep5, setResponsibleStep5] = useState([]);
	const [responsibleStep7, setResponsibleStep7] = useState([]);
	const [responsibleStep8, setResponsibleStep8] = useState([]);
	const [responsibleStep9, setResponsibleStep9] = useState([]);
	const [responsibleStep16, setResponsibleStep16] = useState([]);
	const [responsibleStep23, setResponsibleStep23] = useState([]);
	const [responsibleStep24, setResponsibleStep24] = useState([]);
	const [responsibleStep26, setResponsibleStep26] = useState([]);
	const [responsibleStep28_30_32, setResponsibleStep28_30_32] = useState([]);
	const [usersStep28_30_32, setUsersStep28_30_32] = useState([]);
	const [responsibleStep35, setResponsibleStep35] = useState([]);
	const [responsibleStep37, setResponsibleStep37] = useState([]);
	const [responsibleStep38, setResponsibleStep38] = useState([]);
	const [responsibleStep44, setResponsibleStep44] = useState([]);
	const [responsibleStep45, setResponsibleStep45] = useState([]);

	const [allUsers, setAllUsers] = useState([]);

	const [permissions, setPermissions] = useState([]);

	const columns = [
		{
			accessorFn: (row) => `${row.firstName?.en} ${row.firstName?.ru} ${row.firstName?.hy}`,
			header: T("firstName", lang),
			filterVariant: "multi-select",
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.firstName?.[lang] ? row.firstName?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row.lastName?.en} ${row.lastName?.ru} ${row.lastName?.hy}`,
			header: T("lastName", lang),
			filterVariant: "multi-select",
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.lastName?.[lang] ? row.lastName?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorKey: "phone",
			enableColumnActions: false,
			enableColumnDragging: false,
			header: T("more", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = "";
				if (row.phone && row.phone.length > 0) {
					result = (
						<div
							className="icon-comment-wrapper"
							style={{ display: "flex", justifyContent: "space-between" }}
						>
							<a href={`tel:${parsePhoneNumber(row.phone)}`}>
								<Image
									w="20"
									h="20"
									className="icon-comment"
									src="/assets/phone-call-svgrepo-com.svg"
									alt="phone"
									style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
								/>
							</a>
							<a href={`mailto:${row.email}`}>
								<Image
									w="20"
									h="20"
									className="icon-comment"
									src="/assets/email-svgrepo-com.svg"
									alt="email"
									style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
								/>
							</a>
							<a
								href={`https://chat.apit.am/direct/${row.chatID}`}
								target="_blank"
								rel="noreferrer"
							>
								<Image
									w="20"
									h="20"
									className="icon-comment"
									src="/assets/message-circle-chat-svgrepo-com.svg"
									alt="chat"
									style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
								/>
							</a>
						</div>
					);
				}
				return result;
			}
		}
	];

	const modelSendToServer = {
		index,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		},
		projectDeadline: deadline.project,
		steps: [
			{ index: 1, deadline: deadline.step1 },
			{
				index: 2,
				deadline: deadline.step2,
				responsible: responsibleStep2?.length ? responsibleStep2 : undefined
			},
			{ index: 3, deadline: deadline.step3 },
			{ index: 4, deadline: deadline.step4 },
			{
				index: 5,
				deadline: deadline.step5,
				responsible: responsibleStep5?.length ? responsibleStep5 : undefined
			},
			{ index: 6, deadline: deadline.step6 },
			{
				index: 7,
				deadline: deadline.step7,
				responsible: responsibleStep7?.length ? responsibleStep7 : undefined
			},
			{
				index: 8,
				deadline: deadline.step8,
				responsible: responsibleStep8?.length ? responsibleStep8 : undefined
			},
			{
				index: 9,
				deadline: deadline.step9,
				responsible: responsibleStep9?.length ? responsibleStep9 : undefined
			},
			{
				index: 10,
				deadline: deadline.step10
			},
			{ index: 11, deadline: deadline.step11 },
			{ index: 12, deadline: deadline.step12 },
			{ index: 13, deadline: deadline.step13 },
			{ index: 14, deadline: deadline.step14 },
			{ index: 15, deadline: deadline.step15 },
			{
				index: 16,
				deadline: deadline.step16,
				responsible: responsibleStep16?.length ? responsibleStep16 : undefined
			},
			{ index: 17, deadline: deadline.step17 },
			{ index: 18, deadline: deadline.step18 },
			{ index: 19, deadline: deadline.step19 },
			{ index: 20, deadline: deadline.step20 },
			{ index: 21, deadline: deadline.step21 },
			{ index: 22, deadline: deadline.step22 },
			{
				index: 23,
				deadline: deadline.step23,
				responsible: responsibleStep23?.length ? responsibleStep23 : undefined
			},
			{
				index: 24,
				deadline: deadline.step24,
				responsible: responsibleStep24?.length ? responsibleStep24 : undefined
			},
			{ index: 25, deadline: deadline.step25 },
			{
				index: 26,
				deadline: deadline.step26,
				responsible: responsibleStep26?.length ? responsibleStep26 : undefined
			},
			{ index: 27, deadline: deadline.step27 },
			{
				index: 28,
				deadline: deadline.step28,
				responsible: responsibleStep28_30_32?.length ? responsibleStep28_30_32 : undefined,
				users: usersStep28_30_32?.length ? usersStep28_30_32 : undefined
			},
			{ index: 29, deadline: deadline.step29 },
			{
				index: 30,
				deadline: deadline.step30,
				responsible: responsibleStep28_30_32?.length ? responsibleStep28_30_32 : undefined,
				users: usersStep28_30_32?.length ? usersStep28_30_32 : undefined
			},
			{ index: 31, deadline: deadline.step31 },
			{
				index: 32,
				deadline: deadline.step32,
				responsible: responsibleStep28_30_32?.length ? responsibleStep28_30_32 : undefined,
				users: usersStep28_30_32?.length ? usersStep28_30_32 : undefined
			},
			{ index: 33, deadline: deadline.step33 },
			{ index: 34, deadline: deadline.step34 },
			{
				index: 35,
				deadline: deadline.step35,
				responsible: responsibleStep35?.length ? responsibleStep35 : undefined
			},
			{ index: 36, deadline: deadline.step36 },
			{
				index: 37,
				deadline: deadline.step37,
				responsible: responsibleStep37?.length ? responsibleStep37 : undefined
			},
			{
				index: 38,
				deadline: deadline.step38,
				responsible: responsibleStep38?.length ? responsibleStep38 : undefined
			},
			{ index: 39, deadline: deadline.step39 },
			{ index: 40, deadline: deadline.step40 },
			{ index: 41, deadline: deadline.step41 },
			{ index: 42, deadline: deadline.step42 },
			{ index: 43, deadline: deadline.step43 },
			{
				index: 44,
				deadline: deadline.step44,
				responsible: responsibleStep44?.length ? responsibleStep44 : undefined
			},
			{
				index: 45,
				deadline: deadline.step45,
				responsible: responsibleStep45?.length ? responsibleStep45 : undefined
			}
		].filter(
			(step) =>
				step.deadline !== undefined || step.responsible !== undefined || step.users !== undefined
		)
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawUsers = await API.get({
				route: config.api.user,
				query: {
					temporary: {
						$ne: true
					},
					workingStatus: "working"
				}
			});
			setAllUsers(rawUsers);

			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					populate: ["steps.responsible", "steps.users"]
				});
				setIndex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);

				setDeadline({
					project: currentItem[0]?.deadline?.project,
					step1: currentItem[0]?.steps[0]?.deadline,
					step2: currentItem[0]?.steps[1]?.deadline,
					step3: currentItem[0]?.steps[2]?.deadline,
					step4: currentItem[0]?.steps[3]?.deadline,
					step5: currentItem[0]?.steps[4]?.deadline,
					step6: currentItem[0]?.steps[5]?.deadline,
					step7: currentItem[0]?.steps[6]?.deadline,
					step8: currentItem[0]?.steps[7]?.deadline,
					step9: currentItem[0]?.steps[8]?.deadline,
					step10: currentItem[0]?.steps[9]?.deadline,
					step11: currentItem[0]?.steps[10]?.deadline,
					step12: currentItem[0]?.steps[11]?.deadline,
					step13: currentItem[0]?.steps[12]?.deadline,
					step14: currentItem[0]?.steps[13]?.deadline,
					step15: currentItem[0]?.steps[14]?.deadline,
					step16: currentItem[0]?.steps[15]?.deadline,
					step17: currentItem[0]?.steps[16]?.deadline,
					step18: currentItem[0]?.steps[17]?.deadline,
					step19: currentItem[0]?.steps[18]?.deadline,
					step20: currentItem[0]?.steps[19]?.deadline,
					step21: currentItem[0]?.steps[20]?.deadline,
					step22: currentItem[0]?.steps[21]?.deadline,
					step23: currentItem[0]?.steps[22]?.deadline,
					step24: currentItem[0]?.steps[23]?.deadline,
					step25: currentItem[0]?.steps[24]?.deadline,
					step26: currentItem[0]?.steps[25]?.deadline,
					step27: currentItem[0]?.steps[26]?.deadline,
					step28: currentItem[0]?.steps[27]?.deadline,
					step29: currentItem[0]?.steps[28]?.deadline,
					step30: currentItem[0]?.steps[29]?.deadline,
					step31: currentItem[0]?.steps[30]?.deadline,
					step32: currentItem[0]?.steps[31]?.deadline,
					step33: currentItem[0]?.steps[32]?.deadline,
					step34: currentItem[0]?.steps[33]?.deadline,
					step35: currentItem[0]?.steps[34]?.deadline,
					step36: currentItem[0]?.steps[35]?.deadline,
					step37: currentItem[0]?.steps[36]?.deadline,
					step38: currentItem[0]?.steps[37]?.deadline,
					step39: currentItem[0]?.steps[38]?.deadline,
					step40: currentItem[0]?.steps[39]?.deadline,
					step41: currentItem[0]?.steps[40]?.deadline,
					step42: currentItem[0]?.steps[41]?.deadline,
					step43: currentItem[0]?.steps[42]?.deadline,
					step44: currentItem[0]?.steps[43]?.deadline,
					step45: currentItem[0]?.steps[44]?.deadline
				});
				currentItem[0]?.steps.forEach((item) => {
					switch (item.index) {
						case 2:
							setResponsibleStep2(item.responsible);
							break;
						case 5:
							setResponsibleStep5(item.responsible);
							break;
						case 7:
							setResponsibleStep7(item.responsible);
							break;
						case 8:
							setResponsibleStep8(item.responsible);
							break;
						case 9:
							setResponsibleStep9(item.responsible);
							break;
						case 16:
							setResponsibleStep16(item.responsible);
							break;
						case 23:
							setResponsibleStep23(item.responsible);
							break;
						case 24:
							setResponsibleStep24(item.responsible);
							break;
						case 26:
							setResponsibleStep26(item.responsible);
							break;
						case 28:
							setResponsibleStep28_30_32(item.responsible);
							setUsersStep28_30_32(item.users);
							break;
						case 35:
							setResponsibleStep35(item.responsible);
							break;
						case 37:
							setResponsibleStep37(item.responsible);
							break;
						case 38:
							setResponsibleStep38(item.responsible);
							break;
						case 44:
							setResponsibleStep44(item.responsible);
							break;
						case 45:
							setResponsibleStep45(item.responsible);
							break;
						default:
							break;
					}
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissionModel="voltage"
				modelSendToServer={modelSendToServer}
				permissions={permissions}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						lang: "en",
						permissionModel: "name"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInRussian", lang),
						lang: "ru",
						permissionModel: "name"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInArmenian", lang),
						lang: "hy",
						permissionModel: "name"
					},
					{
						value: deadline?.project,
						setter: (e) => setDeadline({ ...deadline, project: e }),
						type: "number",
						usageType: "number",
						label: T("projectDeadline", lang),
						placeholder: T("projectDeadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step1,
						setter: (e) => setDeadline({ ...deadline, step1: e }),
						type: "number",
						usageType: "number",
						label: config.steps[0].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step2,
						setter: (e) => setDeadline({ ...deadline, step2: e }),
						type: "number",
						usageType: "number",
						label: config.steps[1].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step3,
						setter: (e) => setDeadline({ ...deadline, step3: e }),
						type: "number",
						usageType: "number",
						label: config.steps[2].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step4,
						setter: (e) => setDeadline({ ...deadline, step4: e }),
						type: "number",
						usageType: "number",
						label: config.steps[3].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step5,
						setter: (e) => setDeadline({ ...deadline, step5: e }),
						type: "number",
						usageType: "number",
						label: config.steps[4].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step6,
						setter: (e) => setDeadline({ ...deadline, step6: e }),
						type: "number",
						usageType: "number",
						label: config.steps[5].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step7,
						setter: (e) => setDeadline({ ...deadline, step7: e }),
						type: "number",
						usageType: "number",
						label: config.steps[6].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step8,
						setter: (e) => setDeadline({ ...deadline, step8: e }),
						type: "number",
						usageType: "number",
						label: config.steps[7].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step9,
						setter: (e) => setDeadline({ ...deadline, step9: e }),
						type: "number",
						usageType: "number",
						label: config.steps[8].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step10,
						setter: (e) => setDeadline({ ...deadline, step10: e }),
						type: "number",
						usageType: "number",
						label: config.steps[9].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step11,
						setter: (e) => setDeadline({ ...deadline, step11: e }),
						type: "number",
						usageType: "number",
						label: config.steps[10].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step12,
						setter: (e) => setDeadline({ ...deadline, step12: e }),
						type: "number",
						usageType: "number",
						label: config.steps[11].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step13,
						setter: (e) => setDeadline({ ...deadline, step13: e }),
						type: "number",
						usageType: "number",
						label: config.steps[12].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step14,
						setter: (e) => setDeadline({ ...deadline, step14: e }),
						type: "number",
						usageType: "number",
						label: config.steps[13].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step15,
						setter: (e) => setDeadline({ ...deadline, step15: e }),
						type: "number",
						usageType: "number",
						label: config.steps[14].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step16,
						setter: (e) => setDeadline({ ...deadline, step16: e }),
						type: "number",
						usageType: "number",
						label: config.steps[15].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step17,
						setter: (e) => setDeadline({ ...deadline, step17: e }),
						type: "number",
						usageType: "number",
						label: config.steps[16].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step18,
						setter: (e) => setDeadline({ ...deadline, step18: e }),
						type: "number",
						usageType: "number",
						label: config.steps[17].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step19,
						setter: (e) => setDeadline({ ...deadline, step19: e }),
						type: "number",
						usageType: "number",
						label: config.steps[18].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step20,
						setter: (e) => setDeadline({ ...deadline, step20: e }),
						type: "number",
						usageType: "number",
						label: config.steps[19].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step21,
						setter: (e) => setDeadline({ ...deadline, step21: e }),
						type: "number",
						usageType: "number",
						label: config.steps[20].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step22,
						setter: (e) => setDeadline({ ...deadline, step22: e }),
						type: "number",
						usageType: "number",
						label: config.steps[21].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step23,
						setter: (e) => setDeadline({ ...deadline, step23: e }),
						type: "number",
						usageType: "number",
						label: config.steps[22].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step24,
						setter: (e) => setDeadline({ ...deadline, step24: e }),
						type: "number",
						usageType: "number",
						label: config.steps[23].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step25,
						setter: (e) => setDeadline({ ...deadline, step25: e }),
						type: "number",
						usageType: "number",
						label: config.steps[24].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step26,
						setter: (e) => setDeadline({ ...deadline, step26: e }),
						type: "number",
						usageType: "number",
						label: config.steps[25].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step27,
						setter: (e) => setDeadline({ ...deadline, step27: e }),
						type: "number",
						usageType: "number",
						label: config.steps[26].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step28,
						setter: (e) => setDeadline({ ...deadline, step28: e }),
						type: "number",
						usageType: "number",
						label: config.steps[27].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step29,
						setter: (e) => setDeadline({ ...deadline, step29: e }),
						type: "number",
						usageType: "number",
						label: config.steps[28].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step30,
						setter: (e) => setDeadline({ ...deadline, step30: e }),
						type: "number",
						usageType: "number",
						label: config.steps[29].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step31,
						setter: (e) => setDeadline({ ...deadline, step31: e }),
						type: "number",
						usageType: "number",
						label: config.steps[30].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step32,
						setter: (e) => setDeadline({ ...deadline, step32: e }),
						type: "number",
						usageType: "number",
						label: config.steps[31].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step33,
						setter: (e) => setDeadline({ ...deadline, step33: e }),
						type: "number",
						usageType: "number",
						label: config.steps[32].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step34,
						setter: (e) => setDeadline({ ...deadline, step34: e }),
						type: "number",
						usageType: "number",
						label: config.steps[33].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step35,
						setter: (e) => setDeadline({ ...deadline, step35: e }),
						type: "number",
						usageType: "number",
						label: config.steps[34].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step36,
						setter: (e) => setDeadline({ ...deadline, step36: e }),
						type: "number",
						usageType: "number",
						label: config.steps[35].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step37,
						setter: (e) => setDeadline({ ...deadline, step37: e }),
						type: "number",
						usageType: "number",
						label: config.steps[36].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step38,
						setter: (e) => setDeadline({ ...deadline, step38: e }),
						type: "number",
						usageType: "number",
						label: config.steps[37].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step39,
						setter: (e) => setDeadline({ ...deadline, step39: e }),
						type: "number",
						usageType: "number",
						label: config.steps[38].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step40,
						setter: (e) => setDeadline({ ...deadline, step40: e }),
						type: "number",
						usageType: "number",
						label: config.steps[39].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step41,
						setter: (e) => setDeadline({ ...deadline, step41: e }),
						type: "number",
						usageType: "number",
						label: config.steps[40].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step42,
						setter: (e) => setDeadline({ ...deadline, step42: e }),
						type: "number",
						usageType: "number",
						label: config.steps[41].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step43,
						setter: (e) => setDeadline({ ...deadline, step43: e }),
						type: "number",
						usageType: "number",
						label: config.steps[42].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step44,
						setter: (e) => setDeadline({ ...deadline, step44: e }),
						type: "number",
						usageType: "number",
						label: config.steps[43].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					},
					{
						value: deadline?.step45,
						setter: (e) => setDeadline({ ...deadline, step45: e }),
						type: "number",
						usageType: "number",
						label: config.steps[44].name[lang] + " " + T("deadline", lang),
						permissionModel: "deadline"
					}
				]}
				cartComponent={{
					title: T("responsibleAndUsers", lang),
					data: [
						{
							placeholder: T("responsible", lang) + " - " + config.steps[1].name[lang] + " step2",
							value: responsibleStep2,
							setter: setResponsibleStep2,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[4].name[lang] + " step5",
							value: responsibleStep5,
							setter: setResponsibleStep5,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[6].name[lang] + " step7",
							value: responsibleStep7,
							setter: setResponsibleStep7,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[7].name[lang] + " step8",
							value: responsibleStep8,
							setter: setResponsibleStep8,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[8].name[lang] + " step9",
							value: responsibleStep9,
							setter: setResponsibleStep9,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[15].name[lang] + " step16",
							value: responsibleStep16,
							setter: setResponsibleStep16,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[22].name[lang] + " step23",
							value: responsibleStep23,
							setter: setResponsibleStep23,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[23].name[lang] + " step24",
							value: responsibleStep24,
							setter: setResponsibleStep24,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[25].name[lang] + " step26",
							value: responsibleStep26,
							setter: setResponsibleStep26,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder:
								T("responsible", lang) +
								" - " +
								config.steps[27].name[lang] +
								" step28" +
								" / " +
								config.steps[29].name[lang] +
								" step30" +
								" / " +
								config.steps[31].name[lang] +
								" step32",
							value: responsibleStep28_30_32,
							setter: setResponsibleStep28_30_32,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder:
								T("users", lang) +
								" - " +
								config.steps[27].name[lang] +
								" step28" +
								" / " +
								config.steps[29].name[lang] +
								" step30" +
								" / " +
								config.steps[31].name[lang] +
								" step32",
							value: usersStep28_30_32,
							setter: setUsersStep28_30_32,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[34].name[lang] + " step35",
							value: responsibleStep35,
							setter: setResponsibleStep35,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[36].name[lang] + " step37",
							value: responsibleStep37,
							setter: setResponsibleStep37,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[37].name[lang] + " step38",
							value: responsibleStep38,
							setter: setResponsibleStep38,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[43].name[lang] + " step44",
							value: responsibleStep44,
							setter: setResponsibleStep44,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("responsible", lang) + " - " + config.steps[44].name[lang] + " step45",
							value: responsibleStep45,
							setter: setResponsibleStep45,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						}
					]
				}}
			/>
		);
}
