import React, { useRef, useState, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import { Button, Card, Flex, Text } from "@mantine/core";
import {
	IconPlayerPlay,
	IconPlayerPlayFilled,
	IconSquare,
	IconSquareFilled
} from "@tabler/icons-react";
import { useMantineTheme } from "@mantine/core";
import moment from "moment";

const AudioPlayerWithWaveform = ({ audioUrl }) => {
	const waveformRef = useRef(null); // Ref for the waveform container
	const wavesurferRef = useRef(null); // Ref to hold Wavesurfer instance
	const theme = useMantineTheme();
	const [isPlaying, setIsPlaying] = useState(false); // State to track playback status
	const [currentTime, setCurrentTime] = useState(0);
	const [totalDuration, setTotalDuration] = useState(0);

	useEffect(() => {
		// Create Wavesurfer instance
		wavesurferRef.current = WaveSurfer.create({
			container: waveformRef.current,
			waveColor: theme.colors.gray[6], // Set the color of the waveform using Mantine theme
			progressColor: theme.colors.cyan[9], // Set the color of the progress bar using Mantine theme
			height: 46, // Height of the waveform
			barWidth: 3, // Width of the bars in the waveform
			responsive: true, // Make it responsive
			cursorWidth: 3, // Thicker cursor line (default is 1)
			cursorColor: theme.colors.cyan[9] // Set the color of the cursor using Mantine theme
		});

		// Load the audio file
		wavesurferRef.current.load(audioUrl);

		// Get total duration when the audio is ready
		wavesurferRef.current.on("ready", () => {
			const duration = wavesurferRef.current.getDuration(); // Total duration in seconds
			setTotalDuration(duration); // Save total duration to state
		});

		// Update recording time on playback progress
		wavesurferRef.current.on("audioprocess", () => {
			const currentTime = wavesurferRef.current.getCurrentTime();
			setCurrentTime(currentTime.toFixed(0)); // Update recording time in seconds
		});

		// Reset recording time when playback ends
		wavesurferRef.current.on("finish", () => {
			setIsPlaying(false);
			setCurrentTime(0);
		});

		return () => {
			// Cleanup Wavesurfer instance on component unmount
			wavesurferRef.current.destroy();
		};
		// eslint-disable-next-line
	}, [audioUrl]);

	// Handle play/pause button click
	const handlePlayPause = () => {
		if (isPlaying) {
			wavesurferRef.current.pause(); // Pause audio
		} else {
			wavesurferRef.current.play(); // Start playing audio
		}
		setIsPlaying(!isPlaying); // Toggle play/pause state
	};

	const formatTime = (timeInSeconds) => moment.utc(timeInSeconds * 1000).format("mm:ss");

	return (
		<Card shadow="xs" padding="xs" radius="md" h={80} style={{ width: "100%", maxWidth: "600px" }}>
			<Flex direction="row" align="center" h={"100%"} justify="center">
				<Button
					variant="light"
					onClick={handlePlayPause} // Use the handlePlayPause function here
					radius="md"
					m={0}
					mr={theme.spacing.sm}
					style={{ alignSelf: "start" }}
				>
					{isPlaying ? <IconSquareFilled size={24} /> : <IconPlayerPlayFilled size={24} />}
				</Button>

				<div
					id="waveform"
					ref={waveformRef}
					style={{ width: "100%", maxWidth: "600px", alignSelf: "start", zIndex: 1 }}
				></div>

				<Text
					style={{
						position: "absolute",
						bottom: 4,
						left: theme.spacing.sm,
						width: "100%",
						textAlign: "start"
					}}
					c={isPlaying ? theme.colors.gray[4] : theme.colors.gray[6]}
					align="center"
					size="sm"
					mt={8}
				>
					{formatTime(totalDuration)}
				</Text>

				<Text
					style={{
						position: "absolute",
						bottom: 4,
						right: theme.spacing.sm,
						width: "100%",
						textAlign: "end"
					}}
					c={isPlaying ? theme.colors.gray[4] : theme.colors.gray[6]}
					align="center"
					size="sm"
					mt={8}
				>
					{formatTime(currentTime)}
				</Text>
			</Flex>
		</Card>
	);
};

export default AudioPlayerWithWaveform;
