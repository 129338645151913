import React, { useState, useEffect } from "react";
import StepPage from "../StepPage";
import * as API from "../../../helpers/api";
import config from "../../../config";
// import getUploads from "../../../helpers/getUploads";
import { useUser } from "../../../helpers/userContext";
import moment from "moment";
import { IconCalendarClock, IconCalendarWeek } from "@tabler/icons-react";
import { IconTimeline } from "@tabler/icons-react";
import LoadingSkeleton from "./loadingSceleton";
import { T } from "../../../helpers/translator";
import parse from "html-react-parser";
import { Button, Card, Checkbox, Divider, Grid, Table, Title } from "@mantine/core";

export default function Step({
	route,
	id,
	ID,
	setID,
	modelSendToServer,
	destiny,
	lang,
	history,
	step,
	UpdateStep,
	steps,
	setStep,
	localSteps,
	activeStep,
	setActiveStep,
	isBusy,
	setBusy,
	priority,
	setPriority,
	projectType,
	setProjectType,
	priorities,
	projectTypes,
	setProjectTypes,
	projectStartDate,
	setProjectStartDate,
	handleStepChange,
	projectEndDate,
	setProjectEndDate,
	voltage,
	branch,
	city,
	village,
	community,
	permissions,
	openedInner
}) {
	const [isComponentBusy, setComponentBusy] = useState(true);
	// queue is set automatically when sending to server
	const { user } = useUser();
	const [History, setHistory] = useState([{}]);
	const [responsible, setResponsible] = useState([]);
	const [selectedTimeInterval, setSelectedTimeInterval] = useState({});
	const [timeLineSelected, setTimeLineSelected] = useState(1);

	//step specific variables
	const [index, setIndex] = useState(0);
	const [startDate, setStartDate] = useState([]);
	const [endDate, setEndDate] = useState([]);
	const [deadline, setDeadline] = useState("");
	const [Id, setCurrentStepId] = useState("");
	const [currentSTEP, setCurrentSTEP] = useState({});

	// inputs variables
	const [descEn, setDescEn] = useState("");
	const [descRu, setDescRu] = useState("");
	const [descHy, setDescHy] = useState("");
	const [mains, setMains] = useState([
		{
			index: 1,
			name: {
				hy: "Հիմնական",
				en: "Main",
				ru: "Основной"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 2,
			name: {
				hy: "Մ/Ճ գլխավոր ճարտարագետ",
				en: "Main chief designer",
				ru: "Главный главный конструктор"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 3,
			name: {
				hy: "Էլ. Ցանցի գլխավոր ճարտարագետ",
				en: "Chief designer of electrical networks",
				ru: "Главный конструктор электрических сетей"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 4,
			name: {
				hy: "ՇՇ Պետ",
				en: "SS Head",
				ru: "Главный главный конструктор"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 5,
			name: {
				hy: "«Տելեկոմ Արմենիա» ԲԲԸ",
				en: "Telecom Armenia LLC",
				ru: "Телеком Армения ООО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 6,
			name: {
				hy: "«Գազպրոմ Արմենիա» ՓԲԸ",
				en: "Gazprom Armenia CJSC",
				ru: "Газпром Армения ЗАО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 7,
			name: {
				hy: "«Վեոլա Ջուր» ՓԲԸ",
				en: "Vola Jour LLC",
				ru: "Вола Жур ООО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 8,
			name: {
				hy: "Երևանի ԳԳՄ",
				en: "Yerevan GGM",
				ru: "Ереван ГГМ"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 9,
			name: {
				hy: "«Էներգոկապ» Մ/Ճ",
				en: "EnergoCap LLC",
				ru: "ЭнергоКап ООО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 10,
			name: {
				hy: "«Երևան Էլ. Տրանսպորտ» ՓԲԸ",
				en: "Yerevan Electric Transport CJSC",
				ru: "Ереван Эл. Транспорт ЗАО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 11,
			name: {
				hy: "Դիմումատու",
				en: "Applicant",
				ru: "Заявитель"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 12,
			name: {
				hy: "Տարածքի սեփականատեր",
				en: "Territory manager",
				ru: "Менеджер территории"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		}
	]);
	const [secondary, setSecondary] = useState([
		{
			index: 1,
			name: {
				hy: "Տրանսգազ ՍՊԸ",
				en: "Transgaz LLC",
				ru: "Трансгаз ООО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 2,
			name: {
				hy: "Սևան ազգային պարկ ՊՈԱԿ",
				en: "Sevan National Park SNCO",
				ru: "Севанский национальный парк ГНКО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 3,
			name: {
				hy: "Դիլիջան Ազգային Պարկ ՊՈԱԿ",
				en: "Dilijan National Park SNCO",
				ru: "Дилижанский национальный парк ГНКО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 4,
			name: {
				hy: "Էլեկտրաէներգետիկական համակարգի օպերատոր ՓԲԸ",
				en: "Electric Power System Operator CJSC",
				ru: "Оператор электрической системы ЗАО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 5,
			name: {
				hy: "Հարավկովկասյան Երկաթուղի ՓԲԸ",
				en: "South Caucasus Railway CJSC",
				ru: "Южно-Кавказская железная дорога ЗАО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 6,
			name: {
				hy: "ԲԷՑ ՓԲԸ",
				en: "BECC CJSC",
				ru: "БЭЦ ЗАО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 7,
			name: {
				hy: "ՀՀ ԿԳՄՍ նախարարություն",
				en: "RA Ministry of Education, Science, Culture and Sports",
				ru: "Министерство образования, науки, культуры и спорта РА"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 8,
			name: {
				hy: "Կանաչապատում և Շրջակա Միջավայրի Պահպանություն ՀՈԱԿ",
				en: "Greening and Environmental Protection SNCO",
				ru: "Озеленение и охрана окружающей среды ГНКО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 9,
			name: {
				hy: "Երևանի Կառուցապատման Ներդրումային ԾԻԳ ՀՈԱԿ",
				en: "Yerevan Construction Investment Project Implementation Unit SNCO",
				ru: "Ереванская строительная инвестиционная проектная группа ГНКО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 10,
			name: {
				hy: "Հայանտառ ՊՈԱԿ",
				en: "Hayantar SNCO",
				ru: "Айантар ГНКО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 11,
			name: {
				hy: "Երքաղլույս ՓԲԸ",
				en: "Yerkaghluys CJSC",
				ru: "Еркахлуйс ЗАО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 12,
			name: {
				hy: "Շրջակա միջավայրի նախարարություն",
				en: "Ministry of Environment",
				ru: "Министерство окружающей среды"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 13,
			name: {
				hy: "ՀՀ ՊՆ ԳՇ ԵՎ ԱԲ վարչության պետ",
				en: "Head of the General Staff and AB Department of the RA Ministry of Defense",
				ru: "Начальник Генерального штаба и АБ управления Министерства обороны РА"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 14,
			name: {
				hy: "Արգելոցապարկային համալիր",
				en: "Reserve Park Complex",
				ru: "Заповедный парк"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		},
		{
			index: 15,
			name: {
				hy: "Ջրառ ՓԲԸ",
				en: "Jrar CJSC",
				ru: "Джрар ЗАО"
			},
			providing: {
				state: false,
				date: ""
			},
			entry: {
				state: false,
				date: ""
			},
			delivery: {
				state: false,
				date: ""
			}
		}
	]);

	const [historySteps, setHistorySteps] = useState([]);

	let otherFields = {
		desc: { en: descEn, ru: descRu, hy: descHy },
		agreement: {
			mains: mains,
			secondary: secondary
		}
	};

	const mainRows = mains?.map((element) => (
		<Table.Tr key={element.index}>
			<Table.Td>{element.name[lang]}</Table.Td>
			<Table.Td>
				<Checkbox
					label={
						element.providing?.date && moment(element.providing.date).format("DD-MM-YYYY, HH:mm:ss")
					}
					onChange={() => {
						let newMains = mains.map((item) => {
							if (item.index === element.index) {
								item.providing.state = !item.providing.state;
								item.providing.date = new Date();
							}
							return item;
						});
						setMains(newMains);
					}}
					checked={element.providing.state}
				/>
			</Table.Td>
			<Table.Td>
				<Checkbox
					label={element.entry?.date && moment(element.entry.date).format("DD-MM-YYYY, HH:mm:ss")}
					onChange={() => {
						let newMains = mains.map((item) => {
							if (item.index === element.index) {
								item.entry.state = !item.entry.state;
								item.entry.date = new Date();
							}
							return item;
						});
						setMains(newMains);
					}}
					checked={element.entry.state}
				/>
			</Table.Td>
			<Table.Td>
				<Checkbox
					label={
						element.delivery?.date && moment(element.delivery.date).format("DD-MM-YYYY, HH:mm:ss")
					}
					onChange={() => {
						let newMains = mains.map((item) => {
							if (item.index === element.index) {
								item.delivery.state = !item.delivery.state;
								item.delivery.date = new Date();
							}

							return item;
						});
						setMains(newMains);
					}}
					checked={element.delivery.state}
				/>
			</Table.Td>
		</Table.Tr>
	));

	const secondaryRows = secondary.map((element) => (
		<Table.Tr key={element.index}>
			<Table.Td>{element.name[lang]}</Table.Td>
			<Table.Td>
				<Checkbox
					label={
						element.providing?.date && moment(element.providing.date).format("DD-MM-YYYY, HH:mm:ss")
					}
					onChange={() => {
						let newSecondary = secondary.map((item) => {
							if (item.index === element.index) {
								item.providing.state = !item.providing.state;
								item.providing.date = new Date();
							}
							return item;
						});
						setSecondary(newSecondary);
					}}
					checked={element.providing.state}
				/>
			</Table.Td>
			<Table.Td>
				<Checkbox
					label={element.entry?.date && moment(element.entry.date).format("DD-MM-YYYY, HH:mm:ss")}
					onChange={() => {
						let newSecondary = secondary.map((item) => {
							if (item.index === element.index) {
								item.entry.state = !item.entry.state;
								item.entry.date = new Date();
							}
							return item;
						});
						setSecondary(newSecondary);
					}}
					checked={element.entry.state}
				/>
			</Table.Td>
			<Table.Td>
				<Checkbox
					label={
						element.delivery?.date && moment(element.delivery.date).format("DD-MM-YYYY, HH:mm:ss")
					}
					onChange={() => {
						let newSecondary = secondary.map((item) => {
							if (item.index === element.index) {
								item.delivery.state = !item.delivery.state;
								item.delivery.date = new Date();
							}
							return item;
						});
						setSecondary(newSecondary);
					}}
					checked={element.delivery.state}
				/>
			</Table.Td>
		</Table.Tr>
	));

	useEffect(() => {
		(async () => {
			try {
				let currentStep = await API.get({
					route: config.api.step,
					query: {
						project_id: id,
						queue: step
					},
					uploadType: true,
					limit: 1,
					populate: ["steps.responsible", "steps.users"]
				});

				// set responsible from branch
				if (branch?.steps?.find((item) => item.index === step)?.responsible) {
					let responsible = branch.steps
						?.find((item) => item.index === step)
						.responsible.map(async (resp) => {
							let uploads = await API.get({
								route: config.api.upload,
								query: {
									obj_id: resp._id
								}
							});
							return { ...resp, uploads };
						});
					try {
						await Promise.all(responsible).then(setResponsible);
					} catch (error) {
						console.error(error);
					}
				}

				// set all states from server
				currentStep[0] && setCurrentSTEP(currentStep[0]);
				currentStep[0]?._id && setCurrentStepId(currentStep[0]?._id);
				currentStep[0]?.startDate && setStartDate(currentStep[0]?.startDate);
				currentStep[0]?.endDate && setEndDate(currentStep[0]?.endDate);
				currentStep[0]?.history && setHistory(currentStep[0]?.history);
				currentStep[0]?.desc?.en && setDescEn(currentStep[0]?.desc.en);
				currentStep[0]?.desc?.ru && setDescRu(currentStep[0]?.desc.ru);
				currentStep[0]?.desc?.hy && setDescHy(currentStep[0]?.desc.hy);
				currentStep[0]?.agreement?.mains &&
					currentStep[0]?.agreement?.mains.length > 0 &&
					setMains(currentStep[0]?.agreement?.mains);
				currentStep[0]?.agreement?.secondary &&
					currentStep[0]?.agreement?.secondary.length > 0 &&
					setSecondary(currentStep[0]?.agreement?.secondary);

				let historySteps = [];
				if (currentStep[0]?.history) {
					for (let i = 0; i < currentStep[0]?.history.length; i++) {
						if (currentStep[0]?.history[i].status === "inProgress") {
							// historySteps.push(currentStep[0]?.history[i]);
							historySteps.push({
								startDate: currentStep[0]?.history?.[i].date,
								endDate: currentStep[0]?.history?.[i + 1]?.date,
								changes: {}
							});
						}
					}
				}
				setHistorySteps(historySteps);
				setTimeLineSelected(historySteps.length - 1);
				setSelectedTimeInterval({
					startDate: historySteps[historySteps.length - 1]?.startDate,
					endDate: historySteps[historySteps.length - 1]?.endDate
				});

				setDeadline(voltage?.deadline?.step11);
			} catch (error) {
				console.log(error);
			}

			// eslint-disable-next-line
			setBusy(false);
			setComponentBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, isComponentBusy]);

	if (isComponentBusy) return <LoadingSkeleton />;
	return (
		<StepPage
			isBusy={isBusy}
			setBusy={setBusy}
			route={route}
			projectId={id}
			stepId={Id}
			handleStepChange={handleStepChange}
			lang={lang}
			History={History}
			modelSendToServer={modelSendToServer}
			pageTitle={localSteps?.[step - 1]?.name[lang]}
			step={step}
			UpdateStep={UpdateStep}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			steps={steps}
			setStep={setStep}
			localSteps={localSteps}
			startDate={startDate}
			endDate={endDate}
			selectedTimeInterval={selectedTimeInterval}
			setSelectedTimeInterval={setSelectedTimeInterval}
			timeLineSelected={timeLineSelected}
			setTimeLineSelected={setTimeLineSelected}
			historySteps={historySteps}
			setComponentBusy={setComponentBusy}
			permissions={permissions}
			openedInner={openedInner}
			responsible={responsible}
			isComponentBusy={isComponentBusy}
			otherFields={otherFields}
			injectedComponent={
				<>
					<Grid
						gap={20}
						p={20}
						grow
						style={{
							width: "100%"
						}}
					>
						<Grid.Col>
							<Title
								style={{
									textAlign: "center"
								}}
								mb={10}
								order={1}
							>
								{T("main", lang)}
							</Title>
							<Table>
								<Table.Thead>
									<Table.Tr>
										<Table.Th>{T("name", lang)}</Table.Th>
										<Table.Th>{T("providing", lang)}</Table.Th>
										<Table.Th>{T("entry", lang)}</Table.Th>
										<Table.Th>{T("delivery", lang)}</Table.Th>
									</Table.Tr>
								</Table.Thead>
								<Table.Tbody>{mainRows}</Table.Tbody>
							</Table>
						</Grid.Col>
						<Grid.Col>
							<Title
								style={{
									textAlign: "center"
								}}
								mb={10}
								order={2}
							>
								{T("secondary", lang)}
							</Title>
							<Table>
								<Table.Thead>
									<Table.Tr>
										<Table.Th>{T("name", lang)}</Table.Th>
										<Table.Th>{T("providing", lang)}</Table.Th>
										<Table.Th>{T("entry", lang)}</Table.Th>
										<Table.Th>{T("delivery", lang)}</Table.Th>
									</Table.Tr>
								</Table.Thead>
								<Table.Tbody>{secondaryRows}</Table.Tbody>
							</Table>
						</Grid.Col>
					</Grid>
				</>
			}
			inputs={[
				historySteps[0]?.startDate && {
					value: moment(historySteps[0]?.startDate).format("DD-MM-YYYY, HH:mm:ss"),
					type: "text",
					usageType: "textfield",
					label: T("startDate", lang),
					disabled: true,
					permissionModel: "step11-startDate",
					rightIcon: <IconTimeline />
				},
				historySteps[0]?.startDate && historySteps[historySteps.length - 1]?.endDate
					? {
							value:
								"It lasts " +
								moment(historySteps[historySteps.length - 1]?.endDate).diff(
									historySteps[historySteps.length - 1]?.startDate,
									"minutes"
								) +
								" working minutes",
							type: "text",
							usageType: "textfield",
							label: T("processTime", lang),
							permissionModel: "step11-processTime",
							disabled: true,
							rightIcon: <IconCalendarClock />
						}
					: {
							value:
								"Still going on " +
								moment(new Date()).diff(
									historySteps[historySteps.length - 1]?.startDate,
									"minutes"
								) +
								" working minutes",
							type: "text",
							usageType: "textfield",
							label: T("processTime", lang),
							permissionModel: "step11-processTime",
							disabled: true,
							rightIcon: <IconCalendarClock />
						},
				historySteps[historySteps.length - 1]?.endDate && {
					value: moment(historySteps[historySteps.length - 1]?.endDate).format(
						"DD-MM-YYYY, HH:mm:ss"
					),
					type: "text",
					usageType: "textfield",
					label: T("endDate", lang),
					disabled: true,
					permissionModel: "step11-endDate",
					rightIcon: <IconCalendarClock />
				},
				deadline && {
					value: moment(historySteps[0]?.startDate).add(deadline, "days").format("DD-MM-YYYY"),
					type: "text",
					usageType: "textfield",
					label: T("deadline", lang),
					permissionModel: "step11-deadline",
					disabled: true,
					rightIcon: <IconCalendarWeek />
				},
				{
					value: descEn,
					setter: setDescEn,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInLatin", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					lang: "en",
					permissionModel: "step11-desc",
					width: 12
				},
				{
					value: descRu,
					setter: setDescRu,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInRussian", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					lang: "ru",
					permissionModel: "step11-desc",
					width: 12
				},
				{
					value: descHy,
					setter: setDescHy,
					type: "textarea",
					usageType: "textarea",
					label: T("description", lang),
					placeholder: T("inputInArmenian", lang),
					disabled: steps?.find((item) => item.queue === step)?.status === "done",
					permissionModel: "step11-desc",
					lang: "hy",
					width: 12
				}
			]}
		/>
	);
}
