import React, { useState, useEffect } from "react";
import * as API from "../../../helpers/api";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import NProgress from "nprogress";
import {
	Card,
	Flex,
	Grid,
	RingProgress,
	Text,
	SimpleGrid,
	Paper,
	List,
	ThemeIcon,
	ScrollArea
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { BarChart, PieChart } from "@mantine/charts";
import { T } from "../../../helpers/translator";

export default function ItDashboard() {
	const [isBusy, setBusy] = useState(true);
	const { lang } = useLang();

	// data
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [allDepartments, setAllDepartments] = useState([]);
	const [allPcs, setAllPcs] = useState([]);
	const [allPrinters, setAllPrinters] = useState([]);
	const [allTickets, setAllTickets] = useState([]);
	const [allMonitors, setAllMonitors] = useState([]);
	const [allSwitches, setAllSwitches] = useState([]);
	const [allProblems, setAllProblems] = useState([]);
	const [ticketsForChart, setTicketsForChart] = useState([]);
	const [ticketTimesDonutChart, setTicketTimesDonutChart] = useState([]);
	const [ticketDepartmentDonutChart, setTicketDepartmentDonutChart] = useState([]);
	const [ticketObjectiveDonutChart, setTicketObjectiveDonutChart] = useState([]);

	const processTickets = (tickets, key, dataFunc, colorFunc) => {
		const countByKey = {};

		tickets.forEach((ticket) => {
			const value = key(ticket);
			countByKey[value] = (countByKey[value] || 0) + 1;
		});

		const ordered = Object.keys(countByKey)
			.sort((a, b) => countByKey[b] - countByKey[a])
			.map((key) => ({
				value: countByKey[key],
				name: dataFunc(key),
				color: colorFunc(key)
			}));

		return ordered;
	};

	const ticketCountByDateFunc = (tickets) => {
		const ticketCountByDate = tickets.reduce((acc, ticket) => {
			const date = ticket.openedDate.split("T")[0];
			acc[date] = (acc[date] || 0) + 1;
			return acc;
		}, {});

		const result = Object.entries(ticketCountByDate)
			.map(([date, count]) => ({ date, count }))
			.sort((a, b) => new Date(a.date) - new Date(b.date));

		setTicketsForChart(result);
	};

	const ticketTimesDonutChartFunc = (tickets) => {
		const timeRanges = [
			{ range: "0-5", max: 5, color: "cyan.6" },
			{ range: "5-10", max: 10, color: "blue.6" },
			{ range: "10-20", max: 20, color: "yellow.6" },
			{ range: "20+", max: Infinity, color: "red.6" }
		];
		const result = processTickets(
			tickets,
			(ticket) => {
				const openedDate = new Date(ticket.openedDate);
				const closedDate = new Date(ticket.closedDate);
				if (isNaN(openedDate.getTime()) || isNaN(closedDate.getTime())) {
					console.warn("Invalid ticket dates:", ticket);
					return null;
				}
				const timeTaken = (closedDate - openedDate) / 1000 / 60;
				return timeRanges.find((range) => timeTaken <= range.max)?.range || "unknown";
			},
			(range) => T("timeRange", lang)?.[range] || range + " minutes",
			(range) => {
				const matchedRange = timeRanges.find((r) => r.range === range);
				return matchedRange ? matchedRange.color : "gray";
			}
		);
		setTicketTimesDonutChart(result);
	};

	const ticketDepartmentDonutChartFunc = (tickets) => {
		tickets.forEach((ticket) => {
			if (ticket.createdByUser.department) {
				ticket.department = allDepartments.find((d) => d._id === ticket.createdByUser.department);
			}
		});

		let customColors = [
			"black.6",
			"purple.6",
			"indigo.6",
			"violet.6",
			"teal.6",
			"green.6",
			"pink.6",
			"lime.6",
			"orange.6",
			"red.6",
			"yellow.6",
			"blue.6",
			"cyan.6"
		];

		const result = processTickets(
			tickets,
			(ticket) => ticket.department?._id,
			(department) => allDepartments.find((d) => d._id === department)?.name?.[lang],
			() => customColors.pop()
		);

		setTicketDepartmentDonutChart(result.slice(0, 8));
	};

	const ticketObjectiveDonutChartFunc = (tickets) => {
		let customColors = [
			"black.6",
			"purple.6",
			"indigo.6",
			"violet.6",
			"teal.6",
			"green.6",
			"pink.6",
			"lime.6",
			"orange.6",
			"red.6",
			"yellow.6",
			"blue.6",
			"cyan.6"
		];

		const result = processTickets(
			tickets,
			(ticket) => ticket.objective.problem,
			(objective) => allProblems.find((p) => p._id === objective)?.name?.[lang],
			() => customColors.pop()
		);
		setTicketObjectiveDonutChart(result.slice(0, 10));
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all pcs
			let pcs = await API.get({
				route: config.api.pc
			});
			if (pcs) setAllPcs(pcs);

			// all printers
			let printers = await API.get({
				route: config.api.printer
			});
			if (printers) setAllPrinters(printers);

			// all tickets
			let tickets = await API.get({
				route: config.api.ticket
			});
			if (tickets) setAllTickets(tickets);

			// all monitors
			let monitors = await API.get({
				route: config.api.monitor
			});
			if (monitors) setAllMonitors(monitors);

			// all switches
			let switches = await API.get({
				route: config.api.switch
			});
			if (switches) setAllSwitches(switches);

			// all departments
			let departments = await API.get({
				route: config.api.department
			});
			if (departments) setAllDepartments(departments);

			// all problems
			let problems = await API.get({
				route: config.api.problem
			});
			if (problems) setAllProblems(problems);

			NProgress.done();

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		if (startDate && endDate) {
			(async () => {
				NProgress.start();
				try {
					let tickets = await API.get({
						route: config.api.ticket,
						query: {
							openedDate: {
								$gte: startDate.toLocaleString().split(",")[0],
								$lte: endDate.toLocaleString().split(",")[0]
							}
						},
						populate: ["createdByUser"]
					});

					ticketCountByDateFunc(tickets);
					ticketTimesDonutChartFunc(tickets);
					ticketDepartmentDonutChartFunc(tickets);
					ticketObjectiveDonutChartFunc(tickets);

					if (tickets) setAllTickets(tickets);
				} catch (error) {
					console.error("Error fetching tickets:", error);
				} finally {
					NProgress.done();
				}
			})();
		}
		// eslint-disable-next-line
	}, [startDate, endDate, lang]);

	if (!isBusy) {
		return (
			<>
				<Paper withBorder p="md" radius="md" shadow="xs">
					<Grid gutter="md" columns={12} justify="center" align="center">
						<Grid.Col span={12}>
							<Flex justify="space-between" align="center">
								<DatePicker value={startDate} onChange={setStartDate} placeholder="Start Date" />
								{allTickets.length > 0 && (
									<BarChart
										h={400}
										data={ticketsForChart}
										dataKey="date"
										series={[{ name: "count", color: "violet.6" }]}
									/>
								)}
								<DatePicker value={endDate} onChange={setEndDate} placeholder="End Date" />
							</Flex>
						</Grid.Col>
						<Grid.Col span={3}>
							<Card shadow="xs" radius="md" p="md">
								<ScrollArea h={400}>
									<Text align="center" fz="lg" fw={500} mb={20}>
										{T("time", lang)}
									</Text>
									<Flex justify="space-between" align="center">
										<PieChart
											withLabelsLine
											labelsPosition="outside"
											labelsType="percent"
											withLabels
											data={ticketTimesDonutChart}
										/>
										<List spacing="xs" size="sm" center>
											{ticketTimesDonutChart.map((item, index) => (
												<List.Item
													key={index}
													icon={
														<ThemeIcon color={item.color} size={24} radius="xl">
															{item.value}
														</ThemeIcon>
													}
												>
													{item.name}
												</List.Item>
											))}
										</List>
									</Flex>
								</ScrollArea>
							</Card>
						</Grid.Col>
						<Grid.Col span={5}>
							<Card shadow="xs" radius="md" p="md">
								<ScrollArea h={400}>
									<Text align="center" fz="lg" fw={500} mb={20}>
										{T("department", lang)}
									</Text>
									<Flex justify="space-between" align="center">
										<PieChart
											withLabelsLine
											labelsPosition="outside"
											labelsType="percent"
											withLabels
											data={ticketDepartmentDonutChart}
										/>
										<List spacing="xs" size="sm" center>
											{ticketDepartmentDonutChart.map((item) => {
												return (
													<List.Item
														icon={
															<ThemeIcon color={item.color} size={24} radius="xl">
																{item.value}
															</ThemeIcon>
														}
													>
														{item.name}
													</List.Item>
												);
											})}
										</List>
									</Flex>
								</ScrollArea>
							</Card>
						</Grid.Col>
						<Grid.Col span={4}>
							<Card shadow="xs" radius="md" p="md">
								<ScrollArea h={400}>
									<Text align="center" fz="lg" fw={500} mb={20}>
										{T("problem", lang)}
									</Text>
									<Flex justify="space-between" align="center">
										<PieChart
											withLabelsLine
											labelsPosition="outside"
											labelsType="percent"
											withLabels
											data={ticketObjectiveDonutChart}
										/>

										<List spacing="xs" size="sm" center>
											{ticketObjectiveDonutChart.map((item) => {
												return (
													<List.Item
														icon={
															<ThemeIcon color={item.color} size={24} radius="xl">
																{item.value}
															</ThemeIcon>
														}
													>
														{item.name}
													</List.Item>
												);
											})}
										</List>
									</Flex>
								</ScrollArea>
							</Card>
						</Grid.Col>
						<Grid.Col span={12}>
							<Card shadow="xs" radius="md" p="md">
								<SimpleGrid cols={{ base: 1, sm: 3 }} spacing="lg">
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" h="80">
											<Text align="center" fz="lg" fw={500}>
												{T("pcs", lang)}
											</Text>
											<Text align="center" fz="lg" c={"cyan.6"} fw={700}>
												{allPcs.length}
											</Text>
										</Flex>
									</Paper>

									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("working", lang)}
												</Text>
												<Text fw={700} c={"teal"} size="xl">
													{allPcs.filter((item) => item.working).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allPcs.filter((item) => item.working).length / allPcs.length) * 100,
														color: "teal"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allPcs.filter((item) => !item.working).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notWorking", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("placed", lang)}
												</Text>
												<Text fw={700} c={"cyan"} size="xl">
													{allPcs.filter((item) => item.placed).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allPcs.filter((item) => item.placed).length / allPcs.length) * 100,
														color: "cyan"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allPcs.filter((item) => !item.placed).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notPlaced", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
								</SimpleGrid>
							</Card>
						</Grid.Col>
						<Grid.Col span={12}>
							<Card shadow="xs" radius="md" p="md">
								<SimpleGrid cols={{ base: 1, sm: 3 }} spacing="lg">
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" h="80">
											<Text align="center" fz="lg" fw={500}>
												{T("printers", lang)}
											</Text>
											<Text align="center" fz="lg" c={"cyan.6"} fw={700}>
												{allPrinters.length}
											</Text>
										</Flex>
									</Paper>

									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("working", lang)}
												</Text>
												<Text fw={700} c={"teal"} size="xl">
													{allPrinters.filter((item) => item.working).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allPrinters.filter((item) => item.working).length /
																allPrinters.length) *
															100,
														color: "teal"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allPrinters.filter((item) => !item.working).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notWorking", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("placed", lang)}
												</Text>
												<Text fw={700} c={"cyan"} size="xl">
													{allPrinters.filter((item) => item.placed).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allPrinters.filter((item) => item.placed).length /
																allPrinters.length) *
															100,
														color: "cyan"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allPrinters.filter((item) => !item.placed).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notPlaced", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
								</SimpleGrid>
							</Card>
						</Grid.Col>
						<Grid.Col span={12}>
							<Card shadow="xs" radius="md" p="md">
								<SimpleGrid cols={{ base: 1, sm: 3 }} spacing="lg">
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" h="80">
											<Text align="center" fz="lg" fw={500}>
												{T("switchers", lang)}
											</Text>
											<Text align="center" fz="lg" c={"cyan.6"} fw={700}>
												{allSwitches.length}
											</Text>
										</Flex>
									</Paper>

									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("working", lang)}
												</Text>
												<Text fw={700} c={"teal"} size="xl">
													{allSwitches.filter((item) => item.working).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allSwitches.filter((item) => item.working).length /
																allSwitches.length) *
															100,
														color: "teal"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allSwitches.filter((item) => !item.working).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notWorking", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("placed", lang)}
												</Text>
												<Text fw={700} c={"cyan"} size="xl">
													{allSwitches.filter((item) => item.placed).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allSwitches.filter((item) => item.placed).length /
																allSwitches.length) *
															100,
														color: "cyan"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allSwitches.filter((item) => !item.placed).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notPlaced", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
								</SimpleGrid>
							</Card>
						</Grid.Col>
						<Grid.Col span={12}>
							<Card shadow="xs" radius="md" p="md">
								<SimpleGrid cols={{ base: 1, sm: 3 }} spacing="lg">
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" h="80">
											<Text align="center" fz="lg" fw={500}>
												{T("monitors", lang)}
											</Text>
											<Text align="center" fz="lg" c={"cyan.6"} fw={700}>
												{allMonitors.length}
											</Text>
										</Flex>
									</Paper>

									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("working", lang)}
												</Text>
												<Text fw={700} c={"teal"} size="xl">
													{allMonitors.filter((item) => item.working).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allMonitors.filter((item) => item.working).length /
																allMonitors.length) *
															100,
														color: "teal"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allMonitors.filter((item) => !item.working).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notWorking", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
									<Paper withBorder radius="md" p="xs">
										<Flex justify="space-around" align="center" w={"100%"}>
											<Flex justify={"center"} align={"center"} gap={14}>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("placed", lang)}
												</Text>
												<Text fw={700} c={"cyan"} size="xl">
													{allMonitors.filter((item) => item.placed).length}
												</Text>
											</Flex>
											<RingProgress
												size={80}
												roundCaps
												thickness={8}
												sections={[
													{
														value:
															(allMonitors.filter((item) => item.placed).length /
																allMonitors.length) *
															100,
														color: "cyan"
													}
												]}
											/>

											<Flex justify={"center"} align={"center"} gap={14}>
												<Text fw={700} size="xl">
													{allMonitors.filter((item) => !item.placed).length}
												</Text>
												<Text c="dimmed" size="xs" tt="uppercase" fw={700}>
													{T("notPlaced", lang)}
												</Text>
											</Flex>
										</Flex>
									</Paper>
								</SimpleGrid>
							</Card>
						</Grid.Col>
					</Grid>
				</Paper>
			</>
		);
	}
}
