import React, { useState } from "react";
import { Avatar, Button, Divider, Drawer, Flex, Group, Paper, Text } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import Message from "../message/Message";
import parse from "html-react-parser";
import { useUser } from "../../../helpers/userContext";
import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";

const Reply = ({ open, onClose, data, title }) => {
	const [message, setMessage] = useState("");
	const { user } = useUser();
	const editor = useEditor({
		extensions: [
			StarterKit,
			Placeholder.configure({ placeholder: "This is placeholder" }),
			Underline,
			TextAlign,
			Superscript,
			SubScript
		],
		content: message,
		onUpdate: ({ editor }) => {
			setMessage(editor.getHTML());
		}
	});
	return (
		<Drawer
			opened={open}
			onClose={onClose}
			title={<Text p="md">{title}</Text>}
			position="right"
			padding={0}
			overlayProps={{ backgroundOpacity: 0.2, blur: 0 }}
			closeButtonProps={{
				style: {
					marginRight: 5
				}
			}}
		>
			<Divider mb="sm" />
			<Paper px="md" pb="md">
				{parse(data?.body ? data.body : "")}
				<RichTextEditor editor={editor}>
					<RichTextEditor.Toolbar
						sticky
						stickyOffset={60}
						style={{ justifyContent: "space-between" }}
					>
						<RichTextEditor.ControlsGroup style={{ flexWrap: "wrap" }}>
							<RichTextEditor.Bold />
							<RichTextEditor.Italic />
							<RichTextEditor.Underline />
							<RichTextEditor.Strikethrough />
							<RichTextEditor.ClearFormatting />
							<RichTextEditor.Highlight />
							<RichTextEditor.Code />
							<RichTextEditor.Hr />
							<RichTextEditor.BulletList />
							<RichTextEditor.OrderedList />
							<RichTextEditor.Link />
							<RichTextEditor.Unlink />
						</RichTextEditor.ControlsGroup>
					</RichTextEditor.Toolbar>
					<RichTextEditor.Content />
				</RichTextEditor>
				<Group grow justifyContent="flex-end" mt="md">
					<Button variant="filled">Reply</Button>
				</Group>
			</Paper>
			<Divider mb="sm" />
			<Message
				message={{
					id: 1,
					body: `asfsdfdsfsdfsdfs`,
					created: "10.10.2024"
				}}
				user={user}
				hideActions
			/>
		</Drawer>
	);
};

export default Reply;
