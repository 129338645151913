import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import { useLang } from "../../helpers/language";
import config from "../../config";
import Swal from "sweetalert2";
import moment from "moment";
import classes from "../Tabs.module.css";
//! import modules
import Input from "../modules/input";
import "date-fns";
import { IconArrowBack, IconDeviceFloppy, IconList } from "@tabler/icons-react";
import {
	Group,
	Button,
	Card,
	Tabs,
	Grid,
	CardSection,
	useMantineColorScheme,
	useMantineTheme,
	Flex,
	Checkbox,
	HoverCard,
	Text
} from "@mantine/core";
import { useUser } from "../../helpers/userContext";
import { T } from "../../helpers/translator";
import { FilesComponent } from "../modules/filesComponent";

export default function ProjectAdd() {
	const [isBusy, setBusy] = useState(true);
	const route = config.api.project;
	const { lang } = useLang();
	const { user } = useUser();
	const history = useHistory();
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();
	const location = useLocation();
	const id = location.pathname.split("/").pop();

	const [permissions, setPermissions] = useState([]);

	//! global variables
	const [ID, setID] = useState("");
	const [priority, setPriority] = useState(0); //  0 less , 1 mid , 2 high
	const [importance, setImportance] = useState(0); //  0 less , 1 mid , 2 high
	const [mediator, setMediator] = useState("");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState("");

	const [projectType, setProjectType] = useState("");
	const [projectTypes, setProjectTypes] = useState("");

	const [community, setCommunity] = useState("");
	const [communities, setCommunities] = useState("");

	const [city, setCity] = useState(null);
	const [cities, setCities] = useState(null);

	const [village, setVillage] = useState(null);
	const [villages, setVillages] = useState(null);

	const [branch, setBranch] = useState(null);
	const [branches, setBranches] = useState(null);

	const [voltage, setVoltage] = useState(null);
	const [voltages, setVoltages] = useState(null);

	const [projectPartition, setProjectPartition] = useState("");
	const [contractor, setContractor] = useState("");
	const [disabledSteps, setDisabledSteps] = useState("");
	const [oldProject, setOldProject] = useState(false);

	const [substation, setSubstation] = useState("");
	const [orderNumber, setOrderNumber] = useState("");
	const [address, setAddress] = useState("");
	const [workingDays, setWorkingDays] = useState("");
	const [tempStep, setTempStep] = useState("");

	const [step, setStep] = useState(1); // current selected step
	const [activeStep, setActiveStep] = useState(undefined); // current selected step
	const [steps, setSteps] = useState([]);
	const [users, setUsers] = useState([{}]);
	const [pdfFiles, setPdfFiles] = useState([]);

	// hardcoded information
	const projectPartitions = [
		{
			_id: "0",
			name: {
				en: "Main",
				ru: "Основной",
				hy: "Հիմնական",
				tr: "Ana",
				ar: "رئيسي",
				zh: "主要",
				fa: "اصلی",
				he: "ראשי",
				fr: "Principal",
				es: "Principal",
				pt: "Principal"
			}
		},
		{
			_id: "1",
			name: {
				en: "Partition",
				ru: "Подразделение",
				hy: "Հատոր",
				tr: "Bölüm",
				ar: "تقسيم",
				zh: "分区",
				fa: "پارتیشن",
				he: "מחיצה",
				fr: "Partition",
				es: "Partición",
				pt: "Partição"
			}
		},
		{
			_id: "2",
			name: {
				en: "Project change",
				ru: "Изменение проекта",
				hy: "Նախագծի փոփոխություն",
				tr: "Proje değişikliği",
				ar: "تغيير المشروع",
				zh: "项目变更",
				fa: "تغییر پروژه",
				he: "שינוי פרויקט",
				fr: "Changement de projet",
				es: "Cambio de proyecto",
				pt: "Mudança de projeto"
			}
		}
	];

	const contractors = [
		{
			_id: "0",
			name: {
				en: "Arev Qnstrakshn",
				ru: "Арев Кнстркшн",
				hy: "Արև Քնստրաքշն",
				tr: "Arev İnşaat",
				ar: "أريف كنستركشن",
				zh: "阿雷夫建筑",
				fa: "آرو ساخت و ساز",
				he: "ארב קונסטרקשן",
				fr: "Arev Construction",
				es: "Arev Construcción",
				pt: "Arev Construção"
			}
		},
		{
			_id: "1",
			name: {
				en: "Lus Energy",
				ru: "Лус Энерджи",
				hy: "Լույս Էներջի",
				tr: "Lus Enerji",
				ar: "لوس إنرجي",
				zh: "卢斯能源",
				fa: "لوس انرژی",
				he: "לוס אנרגיה",
				fr: "Lus Énergie",
				es: "Lus Energía",
				pt: "Lus Energia"
			}
		},
		{
			_id: "2",
			name: {
				en: "Inteco Energo",
				ru: "Интеко Энерго",
				hy: "Ինտեկո Էներգո",
				tr: "Inteco Enerji",
				ar: "إنتيكو إنرغو",
				zh: "英特科能源",
				fa: "اینتکو انرژی",
				he: "אינטקו אנרגיה",
				fr: "Inteco Énergie",
				es: "Inteco Energía",
				pt: "Inteco Energia"
			}
		},
		{
			_id: "3",
			name: {
				en: "Cascade Energo Service",
				ru: "Каскад Энерго Сервис",
				hy: "Կասկադ Էներգո Սերվիս",
				tr: "Kaskad Enerji Servisi",
				ar: "خدمة الطاقة كاسكيد",
				zh: "级联能源服务",
				fa: "خدمات انرژی کاسکاد",
				he: "שירות אנרגיה קסקייד",
				fr: "Service Énergie Cascade",
				es: "Servicio de Energía en Cascada",
				pt: "Serviço de Energia em Cascata"
			}
		}
	];

	const priorities = [
		{
			_id: "0",
			name: {
				en: "Low",
				ru: "Низкий",
				hy: "Ցածր",
				tr: "Düşük",
				ar: "منخفض",
				zh: "低",
				fa: "کم",
				he: "נמוך",
				fr: "Faible",
				es: "Bajo",
				pt: "Baixo"
			}
		},
		{
			_id: "1",
			name: {
				en: "Medium",
				ru: "Средний",
				hy: "Միջին",
				tr: "Orta",
				ar: "متوسط",
				zh: "中",
				fa: "متوسط",
				he: "בינוני",
				fr: "Moyen",
				es: "Medio",
				pt: "Médio"
			}
		},
		{
			_id: "2",
			name: {
				en: "High",
				ru: "Высокий",
				hy: "Բարձր",
				tr: "Yüksek",
				ar: "عالي",
				zh: "高",
				fa: "زیاد",
				he: "גבוה",
				fr: "Élevé",
				es: "Alto",
				pt: "Alto"
			}
		}
	];

	const importances = [
		{
			_id: "0",
			name: {
				en: "Low",
				ru: "Низкий",
				hy: "Ցածր",
				tr: "Düşük",
				ar: "منخفض",
				zh: "低",
				fa: "کم",
				he: "נמוך",
				fr: "Faible",
				es: "Bajo",
				pt: "Baixo"
			}
		},
		{
			_id: "1",
			name: {
				en: "Medium",
				ru: "Средний",
				hy: "Միջին",
				tr: "Orta",
				ar: "متوسط",
				zh: "中",
				fa: "متوسط",
				he: "בינוני",
				fr: "Moyen",
				es: "Medio",
				pt: "Médio"
			}
		},
		{
			_id: "2",
			name: {
				en: "High",
				ru: "Высокий",
				hy: "Բարձր",
				tr: "Yüksek",
				ar: "عالي",
				zh: "高",
				fa: "زیاد",
				he: "גבוה",
				fr: "Élevé",
				es: "Alto",
				pt: "Alto"
			}
		}
	];

	const disabledStepsArray = [
		{
			name: {
				hy: "Տիմ մուտք պետք չէ",
				en: "No team entry required",
				ru: "Вход команды не требуется",
				tr: "Takım girişi gerekli değil",
				ar: "لا يلزم إدخال الفريق",
				zh: "不需要团队输入",
				fa: "ورود تیم لازم نیست",
				he: "אין צורך בכניסת צוות",
				fr: "Pas d'entrée d'équipe requise",
				es: "No se requiere entrada de equipo",
				pt: "Nenhuma entrada de equipe necessária"
			},
			steps: [10, 13, 14, 17, 18, 19, 20, 39, 40, 41, 42, 43, 44]
		},
		{
			name: {
				hy: "Տեղազննում պետք չէ",
				en: "No site inspection required",
				ru: "Осмотр сайта не требуется",
				tr: "Site denetimi gerekli değil",
				ar: "لا يلزم التفتيش على الموقع",
				zh: "不需要现场检查",
				fa: "بازرسی سایت لازم نیست",
				he: "אין צורך בבדיקת אתר",
				fr: "Pas d'inspection de site requise",
				es: "No se requiere inspección del sitio",
				pt: "Nenhuma inspeção no local necessária"
			},
			steps: [1]
		},
		{
			name: {
				hy: "Գրունտ պետք չէ",
				en: "No soil required",
				ru: "Почва не требуется",
				tr: "Toprak gerekli değil",
				ar: "لا يلزم التربة",
				zh: "不需要土壤",
				fa: "خاک لازم نیست",
				he: "אין צורך באדמה",
				fr: "Pas de sol requis",
				es: "No se requiere suelo",
				pt: "Nenhum solo necessário"
			},
			steps: [2]
		},
		{
			name: {
				hy: "Նախահաշիվ պետք չէ",
				en: "No preliminary estimate required",
				ru: "Предварительная оценка не требуется",
				tr: "Ön tahmin gerekli değil",
				ar: "لا يلزم تقدير أولي",
				zh: "不需要初步估计",
				fa: "برآورد اولیه لازم نیست",
				he: "אין צורך בהערכה מוקדמת",
				fr: "Pas d'estimation préliminaire requise",
				es: "No se requiere estimación preliminar",
				pt: "Nenhuma estimativa preliminar necessária"
			},
			steps: [28, 29, 30, 31, 32, 33]
		},
		{
			name: {
				hy: "Նախնական ՀԷՑ հանձնելու կարիք չկա",
				en: "No need to submit to preliminary HEC",
				ru: "Нет необходимости подавать в предварительный ГЭК",
				tr: "Ön HEC'ye sunmaya gerek yok",
				ar: "لا حاجة لتقديم إلى HEC الأولي",
				zh: "无需提交初步HEC",
				fa: "نیازی به ارسال به HEC اولیه نیست",
				he: "אין צורך להגיש ל-HEC ראשוני",
				fr: "Pas besoin de soumettre au HEC préliminaire",
				es: "No es necesario presentar al HEC preliminar",
				pt: "Não há necessidade de enviar ao HEC preliminar"
			},
			steps: [15, 16]
		},
		{
			name: {
				hy: "Գծագրում պետք չէ",
				en: "No drawing required",
				ru: "Чертеж не требуется",
				tr: "Çizim gerekli değil",
				ar: "لا يلزم الرسم",
				zh: "不需要绘图",
				fa: "نقشه کشی لازم نیست",
				he: "אין צורך בציור",
				fr: "Pas de dessin requis",
				es: "No se requiere dibujo",
				pt: "Nenhum desenho necessário"
			},
			steps: [4, 5, 6, 7, 8, 9, 11, 12]
		}
	];

	let modelSendToServer = {};

	modelSendToServer = {
		ID,
		history: history ? history : [],
		priority,
		importance,
		startDate,
		endDate,
		steps,
		projectType,
		city,
		branch,
		substation,
		voltage,
		orderNumber,
		mediator,
		address,
		workingDays,
		projectPartition,
		contractor,
		disabledSteps,
		community,
		village,
		oldProject
	};

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "project") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	const HandlePermissionCheck = (input) => {
		if (input) {
			if (user?.ananunaki) {
				input.crud = {
					create: true,
					read: true,
					update: true,
					delete: true
				};
				return input;
			}
			if (permissions) {
				let foundedInput = permissions?.find((inp) => inp.name === input?.permissionModel);
				if (foundedInput && foundedInput.read) {
					input.crud = {
						create: foundedInput.create !== undefined ? foundedInput.create : false,
						read: foundedInput.read !== undefined ? foundedInput.read : false,
						update: foundedInput.update !== undefined ? foundedInput.update : false,
						delete: foundedInput.delete !== undefined ? foundedInput.delete : false
					};
					return input;
				}
			} else {
				console.error("Permission not found");
			}
		}
	};

	useEffect(() => {
		(async () => {
			// all users
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setUsers(rawUsers);
			// communities
			let rawCommunities = await API.get({ route: config.api.community });
			if (rawCommunities) setCommunities(rawCommunities);
			// all voltages
			let rawVoltages = await API.get({ route: config.api.voltage });
			if (rawVoltages) setVoltages(rawVoltages);
			// all projectTypes
			let rawProjectTypes = await API.get({ route: config.api.projectType });
			if (rawProjectTypes) setProjectTypes(rawProjectTypes);

			let tempStep = await API.post(config.api.step, {});
			console.log("🚀 ~ tempStep:", tempStep);
			setTempStep(tempStep);

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		(async () => {
			if (projectType) {
				let currentProjectType = projectTypes?.filter((item) => item._id === projectType)[0];
				let Projects = await API.get({
					route,
					query: {
						projectType: currentProjectType?._id,
						temporary: {
							$ne: true
						}
					},
					sort: {
						ID: -1
					}
				});

				if (Projects[0]?.ID || Projects[0]?.ID === 0) {
					let result;
					if (!isNaN(Projects[0].ID)) {
						result = parseInt(Projects[0].ID) + 1;
					} else if (isNaN(Projects[0].ID)) {
						result = Projects[0].ID + "-1";
					}
					setID(result);
				} else {
					setID(0);
				}
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [projectType]);

	useEffect(() => {
		(async () => {
			// community has villages or cities get them by selected community
			if (community) {
				let currentCommunity = communities.filter((item) => item._id === community)[0];
				if (currentCommunity) {
					if (currentCommunity.villages) {
						let rawVillages = await API.get({
							route: config.api.village,
							query: {
								_id: {
									$in: currentCommunity.villages
								}
							}
						});
						setVillages(rawVillages);
					}
					if (currentCommunity.cities) {
						let rawCities = await API.get({
							route: config.api.city,
							query: {
								_id: {
									$in: currentCommunity.cities
								}
							}
						});
						setCities(rawCities);
					}
					if (currentCommunity.branches) {
						let rawBranches = await API.get({
							route: config.api.branch,
							query: {
								_id: {
									$in: currentCommunity.branches
								}
							}
						});
						setBranches(rawBranches);
					}
				}
			}
		})();
		// eslint-disable-next-line
	}, [community]);

	// ! MAIN BUTTONS -------SAVE------REMOVE------CANCEL
	const saveItem = async () => {
		Swal.fire({
			title: T("areYouSure", lang),
			text: T("youCannotRevertThisAction", lang),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: T("yes", lang)
		}).then(async (result) => {
			if (result.isConfirmed) {
				setBusy(true);
				modelSendToServer.temporary = false;
				modelSendToServer.steps[0] = tempStep._id;
				// create a step empty object
				console.log("🚀 ~ modelSendToServer:", modelSendToServer);

				try {
					let result = await API.update(route, id, modelSendToServer);
					if (result) {
						try {
							if (pdfFiles.length > 0)
								await API.upload(result._id, pdfFiles, lang, "files", () => {}, new Date());
						} catch (error) {
							console.error(error);
						}
						try {
							await API.update(config.api.step, tempStep._id, {
								project_id: result._id,
								queue: step,
								startDate: [startDate],
								status: "inProgress",
								history: [
									{
										date: new Date(),
										user: user._id,
										status: "inProgress"
									}
								]
							});
							Swal.fire(T("saved", lang), T("yourFileHasBeenSaved", lang), "success");
							setTimeout(() => {
								history.push(`/${route}/edit/${result._id}`);
							}, 1000);
						} catch (error) {
							console.error(error);
						}
					} else {
						console.error("error in saving project");
					}
				} catch (error) {
					console.log(error);
				}
			}
		});
	};

	function setEndDateWorkingDays() {
		let localEndDate;
		let localWorkingDays = 0;
		let StartDate = startDate ? new Date(startDate) : new Date();
		voltages?.length > 0 &&
			voltages?.forEach((item) => {
				if (item._id === voltage) {
					// localeeddate is a number but we assign it to a date object
					localEndDate = new Date(
						StartDate.getTime() + item.deadline?.project * 24 * 60 * 60 * 1000
					);
					// Assuming StartDate and localEndDate are defined and are JavaScript Date objects
					// Calculate the difference in working days
					while (StartDate < localEndDate) {
						// Skips Sunday and Saturday
						if (StartDate.getDay() !== 0 && StartDate.getDay() !== 6) {
							localWorkingDays++;
						}
						StartDate.setDate(StartDate.getDate() + 1);
					}
					setWorkingDays(localWorkingDays);
					setEndDate(localEndDate);
					setEndDate(localEndDate);
				}
			});
	}

	useEffect(() => {
		(async () => {
			setEndDateWorkingDays();
			// eslint-disable-next-line
			// let files = await getUploads(id);
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, activeStep]);

	useEffect(() => {
		setEndDateWorkingDays();
		// eslint-disable-next-line
	}, [voltage]);

	return (
		<Tabs defaultValue="first" variant="unstyled" classNames={classes}>
			<Tabs.List>
				<Tabs.Tab value="first" color="blue" leftSection={<IconList size={14} />}>
					{T("mainInformation", lang)}
				</Tabs.Tab>
			</Tabs.List>
			<Tabs.Panel value="first">
				<Card
					shadow="sm"
					p="md"
					style={{
						borderTopLeftRadius: 0
					}}
				>
					<Grid
						p={20}
						align="end"
						style={{
							background: colorScheme === "dark" ? Theme.colors.dark[6] : ""
						}}
					>
						{/* <Grid.Col
							span={{
								base: 12,
								md: 6,
								lg: 3
							}}
						>
							<Input
								value={moment(new Date()).format("DD-MM-YYYY, HH:mm:ss")}
								type="text"
								usageType="textfield"
								disabled={true}
								label={T("startDate", lang)}
								lang={lang}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						</Grid.Col> */}

						{projectTypes && projectTypes.length > 0 && (
							<Grid.Col
								span={{
									base: 3,
									md: 3,
									lg: 2
								}}
							>
								<Input
									value={projectType?._id}
									setter={setProjectType}
									optionListValue={projectTypes}
									type="optionList"
									usageType="optionList"
									label={T("projectTypes", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{projectType && projectTypes && projectTypes.length > 0 && (
							<Grid.Col
								span={{
									base: 3,
									md: 3,
									lg: 2
								}}
							>
								<Flex justify="center" align="center">
									{projectType.abbreviation} -{" "}
									<Input
										value={ID}
										setter={setID}
										type="text"
										usageType="textfield"
										label={T("id", lang)}
										placeholder={T("id", lang)}
										crud={{
											create: true,
											read: true,
											update: true,
											delete: true
										}}
									/>
								</Flex>
							</Grid.Col>
						)}

						{projectType && communities && communities.length > 0 && (
							<Grid.Col
								span={{
									base: 3,
									md: 3,
									lg: 2
								}}
							>
								<Input
									value={community?._id}
									setter={setCommunity}
									optionListValue={communities}
									type="optionList"
									usageType="optionList"
									label={T("community", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{community && !city && villages && villages.length > 0 && (
							<Grid.Col span={{ base: 3, md: 3, lg: 2 }}>
								<Input
									value={village?._id}
									setter={setVillage}
									optionListValue={villages}
									type="optionList"
									usageType="optionList"
									label={T("village", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{community && !village && cities && cities.length > 0 && (
							<Grid.Col
								span={{
									base: 3,
									md: 3,
									lg: 2
								}}
							>
								<Input
									value={city?._id}
									setter={setCity}
									optionListValue={cities}
									type="optionList"
									usageType="optionList"
									label={T("city", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{community && branches && branches.length > 0 && (
							<Grid.Col
								span={{
									base: 6,
									md: 5,
									lg: 3
								}}
							>
								<Input
									value={branch?._id}
									setter={setBranch}
									optionListValue={branches}
									type="optionList"
									usageType="optionList"
									label={T("branch", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{branch || village ? (
							<Grid.Col
								span={{
									base: 6,
									md: 5,
									lg: 3
								}}
							>
								<Input
									value={voltage?._id}
									setter={setVoltage}
									optionListValue={voltages}
									type="optionList"
									usageType="optionList"
									label={T("voltageLevel", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						) : null}

						{voltage && (
							<Grid.Col
								span={{
									base: 6,
									md: 5,
									lg: 3
								}}
							>
								<Input
									value={
										endDate &&
										endDate instanceof Date &&
										endDate?.toLocaleDateString() +
											" (" +
											workingDays +
											" " +
											T("workingDays", lang) +
											")"
									}
									optionListValue={voltages}
									type="textfield"
									usageType="textfield"
									disabled={true}
									label={T("deadLine", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{voltage && (
							<Grid.Col
								span={{
									base: 4,
									md: 4,
									lg: 3
								}}
							>
								<Input
									value={orderNumber}
									setter={setOrderNumber}
									type="textfield"
									usageType="textfield"
									label={T("orderNumber", lang)}
									placeholder={T("orderNumber", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{/* <Grid.Col
							span={{
								base: 12,
								md: 6,
								lg: 3
							}}
						>
							<Input
								value={substation}
								setter={setSubstation}
								type="textfield"
								usageType="textfield"
								label={T("substation", lang)}
								placeholder={T("substation", lang)}
								lang={lang}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						</Grid.Col> */}

						{orderNumber && (
							<Grid.Col
								span={{
									base: 3,
									md: 3,
									lg: 2
								}}
							>
								<Input
									value={priority}
									setter={setPriority}
									optionListValue={priorities}
									type="optionList"
									usageType="optionList"
									label={T("priority", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}
						{orderNumber && (
							<Grid.Col
								span={{
									base: 3,
									md: 3,
									lg: 2
								}}
							>
								<Input
									value={importance}
									setter={setImportance}
									optionListValue={importances}
									type="optionList"
									usageType="optionList"
									label={T("importance", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						)}

						{importance == 1 || importance == 2 ? (
							<Grid.Col
								span={{
									base: 4,
									md: 4,
									lg: 3
								}}
							>
								<Input
									value={mediator}
									setter={setMediator}
									type="textfield"
									usageType="textfield"
									label={T("mediator", lang)}
									placeholder={T("mediator", lang)}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						) : null}

						{priority ? (
							<Grid.Col
								span={{
									base: 4,
									md: 4,
									lg: 3
								}}
							>
								<Input
									value={projectPartition}
									setter={setProjectPartition}
									optionListValue={projectPartitions}
									type="optionList"
									usageType="optionList"
									label={T("projectPartition", lang)}
									placeholder={T("projectPartition", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						) : null}

						{projectPartition == 2 ? (
							<Grid.Col
								span={{
									base: 4,
									md: 4,
									lg: 3
								}}
							>
								<Input
									value={contractor}
									setter={setContractor}
									optionListValue={contractors}
									type="optionList"
									usageType="optionList"
									label={T("contractor", lang)}
									placeholder={T("contractor", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						) : null}

						{priority ? (
							<Grid.Col
								span={{
									base: 6,
									md: 5,
									lg: 4
								}}
							>
								<Input
									value={address}
									setter={setAddress}
									type="textfield"
									usageType="textfield"
									label={T("address", lang)}
									placeholder={T("address", lang)}
									lang={lang}
									crud={{
										create: true,
										read: true,
										update: true,
										delete: true
									}}
								/>
							</Grid.Col>
						) : null}

						{projectPartition ? (
							<Grid.Col
								span={{
									base: 6,
									md: 5,
									lg: 4
								}}
							>
								<Checkbox
									onChange={(e) => {
										setOldProject(e.currentTarget.checked);
									}}
									checked={oldProject}
									label={T("oldProject", lang)}
								/>
							</Grid.Col>
						) : null}

						{projectPartition ? (
							<Grid.Col
								span={{
									base: 12,
									md: 12,
									lg: 12
								}}
							>
								<Checkbox.Group
									label={T("bypassSteps", lang)}
									onChange={(value) => {
										const uniqueSteps = [
											...new Set(value.flatMap((v) => v.split(",").map(Number)))
										];
										setDisabledSteps(uniqueSteps);
									}}
								>
									<Group align="start" m={10}>
										{disabledStepsArray?.map((bypass, i) => (
											<Checkbox
												style={{
													userSelect: "none",
													cursor: "pointer"
												}}
												label={`${bypass.name[lang]} - ( ${[...bypass.steps]} )`}
												value={bypass.steps.join(",")}
												key={i}
												checked={disabledSteps.includes(String(bypass.steps))}
											/>
										))}
									</Group>
								</Checkbox.Group>
							</Grid.Col>
						) : null}
					</Grid>

					<FilesComponent
						id={id}
						destiny={"pdf"}
						files={pdfFiles}
						filesUploadLimit={3}
						acceptedFiles={[".pdf"]}
						lang={lang}
						setBusy={setBusy}
						setFiles={setPdfFiles}
						route={route}
						filesMaxSize={"3"}
						date={new Date()}
						placeholder={"PDFs"}
						user={user}
						crud={{
							create: true,
							read: true,
							update: true,
							delete: true
						}}
						permissionModel="files"
						permissions={permissions}
					/>

					<CardSection p={20} mt={20}>
						<Group justify="center" grow>
							{
								<>
									{projectType &&
										community &&
										(city || village) &&
										voltage &&
										orderNumber &&
										address &&
										projectPartition && (
											<Button
												leftSection={
													<IconDeviceFloppy
														size={14}
														color={colorScheme === "dark" ? "white" : "black"}
													/>
												}
												variant="light"
												onClick={() => {
													saveItem();
												}}
											>
												{T("save", lang)}
											</Button>
										)}
									<Button
										leftSection={
											<IconArrowBack size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										color="orange"
										variant="light"
										onClick={() => history.go(`/${route}/`)}
									>
										{T("cancel", lang)}
									</Button>
								</>
							}
						</Group>
					</CardSection>
				</Card>
			</Tabs.Panel>
		</Tabs>
	);
}
