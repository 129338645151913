import React, { useEffect, useState } from "react";
import { ListPage } from "../../ListPage";
import config from "../../../config";
import * as API from "../../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { Avatar, Box, Button, Card, Flex, Text, Title } from "@mantine/core";
import { Link, useHistory } from "react-router-dom";
import { useMantineColorScheme } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";
import { T } from "../../../helpers/translator";

const customColors = {
	titleColor: "#2ea7c1",
	textColor: "#666"
};

export default function Pcs() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [users, setUsers] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const history = useHistory();

	let renderDetailPanel = ({ row }) => {
		let thisUser = users?.find((user) => user.pc === row.original?._id);
		return (
			<Box
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
					gap: "16px",
					padding: "16px",
					backgroundColor: colorScheme === "dark" ? "#1A1B1E" : "#FFFFFF",
					border: `1px solid ${colorScheme === "dark" ? "#2C2E33" : "#E0E0E0"}`,
					borderRadius: "8px"
				}}
			>
				<Link to={`/pc/${row.original._id}`}>
					<Avatar
						variant="filled"
						radius="sm"
						h={270}
						w={270}
						src={`${config.api.API_URL}/${row.original.uploads?.find((upl) => upl.destiny === "image")?.path}`}
						style={{ border: `2px solid ${customColors.titleColor}` }}
					/>
				</Link>
				<Box sx={{ textAlign: "center" }}>
					<Flex justify="flex-center">
						<Title style={{ color: customColors.titleColor }}>{row.original.name}</Title>
					</Flex>
					{row.original.cpu && (
						<Flex justify="flex-start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("cpu", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.cpu}
							</Text>
						</Flex>
					)}
					{row.original.gpu && (
						<Flex justify="flex-start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("gpu", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.gpu}
							</Text>
						</Flex>
					)}
					{row.original.ram && (
						<Flex justify="flex-start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("ram", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.ram}
							</Text>
						</Flex>
					)}
					{row.original.rom && (
						<Flex justify="flex-start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("rom", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.rom}
							</Text>
						</Flex>
					)}
					{row.original.ip && (
						<Flex justify="flex-start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("ipAddress", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.ip}
							</Text>
						</Flex>
					)}
					{row.original.mac && (
						<Flex justify="flex-start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("macAddress", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.mac}
							</Text>
						</Flex>
					)}
					{thisUser?.name?.[lang] && (
						<Flex justify="flex-start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("user", lang)}
							</Text>
							<Button
								variant="subtle"
								color="blue"
								size="sm"
								ml={10}
								leftIcon={<IconStar size="1.5rem" />}
								onClick={() => history.push(`/user/${thisUser?._id}`)}
							>
								{thisUser?.name?.[lang]}
							</Button>
						</Flex>
					)}
				</Box>
			</Box>
		);
	};

	function setRefreshColumns() {
		return [
			{
				accessorFn: (row) => `${row.name}`,
				permissionModel: "name",
				header: T("name", lang),
				size: 70,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row.name ? row.name : "no description");
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorKey: "ip",
				permissionModel: "ip",
				header: "IP",
				enableClickToCopy: true,
				size: 130,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row.ip ? row.ip : "...");
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorKey: "cpu",
				permissionModel: "cpu",
				header: "CPU",
				enableClickToCopy: true,
				size: 140,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row.cpu ? row.cpu : "...");
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorKey: "gpu",
				permissionModel: "gpu",
				header: "GPU",
				enableClickToCopy: true,
				size: 210,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row.gpu ? row.gpu : "...");
					return result.length > 30 ? result.slice(0, 30) + "..." : result;
				}
			},
			{
				accessorKey: "ram",
				permissionModel: "ram",
				header: "RAM",
				enableClickToCopy: true,
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: [...config.rams.flatMap((rot) => rot.value)]
				},
				size: 100,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					return row.ram;
				}
			},
			{
				accessorKey: "rom",
				permissionModel: "rom",
				header: "ROM",
				enableClickToCopy: true,
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: [...config.roms.flatMap((rot) => rot.value)]
				},
				size: 140,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					return row.rom;
				}
			},
			{
				accessorFn: (row) => `${row?.room?.name}`,
				permissionModel: "room",
				header: T("room", lang),
				size: 300,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					if (!row.room || !row.room.name) return null;
					return (
						<Button
							variant="light"
							color="teal"
							size="xs"
							onClick={() => {
								history.push(`/room/${row.room._id}`);
							}}
						>
							{row.room.name}
						</Button>
					);
				}
			},
			{
				accessorKey: "working",
				permissionModel: "working",
				header: T("workingState", lang),
				size: 160,
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: [
						{ label: `${T("working", lang)}`, value: "true" },
						{ label: `${T("notWorking", lang)}`, value: "false" }
					]
				},
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					return row.working ? (
						<Card c="green" size="xs">
							{T("working", lang)}
						</Card>
					) : (
						<Card c="red" size="xs">
							{T("notWorking", lang)}
						</Card>
					);
				}
			},
			{
				accessorKey: "placed",
				permissionModel: "placed",
				header: T("placedState", lang),
				size: 160,
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: [
						{ label: `${T("placed", lang)}`, value: "true" },
						{ label: `${T("notPlaced", lang)}`, value: "false" }
					]
				},
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					return row.placed ? (
						<Card c="green" size="xs">
							{T("placed", lang)}
						</Card>
					) : (
						<Card c="red" size="xs">
							{T("notPlaced", lang)}
						</Card>
					);
				}
			}
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setUsers(rawUsers);
			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.pc}
				query={{}}
				sorting={{ ip: "asc" }}
				permissionModel={"pc"}
				uploadType={true}
				limit={10}
				populate={["room"]}
				columns={columns}
				renderDetailPanel={renderDetailPanel}
			/>
		);
}
