import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// import Navigation from './navigation';
import { useAuth } from "../../helpers/auth";
import config from "../../config";
import { ActionToggle } from "../../helpers/actionToggle/actionToggle";
import { useUser } from "../../helpers/userContext";
import { useLang, LanguagePicker } from "../../helpers/language";
import { useDisclosure } from "@mantine/hooks";
import NProgress, { set } from "nprogress";
import * as API from "../../helpers/api";
import {
	IconArrowRight,
	IconChevronRight,
	IconFolder,
	IconLogin,
	IconRefresh,
	IconTrash
} from "@tabler/icons-react";
import { useViewportSize } from "@mantine/hooks";
import { T } from "../../helpers/translator";

import {
	Avatar,
	Text,
	Card,
	Grid,
	Modal,
	Button,
	Image,
	Flex,
	NavLink,
	ScrollArea,
	useMantineColorScheme,
	Timeline,
	Indicator,
	Badge
} from "@mantine/core";
import { IconHistory, IconLogout, IconSettings } from "@tabler/icons-react";
import classes from "./UserInfoIcons.module.css";
import moment from "moment";
import { useSocket } from "../../helpers/socket";
import { useActivePlatform } from "../../helpers/activePlatform";

export default function Sidebar() {
	const { logout } = useAuth();
	const { user } = useUser();
	const { lang } = useLang();
	const history = useHistory();
	const [opened, OpenClose] = useDisclosure(false);
	const [opened1, OpenClose1] = useDisclosure(false);
	const { colorScheme } = useMantineColorScheme();
	const { height } = useViewportSize();
	const { socket, connectedUsers } = useSocket();
	const { activePlatform, activePage, setActivePage, subActivePage, setSubActivePage } =
		useActivePlatform();

	const [users, setUsers] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [chats, setChats] = useState([]);
	const [directChats, setDirectChats] = useState([]);
	const [channelChats, setChannelChats] = useState([]);
	const [groupChats, setGroupChats] = useState([]);

	const getChats = async () => {
		let rawUsers = [];
		try {
			rawUsers = await API.get({ route: config.api.user, uploadType: true });
			if (rawUsers) setUsers(rawUsers);
		} catch (error) {
			console.error(error);
		}

		try {
			let rawChats = await API.get({
				route: config.api.chat,
				query: { $or: [{ users: user._id }, { admins: user._id }] },
				populate: ["users"]
			});
			if (rawChats) {
				rawChats = await Promise.all(
					rawChats.map(async (chat) => {
						chat.users = await Promise.all(
							chat.users.map(async (chatUser) => {
								const foundUser = rawUsers.find((user) => user._id === chatUser._id);
								if (foundUser) {
									chatUser.uploads = foundUser.uploads;
								}
								return chatUser;
							})
						);

						try {
							let messages = await API.get({
								route: config.api.message,
								query: { chatId: chat._id },
								sort: { createdAt: -1 }
							});
							chat.messages = messages;
							chat.unreadMessages = messages.filter(
								(message) =>
									!message.readBy.includes(user._id) && !message.deletedFor.includes(user._id)
							);
						} catch (error) {
							console.error(error);
						}
						chat.lastMessage = chat.messages[chat.messages.length - 1];
						return chat;
					})
				);
				setChats(rawChats);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getUsers = async () => {};

	useEffect(() => {
		// setActivePage(0);
		const platform = config.menu[activePlatform];
		if (platform && platform.menu && platform.menu.length > 0) {
			history.push(`/${platform.menu[0].route}`);
		}

		if (activePlatform === 3) {
			NProgress.start();
			(async () => {
				// get all Users
				getUsers();

				// get my chats
				getChats();

				NProgress.done();
				setBusy(false);
			})();
			// eslint-disable-next-line
		}
	}, [activePlatform]);

	useEffect(() => {
		// Separate chats into direct and channel chats
		const direct = chats.filter((chat) => chat.chatType === "direct");
		const channels = chats.filter((chat) => chat.chatType === "channel");
		const group = chats.filter((chat) => chat.chatType === "group");
		setDirectChats(direct);
		setChannelChats(channels);
		setGroupChats(group);
	}, [chats]);

	useEffect(() => {
		if (activePlatform === 3) {
			NProgress.start();
			(async () => {
				// get my chats
				getChats();

				NProgress.done();
				setBusy(false);
			})();
			// eslint-disable-next-line
		}
	}, [isBusy]);

	useEffect(() => {
		socket?.on("newMessageInteger", async (newMessage) => {
			if (window.location.pathname.includes(`chat/${newMessage.chatId}`)) return;
			getChats();
		});
		socket?.on("updatedMessageInteger", async (newMessage) => {
			getChats();
		});

		return () => {
			socket?.off("newMessageInteger");
			socket?.off("updatedMessageInteger");
		};

		// eslint-disable-next-line
	}, [socket, window.location.href]);

	return (
		<div className="sidebar">
			<Card shadow="sm" padding="lg">
				<Grid>
					<Modal opened={opened} onClose={OpenClose.close} title={T("settings", lang)} centered>
						<Card withBorder shadow="sm" radius="md">
							<Card.Section withBorder inheritPadding py="xs">
								{T("languagePickerLabel", lang)}
							</Card.Section>
							<Card.Section withBorder inheritPadding py="xs">
								<LanguagePicker />
							</Card.Section>
						</Card>

						<Card withBorder shadow="sm" radius="md" mt={20}>
							<Card.Section withBorder inheritPadding py="xs">
								{T("themePickerLabel", lang)}
							</Card.Section>
							<Card.Section withBorder inheritPadding py="xs">
								<ActionToggle />
							</Card.Section>
						</Card>

						<Card withBorder shadow="sm" radius="md" mt={20}>
							<Card.Section withBorder inheritPadding py="xs">
								{T("platformSpecificSettings", lang)}
							</Card.Section>
							<Card.Section withBorder inheritPadding py="xs">
								<Link to="/permissions">
									<Text c="blue" size="md">
										{T("permissions", lang)}
									</Text>
								</Link>
							</Card.Section>
						</Card>
					</Modal>
					<Modal opened={opened1} onClose={OpenClose1.close} title={T("history", lang)} centered>
						{user.history && user.history?.length > 0 && (
							<Card withBorder shadow="sm" radius="md">
								<Timeline activePage={user.history.length} bulletSize={24} lineWidth={2}>
									{user?.history?.map((item, index) => {
										return (
											<Timeline.Item
												bullet={
													item?.action?.method === "entered" ? (
														<IconLogin size={14} />
													) : item?.action?.method === "updated" ? (
														<IconRefresh size={14} />
													) : item?.action?.method === "deleted" ? (
														<IconTrash size={14} />
													) : (
														<IconArrowRight size={14} />
													)
												}
												title={
													moment(item?.date).format("HH:mm") + " - " + item?.action?.name?.[lang] ||
													"No description"
												}
												key={index}
											>
												{item?.action?.route.pathname && (
													<Link
														to={
															"/" + item?.action?.route.pathname + "?" + item?.action?.route.search
														}
														onClick={OpenClose1.close}
													>
														<Text c="dimmed" size="md">
															{item?.action?.route.pathname}
														</Text>
														<Text c="dimmed" size="md">
															{item?.action?.route.search}
														</Text>
													</Link>
												)}
											</Timeline.Item>
										);
									})}
								</Timeline>
							</Card>
						)}
					</Modal>
					<Grid.Col span={5}>
						<Avatar
							src={`${config.api.API_URL}/${user.uploads?.find((upl) => upl.destiny === "image")?.path}`}
							size={94}
							radius="md"
						/>
					</Grid.Col>
					<Grid.Col span={7}>
						<Flex wrap="nowrap" mt={1} direction={"column"}>
							<Button
								justify="center"
								width
								onClick={() => logout(socket)}
								p={5}
								size="xs"
								leftSection={
									<IconLogout
										size="1rem"
										className={classes.icon}
										color={colorScheme === "dark" ? "white" : "black"}
									/>
								}
								variant="default"
							>
								{T("logOut", lang)}
							</Button>

							<Button
								justify="center"
								width
								onClick={OpenClose1.toggle}
								p={5}
								size="xs"
								leftSection={
									<IconHistory
										size="1rem"
										className={classes.icon}
										color={colorScheme === "dark" ? "white" : "black"}
									/>
								}
								variant="default"
								mt={5}
							>
								{T("history", lang)}
							</Button>

							<Button
								justify="center"
								width
								size="xs"
								onClick={OpenClose.toggle}
								p={5}
								leftSection={
									<IconSettings
										size="1rem"
										className={classes.icon}
										color={colorScheme === "dark" ? "white" : "black"}
									/>
								}
								variant="default"
								mt={5}
							>
								{T("settings", lang)}
							</Button>
						</Flex>
					</Grid.Col>
					<Grid.Col span={12}>
						<div>
							<Text fz="lg" fw={500} className={classes.name}>
								{user.name?.[lang]}
							</Text>
							<Text fz="xs" tt="uppercase" fw={700} c="dimmed">
								{user.position?.name?.[lang]}
							</Text>
						</div>
					</Grid.Col>
				</Grid>
			</Card>

			<Card shadow="sm" padding="" mt={20}>
				{activePlatform === 3 ? (
					<ScrollArea h={height - 40 - 267}>
						<NavLink label={T("channels", lang)} disabled />
						{channelChats.map((item, index) => {
							return (
								<NavLink
									label={item.name.charAt(0).toUpperCase() + item.name.slice(1)}
									onClick={() => {
										setActivePage(index);
										history.push(`/chat/${item._id}`);
									}}
									p={12}
									leftSection={
										<Avatar
											size="md"
											radius="sm"
											src={
												`${config.api.API_URL}/${item?.uploads?.find((upl) => upl.destiny === "image").path}` ||
												""
											}
										/>
									}
									rightSection={
										<>
											<Text>{item.lastMessage ? item.lastMessage.text : "No messages"}</Text>
											{item.unreadMessages?.length > 0 ? (
												<Badge variant="light" color="blue" size="lg">
													{item.unreadMessages?.length}
												</Badge>
											) : null}
										</>
									}
									active={index === activePage}
									key={index}
								/>
							);
						})}
						<NavLink label={T("groupChats", lang)} disabled />
						{groupChats.map((item, index) => {
							return (
								<NavLink
									label={item.name.charAt(0).toUpperCase() + item.name.slice(1)}
									onClick={() => {
										setActivePage(channelChats.length + index);
										history.push(`/chat/${item._id}`);
									}}
									p={12}
									leftSection={
										<Avatar
											size="md"
											radius="sm"
											src={
												`${config.api.API_URL}/${item?.uploads?.find((upl) => upl.destiny === "image").path}` ||
												""
											}
										/>
									}
									rightSection={
										<>
											<Text>{item.lastMessage ? item.lastMessage.text : "No messages"}</Text>
											{item.unreadMessages?.length > 0 ? (
												<Badge variant="light" color="blue" size="lg">
													{item.unreadMessages?.length}
												</Badge>
											) : null}
										</>
									}
									active={channelChats.length + index === activePage}
									key={channelChats.length + index}
								/>
							);
						})}
						<NavLink label={T("directMessages", lang)} disabled />
						{directChats.map((item, index) => {
							let partner = item.users?.find((usr) => usr._id !== user._id);
							let userConnectionStatus = connectedUsers.find(
								(user) => user._id === partner?._id
							)?.connectionStatus;

							return (
								<NavLink
									label={
										item.chatType === "direct"
											? partner.name?.[lang]
											: item.chatType === "channel"
												? item.name.charAt(0).toUpperCase() + item.name.slice(1)
												: "no data"
									}
									onClick={() => {
										setActivePage(groupChats.length + channelChats.length + index);
										history.push(`/chat/${item._id}`);
									}}
									p={12}
									leftSection={
										<Indicator
											inline
											processing={
												userConnectionStatus
													? userConnectionStatus === "online"
														? true
														: false
													: false
											}
											color={
												userConnectionStatus === "online"
													? "green"
													: userConnectionStatus === "away"
														? "orange"
														: "grey"
											}
											position="top-start"
											size={10}
										>
											<Avatar
												size="md"
												radius="sm"
												src={
													`${config.api.API_URL}/${partner?.uploads?.find((upl) => upl.destiny === "image").path}` ||
													""
												}
											/>
										</Indicator>
									}
									rightSection={
										<>
											<Text>{item.lastMessage ? item.lastMessage.text : "No messages"}</Text>
											{item.unreadMessages?.length > 0 ? (
												<Badge variant="light" color="blue" size="lg">
													{item.unreadMessages?.length}
												</Badge>
											) : null}
										</>
									}
									active={groupChats.length + channelChats.length + index === activePage}
									key={groupChats.length + channelChats.length + index}
								/>
							);
						})}
					</ScrollArea>
				) : (
					<ScrollArea h={height - 40 - 267}>
						{config.menu[activePlatform]?.menu?.flatMap((item, index) => {
							if (item.subMenu) {
								return (
									<NavLink
										onClick={() => {
											setActivePage(index);
											setSubActivePage(null);
											if (!item.subMenu) {
												return history.push(`/${item.route}`);
											}
										}}
										label={item.title?.[lang]}
										key={index}
										childrenOffset={10}
										leftSection={<IconFolder size="1.5rem" stroke={1.5} />}
										rightSection={
											<IconChevronRight size="0.8rem" stroke={1.5} className="mantine-rotate-rtl" />
										}
										variant="subtle"
										active={index === activePage && subActivePage === null}
									>
										{item.subMenu?.flatMap((subItem, subIndex) => {
											if (user.ananunaki) {
												return (
													<NavLink
														label={
															subItem.name[lang]?.charAt(0).toUpperCase() +
															subItem.name[lang]?.slice(1)
														}
														onClick={() => {
															setActivePage(index);
															setSubActivePage(subIndex);
															history.push(`/${subItem.route}`);
														}}
														leftSection={
															<Image
																src={`/assets/${subItem.icon}`}
																alt="navigation-icon"
																w={20}
																h={20}
																style={{
																	filter: `invert(${colorScheme === "dark" ? 1 : 0})`
																}}
															/>
														}
														active={index === activePage && subIndex === subActivePage}
														key={subIndex}
													/>
												);
											} else {
												return user.role?.permissions?.map((perm) => {
													if (perm.name === subItem.permissionModel && perm.crud.read) {
														return (
															<NavLink
																label={
																	subItem.name[lang]?.charAt(0).toUpperCase() +
																	subItem.name[lang]?.slice(1)
																}
																onClick={() => {
																	setActivePage(index);
																	setSubActivePage(subIndex);
																	history.push(`/${subItem.route}`);
																}}
																leftSection={
																	<Image
																		src={`/assets/${subItem.icon}`}
																		alt="navigation-icon"
																		w={20}
																		h={20}
																		style={{
																			filter: `invert(${colorScheme === "dark" ? 1 : 0})`
																		}}
																	/>
																}
																active={index === activePage && subIndex === subActivePage}
																key={subIndex}
															/>
														);
													}
													return null;
												});
											}
										})}
									</NavLink>
								);
							} else {
								if (user.ananunaki) {
									return (
										<NavLink
											href="#required-for-focus"
											onClick={() => {
												setActivePage(index);
												setSubActivePage(null);
												if (!item.subMenu) {
													return history.push(`/${item.route}`);
												}
											}}
											label={item.name?.[lang]}
											key={index}
											childrenOffset={10}
											leftSection={
												!item.subMenu ? (
													<Image
														src={`/assets/${item.icon}`}
														alt="navigation-icon"
														w={20}
														h={20}
														style={{
															filter: `invert(${colorScheme === "dark" ? 1 : 0})`
														}}
													/>
												) : (
													<IconFolder size="1.5rem" stroke={1.5} />
												)
											}
											rightSection={
												item.subMenu && (
													<IconChevronRight
														size="0.8rem"
														stroke={1.5}
														className="mantine-rotate-rtl"
													/>
												)
											}
											variant="subtle"
											active={index === activePage && subActivePage === null}
										>
											{item.subMenu?.flatMap((subItem, subIndex) => {
												return (
													<NavLink
														label={
															subItem.name[lang]?.charAt(0).toUpperCase() +
															subItem.name[lang]?.slice(1)
														}
														onClick={() => {
															setActivePage(index);
															setSubActivePage(subIndex);
															history.push(`/${subItem.route}`);
														}}
														leftSection={
															<Image
																src={`/assets/${subItem.icon}`}
																alt="navigation-icon"
																w={20}
																h={20}
																style={{
																	filter: `invert(${colorScheme === "dark" ? 1 : 0})`
																}}
															/>
														}
														active={index === activePage && subIndex === subActivePage}
														key={subIndex}
													/>
												);
											})}
										</NavLink>
									);
								} else {
									return user.role.permissions.map((perm) => {
										if (perm.name === item.permissionModel && perm.crud.read) {
											return (
												<NavLink
													href="#required-for-focus"
													onClick={() => {
														setActivePage(index);
														setSubActivePage(null);
														if (!item.subMenu) {
															return history.push(`/${item.route}`);
														}
													}}
													label={item.name?.[lang]}
													key={index}
													childrenOffset={10}
													leftSection={
														!item.subMenu ? (
															<Image
																src={`/assets/${item.icon}`}
																alt="navigation-icon"
																w={20}
																h={20}
																style={{
																	filter: `invert(${colorScheme === "dark" ? 1 : 0})`
																}}
															/>
														) : (
															<IconFolder size="1.5rem" stroke={1.5} />
														)
													}
													rightSection={
														item.subMenu && (
															<IconChevronRight
																size="0.8rem"
																stroke={1.5}
																className="mantine-rotate-rtl"
															/>
														)
													}
													variant="subtle"
													active={index === activePage && subActivePage === null}
												>
													{item.subMenu?.flatMap((subItem, subIndex) => {
														return (
															<NavLink
																label={
																	subItem.name[lang]?.charAt(0).toUpperCase() +
																	subItem.name[lang]?.slice(1)
																}
																onClick={() => {
																	setActivePage(index);
																	setSubActivePage(subIndex);
																	history.push(`/${subItem.route}`);
																}}
																leftSection={
																	<Image
																		src={`/assets/${subItem.icon}`}
																		alt="navigation-icon"
																		w={20}
																		h={20}
																		style={{
																			filter: `invert(${colorScheme === "dark" ? 1 : 0})`
																		}}
																	/>
																}
																active={index === activePage && subIndex === subActivePage}
																key={subIndex}
															/>
														);
													})}
												</NavLink>
											);
										}
										return null;
									});
								}
							}
						})}
					</ScrollArea>
				)}
			</Card>
		</div>
	);
}
